import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    collectionUID: null,
    caseUID: null,
    caseTab: "overview",
    directLoad: false,
    searchKeyword: null,
    department: null,
    searchResponse: null,
    blockLoading: false,
    loading: true,
    currentView: "components",
    reloadTrigger: false,
    editCount: 0,
    progressModal: false,
    completeModal: false,
    redoModal: false,
    outsourceModal: false,
    refetchMessageTrigger: false,
    me: {},
    statuses: [],
    employees: [],
    branches: [],
    collection: {},
    orderForms: [],
    messageTypes: [],
    messages: [],
    approvalTypes: [],
    kase: {},
    materials: [],
    materialTypes: [],
    jobTypes: [],
    billKits: [],
    attachments: [],
    analogs: [],
    genericComponents: [],
    audit: [],
    partners: [],
    orderStages: [],
    messageFilter: {},
    communicationLogs: []
  },
  getters: {
    collectionUID: state => {
      return state.collectionUID;
    },
    caseUID: state => {
      return state.caseUID;
    },
    caseTab: state => {
      return state.caseTab;
    },
    directLoad: state => {
      return state.directLoad;
    },
    searchKeyword: state => {
      return state.searchKeyword;
    },
    department: state => {
      return state.department;
    },
    searchResponse: state => {
      return state.searchResponse;
    },
    isLoading: state => {
      return state.loading;
    },
    isBlockLoading: state => {
      return state.blockLoading;
    },
    currentView: state => {
      return state.currentView;
    },
    reloadTrigger: state => {
      return state.reloadTrigger;
    },
    isEditing: state => {
      if (state.reloadTrigger) {
        return false;
      }
      return state.editCount !== 0;
    },
    progressModal: state => {
      return state.progressModal;
    },
    completeModal: state => {
      return state.completeModal;
    },
    redoModal: state => {
      return state.redoModal;
    },
    outsourceModal: state => {
      return state.outsourceModal;
    },
    me: state => {
      return state.me;
    },
    statuses: state => {
      return state.statuses;
    },
    employees: state => {
      return state.employees;
    },
    branches: state => {
      return state.branches;
    },
    collection: state => {
      return state.collection;
    },
    orderForms: state => {
      return state.orderForms;
    },
    messageTypes: state => {
      return state.messageTypes;
    },
    messages: state => {
      return state.messages;
    },
    approvalTypes: state => {
      return state.approvalTypes;
    },
    kase: state => {
      return state.kase;
    },
    materials: state => {
      return state.materials;
    },
    materialTypes: state => {
      return state.materialTypes;
    },
    jobTypes: state => {
      return state.jobTypes;
    },
    billKits: state => {
      return state.billKits;
    },
    attachments: state => {
      return state.attachments;
    },
    analogs: state => {
      return state.analogs;
    },
    genericComponents: state => {
      return state.genericComponents;
    },
    audit: state => {
      return state.audit;
    },
    partners: state => {
      return state.partners;
    },
    orderStages: state => {
      return state.orderStages;
    },
    messageFilter: state => {
      return state.messageFilter;
    },
    communicationLogs: state => {
      return state.communicationLogs;
    },
    dentates: (_, getters) => {
      const dentates = [];
      if (getters.casesByDentate.upper.length > 0) {
        dentates.push("UPPER");
      }
      if (getters.casesByDentate.lower.length > 0) {
        dentates.push("LOWER");
      }
      if (getters.casesByDentate.both.length > 0) {
        dentates.push("BOTH");
      }
      if (getters.casesByDentate.none.length > 0) {
        dentates.push("NONE");
      }
      return dentates;
    },
    casesByDentate: state => {
      const sortedCases = {
        upper: [],
        lower: [],
        both: [],
        none: []
      };
      if (!state.collection) {
        return sortedCases;
      }
      if (!state.collection.cases) {
        return sortedCases;
      }
      if (state.collection.cases.length === 0) {
        return sortedCases;
      }
      state.collection.cases.forEach(kase => {
        if (kase.dentate) {
          if (kase.dentate === "BOTH") {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "NONE") {
            sortedCases.none.push(kase);
            return;
          }
          if (
            kase.implants.filter(implant => implant.tooth > 30).length > 0 &&
            kase.implants.filter(implant => implant.tooth < 30).length > 0
          ) {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "UPPER") {
            if (
              kase.implants.filter(implant => implant.tooth > 30).length > 0
            ) {
              sortedCases.both.push(kase);
              return;
            }
            sortedCases.upper.push(kase);
            return;
          }
          if (kase.implants.filter(implant => implant.tooth < 30).length > 0) {
            sortedCases.both.push(kase);
            return;
          }
          sortedCases.lower.push(kase);
          return;
        }
        if (kase.implants.length === 0) {
          sortedCases.none.push(kase);
          return;
        }
        if (
          kase.implants.filter(implant => implant.tooth > 30).length > 0 &&
          kase.implants.filter(implant => implant.tooth < 30).length > 0
        ) {
          sortedCases.both.push(kase);
          return;
        }
        if (kase.implants.filter(implant => implant.tooth > 30).length > 0) {
          sortedCases.lower.push(kase);
          return;
        }
        sortedCases.upper.push(kase);
      });
      return sortedCases;
    },
    messageRefetchTrigger: state => {
      return state.refetchMessageTrigger;
    },
  },
  mutations: {
    SET_COLLECTION_UID(state, payload) {
      state.collectionUID = payload;
    },
    SET_CASE_UID(state, payload) {
      state.caseUID = payload;
      if (!state.caseUID) {
        state.kase = {};
        state.caseTab = "overview";
        return;
      }
      state.kase = state.collection.cases.find(
        kase => kase.uid === state.caseUID
      );
    },
    SET_CASE_TAB(state, payload) {
      state.caseTab = payload;
    },
    SET_DIRECT_LOAD(state, payload) {
      state.directLoad = payload;
    },
    SET_SEARCH_KEYWORD(state, payload) {
      state.searchKeyword = payload;
    },
    SET_DEPARTMENT(state, payload) {
      state.department = payload;
    },
    SET_SEARCH_RESPONSE(state, payload) {
      state.searchResponse = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_BLOCK_LOADING(state, payload) {
      state.blockLoading = payload;
    },
    SET_CURRENT_VIEW(state, payload) {
      state.currentView = payload;
    },
    SET_RELOAD_TRIGGER(state, payload) {
      state.reloadTrigger = payload;
    },
    SET_IS_EDITING(state, payload) {
      if (payload) {
        state.editCount++;
      } else {
        state.editCount--;
      }
    },
    SET_PROGRESS_MODAL(state, payload) {
      state.progressModal = payload;
    },
    SET_COMPLETE_MODAL(state, payload) {
      state.completeModal = payload;
    },
    SET_REDO_MODAL(state, payload) {
      state.redoModal = payload;
    },
    SET_OUTSOURCE_MODAL(state, payload) {
      state.outsourceModal = payload;
    },
    SET_ME(state, payload) {
      state.me = payload;
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload;
    },
    SET_EMPLOYEES(state, payload) {
      state.employees = payload;
    },
    SET_BRANCHES(state, payload) {
      state.branches = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_ORDER_FORMS(state, payload) {
      state.orderForms = payload;
    },
    SET_MESSAGE_TYPES(state, payload) {
      state.messageTypes = payload;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
    SET_APPROVAL_TYPES(state, payload) {
      state.approvalTypes = payload;
    },
    SET_MATERIALS(state, payload) {
      state.materials = payload;
    },
    SET_MATERIAL_TYPES(state, payload) {
      state.materialTypes = payload;
    },
    SET_JOB_TYPES(state, payload) {
      state.jobTypes = payload;
    },
    SET_BILL_KITS(state, payload) {
      state.billKits = payload;
    },
    SET_ATTACHMENTS(state, payload) {
      state.attachments = payload;
    },
    SET_ANALOGS(state, payload) {
      state.analogs = payload;
    },
    SET_GENERIC_COMPONENTS(state, payload) {
      state.genericComponents = payload;
    },
    SET_AUDIT(state, payload) {
      state.audit = payload;
    },
    SET_PARTNERS(state, payload) {
      state.partners = payload;
    },
    SET_ORDER_STAGES(state, payload) {
      state.orderStages = payload;
    },
    SET_MESSAGE_FILTER(state, payload) {
      state.messageFilter = payload;
    },
    SET_COMMUNICATION_LOGS(state, payload) {
      state.communicationLogs = payload;
    },
    SET_REFETCHMESSAGE_TRIGGER(state, payload) {
      state.refetchMessageTrigger = payload;
    }
  },
  actions: {
    setCollectionUID({ commit }, collectionUID) {
      commit("SET_CASE_UID", null);
      commit("SET_COLLECTION_UID", collectionUID);
    },
    setCaseUID({ commit }, caseUID) {
      commit("SET_CASE_UID", caseUID);
    },
    setCaseTab({ commit }, tabKey) {
      commit("SET_CASE_TAB", tabKey);
    },
    setDirectLoad({ commit }) {
      commit("SET_DIRECT_LOAD", true);
    },
    setSearchKeyword({ commit }, keyword) {
      commit("SET_SEARCH_KEYWORD", keyword);
    },
    setDepartment({ commit }, keyword) {
      commit("SET_DEPARTMENT", keyword);
    },
    setSearchResponse({ commit }, keyword) {
      commit("SET_SEARCH_RESPONSE", keyword);
    },
    startLoading({ commit }) {
      commit("SET_LOADING", true);
    },
    stopLoading({ commit }) {
      commit("SET_LOADING", false);
    },
    startBlockLoading({ commit }) {
      commit("SET_BLOCK_LOADING", true);
    },
    stopBlockLoading({ commit }) {
      commit("SET_BLOCK_LOADING", false);
    },
    setCurrentView({ commit }, view) {
      commit("SET_MESSAGE_FILTER", {});
      commit("SET_CURRENT_VIEW", view);
    },
    triggerReload({ commit }) {
      commit("SET_RELOAD_TRIGGER", true);
    },
    reloadComplete({ commit }) {
      commit("SET_RELOAD_TRIGGER", false);
    },
    startEditing({ commit }) {
      commit("SET_IS_EDITING", true);
    },
    stopEditing({ commit }) {
      commit("SET_IS_EDITING", false);
    },
    showProgressModal({ commit }) {
      commit("SET_PROGRESS_MODAL", true);
    },
    hideProgressModal({ commit }) {
      commit("SET_PROGRESS_MODAL", false);
    },
    showCompleteModal({ commit }) {
      commit("SET_COMPLETE_MODAL", true);
    },
    hideCompleteModal({ commit }) {
      commit("SET_COMPLETE_MODAL", false);
    },
    showRedoModal({ commit }) {
      commit("SET_REDO_MODAL", true);
    },
    hideRedoModal({ commit }) {
      commit("SET_REDO_MODAL", false);
    },
    showOutsourceModal({ commit }) {
      commit("SET_OUTSOURCE_MODAL", true);
    },
    hideOutsourceModal({ commit }) {
      commit("SET_OUTSOURCE_MODAL", false);
    },
    setMe({ commit }, me) {
      commit("SET_ME", me);
    },
    setStatuses({ commit }, statuses) {
      commit("SET_STATUSES", statuses);
    },
    setEmployees({ commit }, employees) {
      commit("SET_EMPLOYEES", employees);
    },
    setBranches({ commit }, branches) {
      commit("SET_BRANCHES", branches);
    },
    setCollection({ commit }, collection) {
      commit("SET_COLLECTION", collection);
    },
    setOrderForms({ commit }, orderForms) {
      commit("SET_ORDER_FORMS", orderForms);
    },
    setMessageTypes({ commit }, messageTypes) {
      commit("SET_MESSAGE_TYPES", messageTypes);
    },
    setMessages({ commit }, messages) {
      commit("SET_MESSAGES", messages);
    },
    setApprovalTypes({ commit }, approvalTypes) {
      commit("SET_APPROVAL_TYPES", approvalTypes);
    },
    setMaterials({ commit }, materials) {
      commit("SET_MATERIALS", materials);
    },
    setMaterialTypes({ commit }, materialTypes) {
      commit("SET_MATERIAL_TYPES", materialTypes);
    },
    setJobTypes({ commit }, jobTypes) {
      commit("SET_JOB_TYPES", jobTypes);
    },
    setBillKits({ commit }, billKits) {
      commit("SET_BILL_KITS", billKits);
    },
    setAttachments({ commit }, attachments) {
      commit("SET_ATTACHMENTS", attachments);
    },
    setAnalogs({ commit }, analogs) {
      commit("SET_ANALOGS", analogs);
    },
    setGenericComponents({ commit }, genericComponents) {
      commit("SET_GENERIC_COMPONENTS", genericComponents);
    },
    setAudit({ commit }, audit) {
      commit("SET_AUDIT", audit);
    },
    setPartners({ commit }, partners) {
      commit("SET_PARTNERS", partners);
    },
    setOrderStages({ commit }, orderStages) {
      commit("SET_ORDER_STAGES", orderStages);
    },
    setMessageFilter({ commit }, filters) {
      commit("SET_MESSAGE_FILTER", filters);
    },
    setCommunicationLogs({ commit }, communicationLogs) {
      commit("SET_COMMUNICATION_LOGS", communicationLogs);
    },
    triggerMessageReload({ commit }) {
      commit("SET_REFETCHMESSAGE_TRIGGER", true);
    },
    reloadMessageComplete({ commit }) {
      commit("SET_REFETCHMESSAGE_TRIGGER", false);
    },
  },
  modules: {}
});
