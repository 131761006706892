<template>
  <div>
    <InvoiceList v-model="selectedInvoiceID" :invoices="invoices" />
    <CollectionHeader />
    <div v-if="loading" class="text-center ma-5">
      <v-progress-circular indeterminate size="256" color="primary">
        Fetching Invoices
      </v-progress-circular>
    </div>
    <v-card v-if="noBranch" outlined class="rounded-lg my-5">
      <v-card-text class="text-center my-5">
        <v-icon x-large color="error">mdi-alert</v-icon>
        <br />
        No Fulfillment Branch Selected
      </v-card-text>
    </v-card>
    <div v-if="!loading && !noBranch">
      <InvoiceDetails
        :selected-invoice="selectedInvoice"
        @updated="updated()"
        @updatePurchaseOrder="updatePurchaseOrder"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import InvoiceList from "./Invoices/InvoiceList";
import InvoiceDetails from "./Invoices/InvoiceDetails";

export default {
  name: "Invoice",
  components: {
    CollectionHeader,
    InvoiceList,
    InvoiceDetails
  },
  data() {
    return {
      invoices: [],
      selectedInvoiceID: null,
      invoicesUpdated: false,
      loading: true,
      noBranch: false
    };
  },
  watch: {
    invoiceUpdated(value) {
      if (!value) {
        return;
      }
      this.loading = true;
      this.fetchInvoices().then(() => {
        this.loading = false;
        this.setInvoiceUpdated(false);
      });
    }
  },
  computed: {
    ...mapGetters(["collection"]),
    caseID() {
      if (this.collection.cases.length === 0) {
        return;
      }
      return this.collection.cases[0].case_id;
    },
    selectedInvoice() {
      if (!this.selectedInvoiceID) {
        if (this.invoices.length > 0) {
          return this.invoices[0];
        }
        return;
      }
      return this.invoices.find(
        invoice => invoice.case_number === this.selectedInvoiceID
      );
    }
  },
  methods: {
    ...mapActions(["stopLoading", "startLoading"]),
    async fetchInvoices() {
      if (!this.caseID) {
        return;
      }
      if (!this.collection.fulfillment_branch) {
        this.noBranch = true;
        return;
      } else {
        this.noBranch = false;
      }
      return new Promise(resolve => {
        if (this.isEditing) {
          return resolve();
        }
        this.$axios
          .get(`/api/cases/${this.caseID}/invoicing/`)
          .then(response => {
            this.invoices = response.data;
            this.selectedInvoiceID = null;
            resolve();
          });
      });
    },
    updated() {
      this.fetchInvoices();
    },
    async updatePurchaseOrder(selectedInvoice) {
      this.openParkModal = false;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${selectedInvoice.case_number}/`, {
          action: "invoice_purchase_order",
          case_collection_uid: selectedInvoice.case_collection_uid,
          purchase_order_number: selectedInvoice.purchase_order_number
        })
        .then(() => {
          this.updated();
          this.stopLoading();
        })
        .catch(() => {
          this.stopLoading();
          alert("Invoice PO# could not be updated, please try again.");
        });
    }
  },
  mounted() {
    this.loading = true;
    this.fetchInvoices().then(() => {
      this.loading = false;
    });
  }
};
</script>
