<template>
  <span v-if="timeLeft">
    <span v-if="timeLeft.minus">-</span>
    {{ timeLeft.hours }} : {{ timeLeft.minutes }} : {{ timeLeft.seconds }}
  </span>
</template>

<script>
export default {
  name: "CountDown",
  props: ["value", "dueTime"],
  data() {
    return {
      timeLeft: null,
      alerting: false
    };
  },
  methods: {
    updateTimeLeft() {
      this.$emit("input", this.alerting);
      if (!this.dueTime) {
        this.alerting = false;
        this.timeLeft = null;
        return;
      }
      this.alerting = !this.alerting;
      let isMinus = false;
      const now = new Date();
      const dueTime = new Date(this.dueTime);
      let total = dueTime - now;
      if (now > dueTime) {
        total = now - dueTime;
        isMinus = true;
      }
      const seconds = Math.abs(Math.floor((total / 1000) % 60));
      const minutes = Math.abs(Math.floor((total / 1000 / 60) % 60));
      const hours = Math.abs(Math.floor(total / (1000 * 60 * 60)));
      this.timeLeft = {
        minus: isMinus,
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0")
      };
    }
  },
  mounted() {
    this.updateTimeLeft();
    setInterval(this.updateTimeLeft, 1000);
  }
};
</script>
