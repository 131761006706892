export default number => {
  const groupColors = [
    "blue lighten-1",
    "green lighten-1",
    "cyan lighten-1",
    "teal lighten-1",
    "orange lighten-1"
  ];
  if (!number) {
    return;
  }
  return groupColors[(number - 1) % 5];
};
