<template>
  <CountryFlag v-if="country" rounded :country="country" :size="size" />
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "CountryFlags",
  components: {
    CountryFlag
  },
  props: ["country", "size"]
};
</script>
