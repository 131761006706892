<template>
  <div class="text-center ml-1" style="min-width: 70px">
    <v-menu
      max-width="600"
      min-width="600"
      max-height="800"
      open-on-hover
      dark
      offset-y
      class="overflow-y-auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="rounded-lg" outlined dark v-on="on" v-bind="attrs">
          <v-badge
            v-if="notifications.length > 0"
            color="error"
            :content="notifications.length"
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-if="notifications.length === 0">mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list class="rounded-lg">
        <v-list-item v-if="notifications.length === 0">
          <v-list-item-content>
            No Notifications
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(notification, index) in notifications"
          :key="index"
        >
          <v-list-item-content>
            <v-card class="rounded-lg" outlined light>
              <v-card-title>
                <small>
                  <v-icon small>mdi-clock</v-icon>
                  {{ notification.created_at | luxon({ output: "tz_med" }) }}
                </small>
                <v-spacer />
                <v-btn
                  v-if="notification.expires_at"
                  x-small
                  color="error"
                  class="mr-2"
                  @click="ignoreNotification(notification)"
                >
                  <v-icon small class="mr-2">mdi-thumb-down</v-icon>
                  Ignore
                </v-btn>
                <v-btn
                  x-small
                  color="success"
                  @click="dismissNotification(notification)"
                >
                  <v-icon small class="mr-2">mdi-thumb-up</v-icon>
                  Actioned
                </v-btn>
              </v-card-title>
              <v-card-text
                @click="notificationClick(notification)"
                style="cursor: pointer;"
              >
                <v-row align="center">
                  <v-col sm="8">
                    <span v-if="notification.case_uid">
                      <v-chip color="primary" small label>{{
                        notification.case.case_mkey
                      }}</v-chip>
                      <small class="ml-2">{{
                        truncateString(notification.case.job_type, 80)
                      }}</small>
                    </span>
                    <span v-else-if="notification.case_collection_uid">
                      <v-chip color="primary" small label>{{
                        notification.collection.collection_id
                      }}</v-chip>
                    </span>
                  </v-col>
                  <v-col sm="4" class="text-right">
                    <v-chip outlined label small>
                      {{ toTitleCase(notification.message_type) }}
                    </v-chip>
                  </v-col>
                  <v-col sm="8" class="py-0">
                    <v-icon small>mdi-account</v-icon>
                    {{ notification.author_reference }}
                  </v-col>
                  <v-col sm="4" class="py-0 text-right">
                    <span v-if="notification.expires_at">
                      <v-icon small>mdi-alarm-snooze</v-icon>
                      {{ notification.expires_at | luxonRelative }}
                    </span>
                  </v-col>
                  <v-col sm="12">
                    <small>
                      <i>{{ truncateString(notification.note) }}</i>
                    </small>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar timeout="-1" top right rounded="lg" v-model="popup">
      <v-icon class="mr-5">mdi-bell-alert</v-icon>
      You have a new notification
      <template v-slot:action="{ attrs }">
        <v-btn color="warning" text v-bind="attrs" @click="popup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "Notifications",
  data() {
    return {
      notificationSound: null,
      focused: true,
      refreshIntervalID: null,
      loading: false,
      popup: false,
      notifications: [],
    };
  },
  methods: {
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
    truncateString(string, maxLength) {
      const clamp = "...";
      const length = maxLength || 255;
      return string.length > length ? string.slice(0, length) + clamp : string;
    },
    notificationClick(notification) {
      if (!notification.case_collection_uid) {
        return;
      }
      window.open(
        `/static/v2/collection.html?view=messages&uid=${notification.case_collection_uid}&muid=${notification.uid}`
      );
    },
    focusMonitor() {
      this.focused = document.hasFocus();
      if (!this.focused && this.refreshIntervalID) {
        clearInterval(this.refreshIntervalID);
        this.refreshIntervalID = null;
      }
      if (this.focused && !this.refreshIntervalID) {
        this.fetchNotifications();
        this.refreshIntervalID = setInterval(this.fetchNotifications, 30000);
      }
    },
    async fetchNotifications() {
      this.loading = true;
      this.$axios
        .get("/api/v2/messages/notifications/")
        .then((response) => {
          response.data.forEach((notification) => {
            if (
              !this.notifications
                .map((existing) => existing.notification_uid)
                .includes(notification.notification_uid)
            ) {
              if (!notification.delivered_at) {
                this.newNotification();
                this.$axios
                  .patch(
                    `/api/v2/messages/notification/${notification.notification_uid}/acknowledge`
                  )
                  .then(() => {
                    notification.delivered_at = DateTime.now().toISO();
                  });
              }
              this.notifications.push(notification);
            }
          });
          this.notifications.forEach((seenNotification) => {
            if (
              response.data
                .map((notification) => notification.uid)
                .includes(seenNotification.uid)
            ) {
              return;
            }
            const index = this.notifications.indexOf(seenNotification);
            if (index > -1) {
              this.notifications.splice(index, 1);
            }
          });
        })
        .catch(() => {});
      this.loading = false;
    },
    async dismissNotification(notification) {
      if (!confirm("Are you sure you have actioned this message?")) {
        return;
      }
      this.loading = true;
      this.$axios
        .patch(
          `/api/v2/messages/notification/${notification.notification_uid}/dismiss`
        )
        .then(() => {
          this.loading = false;
        });
      const index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
    async ignoreNotification(notification) {
      if (!confirm("Are you sure you want to ignore this message?")) {
        return;
      }
      this.loading = true;
      this.$axios
        .patch(
          `/api/v2/messages/notification/${notification.notification_uid}/ignore`
        )
        .then(() => {
          this.loading = false;
        });
      const index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
    newNotification() {
      this.popup = true;
      if (this.notificationSound) {
        this.notificationSound.play();
      }
    },
  },
  mounted() {
    this.notificationSound = new Audio(require("@/assets/notification.wav"));
    if (this.$axios.defaults.headers.Authorization) {
      this.fetchNotifications();
    }
    setInterval(this.focusMonitor, 1000);
  },
};
</script>
