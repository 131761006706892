const Axs = require("axios");

const options = {
  // baseURL: process.env.BASE_URL
};
const axsAuth = Axs.create(options);
const authorizeURL = "/oauth2/authorize/";

const tokenExpired = (expDateUtc) => {
  const currentTime = new Date(Date.now())
  if (expDateUtc < currentTime) {
    return true
  } else {
    return false
  }
}

export default {
  fetchCredentials: async function(redirect = true) {
    return new Promise((resolve, reject) => {
      axsAuth.get('/oauth2/token/').then(response => {
        let expiresAt = response.data.expires_at
        this.$axios.defaults.headers.Authorization = `Bearer ${response.data.access_token}`
        setInterval(() => {
          if (tokenExpired(new Date(expiresAt))) {
            axsAuth.get('/oauth2/token/')
              .then((response) => {
                expiresAt = response.data.expires_at
                this.$axios.defaults.headers.Authorization = `Bearer ${response.data.access_token}`
              })
              .catch(() => {
                document.location = authorizeURL
              })
          }
        }, 5_000)
        resolve()
      }).catch(() => {
        if (redirect) {
          document.location = authorizeURL
        }
        reject(new Error('Not Logged In'))
      })
    })
  },
  fetchMe: async function(redirect = true) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/me/')
        .then(response => {
          if (!response.data.logged_in) {
            if (redirect) {
              document.location = authorizeURL;
            }
            reject(new Error('Not Logged In, forcing re-auth'))
          }
          this.setMe(response.data)
          resolve()
        })
        .catch((error) => {
          if (error.response && error.response.data === "Invalid Token") {
            document.location = '/oauth2/logout/';
          } else if (redirect) {
            document.location = authorizeURL;
          }
          reject(new Error('Not Logged In, forcing re-auth'))
        })
    })
  }
};
