<template>
  <v-sheet v-if="collection">
    <CollectionHeader />
    <v-row>
      <v-col sm="12" md="12">
        <v-card outlined class="rounded-lg">
          <v-card-title>
            <v-icon class="mr-2">mdi-hexagon-multiple</v-icon>
            Dispatches
          </v-card-title>
          <v-card-text>
            <DispatchesCard
              v-for="(dispatch, index) in collection.dispatches"
              :key="index"
              :dispatch="dispatch"
            />
            <v-row v-if="collection.dispatches.length === 0">
              <v-col class="text-center mb-10">
                <v-icon large class="ma-5">mdi-sleep</v-icon>
                <br />
                No Dispatches
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import DispatchesCard from "./Dispatches/DispatchesCard";

export default {
  name: "Dispatches",
  components: {
    CollectionHeader,
    DispatchesCard
  },
  computed: {
    ...mapGetters(["collection"])
  }
};
</script>
