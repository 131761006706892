<template>
  <v-form ref="evidentForm" lazy-validation v-if="kase">
    <v-row class="mt-3">
      <v-col class="text-center">
        <h4>Evident Outsource Form</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          class="rounded-lg"
          v-model="designType"
          :rules="[designTypeValidation]"
          dense
          outlined
          :items="designTypes"
          label="Design Type"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          class="rounded-lg"
          v-model="scannerType"
          :rules="[scannerTypeValidation]"
          dense
          outlined
          :items="scannerTypes"
          label="Scanner"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          class="rounded-lg"
          v-model="instructions"
          :rules="[instructionsValidation]"
          outlined
          dense
          label="Instructions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          class="rounded-lg"
          v-model="units"
          outlined
          dense
          type="number"
          label="Units (Optional)"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          class="rounded-lg"
          v-model="position"
          :rules="[positionValidation]"
          dense
          outlined
          :items="positions"
          label="Position"
        />
      </v-col>
      <v-col>
        <v-checkbox class="mt-0" v-model="rush" label="Rush Job (Optional)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          class="rounded-lg"
          v-model="fileID"
          :rules="[fileIDValidation]"
          :items="filesToSend"
          outlined
          dense
          label="File to Send"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn
          :disabled="submitting"
          color="success"
          class="rounded-lg"
          @click="startOutsource()"
        >
          <v-icon class="mr-2">mdi-send</v-icon>
          Start
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Evident",
  props: ["partner"],
  data() {
    return {
      submitting: false,
      designType: null,
      designTypes: [
        { text: "Single Abutment (Custom) + Model", value: "100" },
        { text: "Custom Abutment Only", value: "110" },
        { text: "Custom Abutment Over Ti-Base", value: "115" },
        { text: "Single Crown (Ti-Base) + Model", value: "205" },
        { text: "Anatomical Abutment", value: "210" },
        { text: "Anatomical Abutment (Ti-Base)", value: "215" },
        { text: "Anatomical Implant Bridge", value: "220" },
        { text: "Anatomical Implant Bridge (Ti-Base)", value: "225" },
        { text: "Implant Bridge", value: "230" },
        { text: "Implant Bridge (Ti-Base)", value: "235" },
        { text: "Single Crown / Veneer + Model", value: "300" },
        { text: "Full Arch All on X", value: "400" },
        { text: "Evo Denture", value: "500" },
      ],
      scannerType: null,
      scannerTypes: [
        "3Shape",
        "Exocad",
        "Ceramill",
        "Sirona",
        "Straumann",
        "IOS",
        "Others",
      ],
      instructions: null,
      units: null,
      position: null,
      positions: [
        { text: "Anterior", value: "anterior" },
        { text: "Posterior", value: "posterior" },
        { text: "Anterior & Posterior", value: "anterior and posterior" },
      ],
      rush: false,
      fileID: null,
    };
  },
  computed: {
    ...mapGetters(["kase"]),
    filesToSend() {
      return this.kase.outsource_sent_files.map((file) => {
        return {
          text: file.filename,
          value: file.id,
        };
      });
    },
    designTypeValidation() {
      if (!this.designType) {
        return "Must be selected";
      }
      return true;
    },
    scannerTypeValidation() {
      if (!this.scannerType) {
        return "Must be selected";
      }
      return true;
    },
    instructionsValidation() {
      if (!this.instructions) {
        return "Must be supplied";
      }
      return true;
    },
    positionValidation() {
      if (!this.position) {
        return "Must be selected";
      }
      return true;
    },
    fileIDValidation() {
      if (!this.fileID) {
        return "Must be selected";
      }
      return true;
    },
    instructionsWithDesignType() {
      let instructions = "";
      instructions = `### REFERENCE NUMBER: ${this.kase.case_mkey} ###\n\n`;
      const selectedDesignType = this.designTypes.find(
        (designType) => designType.value === this.designType
      );
      if (selectedDesignType) {
        instructions += `${selectedDesignType.text}:\n\n`;
      }
      return instructions + this.instructions;
    },
    evidentDesignTypeTranslation() {
      switch (this.designType) {
        case "100":
          return ["1020", "1051", "1013", "1100"];
        case "110":
          return ["1019", "1100"];
        case "115":
          return ["1019", "1100"];
        case "205":
          return ["1058", "1051", "1100"];
        case "210":
          return ["1021", "1100"];
        case "215":
          return ["1058", "1100"];
        case "220":
          return ["1005", "1021", "1100"];
        case "225":
          return ["1005", "1058", "1100"];
        case "230":
          return ["1005", "1021", "1100"];
        case "235":
          return ["1005", "1058", "1100"];
        case "300":
          return ["1005", "1013", "1100"];
        case "400":
          return ["1044", "1100"];
        case "500":
          return ["1046", "1003", "1100"];
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async startOutsource() {
      if (!this.kase.in_design) {
        if (
          !confirm(
            "This case is not in design - if you start this outsource job, the case will be moved back to the design system. Are you sure this is okay?"
          )
        ) {
          return;
        }
      }
      if (!this.$refs.evidentForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "start_integrated_outsource",
          partner_id: this.partner,
          partner_name: "Evident",
          note: this.instructions,
          file_id: this.fileID,
          payload: {
            DesignType: this.evidentDesignTypeTranslation,
            ScannerType: this.scannerType,
            Instruction: this.instructionsWithDesignType,
            Units: this.units,
            Position: this.position,
            Rush: this.rush,
            RefId: this.kase.case_id,
          },
        })
        .then(() => {
          this.$refs.evidentForm.reset();
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
          this.$emit("completed");
        });
    },
  },
};
</script>
