<template>
  <div v-if="message.attachments.length > 0">
    <ul style="padding-left: 0; list-style-type: none;">
      <li v-for="(attachment, index) in message.attachments" :key="index">
        <a @click="viewFile(attachment)">
          <v-icon small>mdi-paperclip</v-icon>
          {{
            attachment.file_name
              .split("/")
              .pop()
              .slice(7)
          }}
        </a>
      </li>
    </ul>
    <FileViewer v-model="file" type="message_attachment" />
  </div>
</template>

<script>
import FileViewer from "@/shared/Portal/shared/FileViewer";

export default {
  name: "Attachments",
  props: ["message"],
  data() {
    return {
      file: null
    };
  },
  components: {
    FileViewer
  },
  methods: {
    viewFile(file) {
      this.file = file.uid;
    }
  }
};
</script>
