<template>
  <v-card outlined v-if="kase.machine_project.id" class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-select-group</v-icon>
      Production Information
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Project Name</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.machine_project.name }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Machine</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <span v-if="kase.machine_project.machine">
            {{ kase.machine_project.machine }}
          </span>
          <span v-if="!kase.machine_project.machine">
            Not Allocated
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Branch</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.machine_project.branch || kase.fulfillment_branch }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Mill Start Time</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <span v-if="kase.machine_project.start_time">
            {{ kase.machine_project.start_time | luxon({ output: "tz_med" }) }}
            ({{ kase.machine_project.start_time | luxonRelative }})
          </span>
          <span v-if="!kase.machine_project.start_time">
            Not Assigned
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Mill Completion Time</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <span v-if="kase.machine_project.end_time">
            {{ kase.machine_project.end_time | luxon({ output: "tz_med" }) }}
            ({{ kase.machine_project.end_time | luxonRelative }})
          </span>
          <span v-if="!kase.machine_project.end_time">
            Not Assigned
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Project Creator</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.machine_project.created_by }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4">
          <strong>Project Status</strong>
        </v-col>
        <v-col sm="12" lg="8">
          <v-chip
            dark
            color="orange"
            class="rounded-lg"
            v-if="kase.machine_project.status === 'Pending'"
          >
            Programming
          </v-chip>
          <v-chip
            dark
            color="success"
            class="rounded-lg"
            v-if="kase.machine_project.status === 'Ready'"
          >
            Ready for Machining
          </v-chip>
          <v-chip
            dark
            color="success"
            class="rounded-lg"
            v-if="kase.machine_project.status === 'Complete'"
          >
            Complete
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Production",
  computed: {
    ...mapGetters(["kase"])
  }
};
</script>
