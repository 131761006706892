<template>
  <div>
    <v-card outlined class="rounded-lg">
      <v-card-title>
        <v-icon class="mr-2">mdi-history</v-icon>
        Implant Audit
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-center ma-3" v-if="audit.length === 0">
              There is no implant history recorded for this case
            </div>
            <v-card class="overflow-y-auto" max-height="600" elevation="0">
              <v-timeline dense v-if="audit.length > 0">
                <v-timeline-item
                  fill-dot
                  small
                  icon="mdi-tooth"
                  v-for="(change, index) in audit"
                  :key="index"
                  :color="change.action === 'UPDATED' ? 'green' : 'red'"
                >
                  <p>
                    <strong>Tooth {{ change.changed_values.tooth }}</strong>
                    <br />
                    <span v-if="change.action === 'UPDATED'">
                      Updated
                    </span>
                    <span v-if="change.action === 'DELETED'">
                      Removed
                    </span>
                    <span v-if="change.modified_by !== ' '">
                      by
                      <strong>{{ change.modified_by }}</strong>
                    </span>
                    {{ change.modified_at | luxonRelative }}
                    <br />
                    <span v-if="change.changed_values.platform">
                      <i>{{ change.changed_values.platform.string }}</i>
                      <span v-if="change.changed_values.screw">
                        with <i>{{ change.changed_values.screw.name }}</i>
                      </span>
                      <span v-if="!change.changed_values.screw">
                        with Default Screw
                      </span>
                    </span>
                    <span v-if="!change.changed_values.platform">
                      Pontic
                    </span>
                    <br />
                    <span v-if="change.changed_values.bi_axial">
                      <li>Biaxial</li>
                    </span>
                    <span v-if="change.changed_values.engaging">
                      <li>Engaging</li>
                    </span>
                  </p>
                </v-timeline-item>
              </v-timeline>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ImplantAudit",
  props: ["audit"]
};
</script>
