<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-bell</v-icon>
      Immediacy
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="kase.immediacy_due" class="text-center">
          <h1>
            <CountDown :due-time="kase.immediacy_due" />
          </h1>
        </v-col>
        <v-col v-if="!kase.immediacy_due">
          <v-text-field
            class="rounded-lg"
            v-model.number="inputHours"
            dense
            type="number"
            outlined
            clearable
            label="Hours Until Due"
          />
        </v-col>
        <v-col class="text-right">
          <v-btn
            class="rounded-lg"
            color="success"
            v-if="!kase.immediacy_due"
            :disabled="!inputHours"
            @click="startImmediacy()"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            Start
          </v-btn>
          <v-btn
            class="rounded-lg"
            color="error"
            v-if="kase.immediacy_due"
            @click="endImmediacy()"
          >
            <v-icon class="mr-2">mdi-stop</v-icon>
            End
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CountDown from "@/shared/CountDown";

export default {
  name: "Immediacy",
  components: {
    CountDown
  },
  data() {
    return {
      inputHours: null
    };
  },
  computed: {
    ...mapGetters(["kase", "isLoading"]),
    immediacyDueTime() {
      if (!this.inputHours) {
        return;
      }
      const dueTime = new Date();
      dueTime.setHours(dueTime.getHours() + this.inputHours);
      return dueTime;
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async startImmediacy() {
      if (!confirm("Are you sure you wish to start an immediacy?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "start_immediacy",
          immediacy_due: this.immediacyDueTime
        })
        .then(() => {
          this.inputHours = null;
          this.stopLoading();
          this.triggerReload();
        });
    },
    async endImmediacy() {
      if (!confirm("Are you sure you wish to end the immediacy?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "end_immediacy"
        })
        .then(() => {
          this.stopLoading();
          this.triggerReload();
        });
    }
  }
};
</script>
