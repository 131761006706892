<template>
  <div>
    <CollectionHeader />
    <v-card flat>
      <v-card-title>
        <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
        Checklists
      </v-card-title>
      <v-card-text class="pa-0">
        <DepartmentFilter v-model="departmentFilter" />
        <QCChecklist
          :case-uids="casesWithChecklistItems"
          :department="departmentFilter"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import DepartmentFilter from "@/collection/components/shared/DepartmentFilter";
import QCChecklist from "@/shared/QCChecklist";

export default {
  name: "Checklists",
  components: {
    CollectionHeader,
    DepartmentFilter,
    QCChecklist
  },
  data() {
    return {
      departmentFilter: null
    };
  },
  computed: {
    ...mapGetters(["collection"]),
    casesWithChecklistItems() {
      return this.collection.cases
        .filter(
          cases => this.filterAndSortItems(cases.checklist_items).length > 0
        )
        .map(c => c.uid);
    }
  },
  methods: {
    filterAndSortItems(items) {
      return [
        ...items.filter(item => {
          if (!this.departmentFilter || this.departmentFilter.value === "ALL") {
            return true;
          }
          return item.department === this.departmentFilter.value;
        })
      ].sort((a, b) => {
        return a.department.localeCompare(b.department) || a.id - b.id;
      });
    }
  }
};
</script>
