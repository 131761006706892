<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-format-list-checks</v-icon>
      QC Checklist
    </v-card-title>
    <v-card-text>
      <DepartmentFilter v-model="departmentFilter" />
      <QCChecklist :case-uid="kase.uid" :department="departmentFilter" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import QCChecklist from '@/shared/QCChecklist'
import DepartmentFilter from "@/collection/components/shared/DepartmentFilter.vue";

export default {
  name: "Checklist",
  components: {
    QCChecklist,
    DepartmentFilter
  },
  data() {
    return {
      departmentFilter: null
    };
  },
  computed: {
    ...mapGetters(["kase"])
  }
};
</script>
