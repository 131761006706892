<template>
  <div>
    <CollectionHeader />
    <v-card flat>
      <v-card-title>
        <v-icon class="mr-2">mdi-account-check</v-icon>
        Approvals
        <v-spacer />
        <v-btn color="success" class="rounded-lg" @click="newModal = true">
          <v-icon class="mr-2">mdi-plus</v-icon>
          New Approval Request
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row>
          <v-col sm="12" class="text-center my-5" v-if="approvals.length === 0">
            <v-icon large class="mb-5">mdi-sleep</v-icon>
            <br />
            No approval requests have been submitted yet...
          </v-col>
          <v-col
            sm="12"
            lg="4"
            v-for="(approval, index) in approvals"
            :key="index"
          >
            <ApprovalCard :approval="approval" />
          </v-col>
        </v-row>
        <NewApprovalModal v-model="newModal" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import ApprovalCard from "@/collection/components/shared/Approvals/ApprovalCard";
import NewApprovalModal from "@/collection/components/shared/Approvals/NewApprovalModal";

export default {
  name: "Approvals",
  data() {
    return {
      newModal: false
    };
  },
  components: {
    CollectionHeader,
    ApprovalCard,
    NewApprovalModal
  },
  computed: {
    ...mapGetters(["collection"]),
    approvals() {
      return [...this.collection.approvals].sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      );
    }
  }
};
</script>
