<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Request Outsource Redo</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="redoForm" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-textarea
                  class="rounded-lg"
                  v-model="message"
                  :rules="[messageValidation]"
                  outlined
                  dense
                  label="Message"
                />
              </v-col>
            </v-row>
            <v-row v-if="!submitting">
              <v-col class="text-right">
                <v-btn
                  class="rounded-lg"
                  color="success"
                  @click="redoOutsource()"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RedoModal",
  props: ["value"],
  data() {
    return {
      message: null,
      submitting: false
    };
  },
  computed: {
    dialog: {
      get() {
        return !!this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.redoForm.reset();
          this.$emit("input", null);
        }
      }
    },
    messageValidation() {
      if (!this.message) {
        return "Cannot be blank";
      }
      return true;
    },
    redoNotes() {
      return {
        Notes: this.message
      };
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async redoOutsource() {
      if (!this.$refs.redoForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.value.case_id}/`, {
          action: "redo_integrated_outsource",
          outsource_id: this.value.id,
          note: this.message,
          payload: this.redoNotes
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
          this.$refs.redoForm.reset();
          this.dialog = false;
        });
    }
  }
};
</script>
