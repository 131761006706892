<template>
  <v-card elevation="0" class="rounded-lg">
    <v-card-title v-if="!group">
      <v-icon class="mr-2">mdi-clipboard-text</v-icon>
      Order Form
    </v-card-title>
    <v-card-text class="pa">
      <v-card
        outlined
        class="mb-5 rounded-lg"
        v-if="group && caseFromUID(form.case_uid)"
        @click="openCase(form.case_uid)"
      >
        <v-card-text>
          <v-row align="center">
            <v-col sm="3" class="py-1">
              <v-chip outlined>
                <strong>{{ caseFromUID(form.case_uid).case_mkey }}</strong>
              </v-chip>
            </v-col>
            <v-col sm="4" class="py-1">
              <strong>{{ caseFromUID(form.case_uid).job_type }}</strong>
            </v-col>
            <v-col class="py-1 text-right">
              <small
                v-if="
                  !caseFromUID(form.case_uid).dispatched &&
                    !caseFromUID(form.case_uid).cancelled &&
                    !caseFromUID(form.case_uid).ordered
                "
                class="mr-2"
              >
                {{ caseFromUID(form.case_uid).status }}
              </small>
              <v-chip
                small
                color="warning"
                label
                v-if="caseFromUID(form.case_uid).in_design"
              >
                <v-icon class="mr-2">mdi-pencil-ruler</v-icon>
                Design
              </v-chip>
              <v-chip
                small
                color="warning"
                label
                v-if="caseFromUID(form.case_uid).in_production"
              >
                <v-icon class="mr-2">mdi-factory</v-icon>
                Production
              </v-chip>
              <v-chip
                small
                color="warning"
                label
                v-if="caseFromUID(form.case_uid).in_lab"
              >
                <v-icon class="mr-2">mdi-beaker-outline</v-icon>
                Lab
              </v-chip>
              <v-chip
                small
                color="primary"
                label
                v-if="caseFromUID(form.case_uid).ordered"
              >
                <v-icon color="primary" small>mdi-inbox</v-icon>
                Ordered
              </v-chip>
              <v-chip
                small
                color="success"
                label
                v-if="caseFromUID(form.case_uid).dispatched"
              >
                <v-icon class="mr-2">mdi-truck-outline</v-icon>
                Dispatched
              </v-chip>
              <v-chip
                small
                color="error"
                label
                v-if="caseFromUID(form.case_uid).cancelled"
              >
                <v-icon class="mr-2">mdi-cancel</v-icon>
                Cancelled
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-5 rounded-lg">
        <v-card-title>Basic Information</v-card-title>
        <v-card-text>
          <div
            v-for="(key, index) in Object.keys(basicForm(form.json_data))"
            :key="index"
          >
            <v-row v-if="basicForm(form.json_data)[key]">
              <v-col sm="12" lg="6" class="pb-1">
                <strong>{{ key | snakeToTitle }}</strong>
              </v-col>
              <v-col sm="12" lg="6" class="pb-1">
                {{ basicForm(form.json_data)[key] }}
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <div
        class="mb-5"
        v-for="key in Object.keys(nestedVerticalTableForms(form.json_data))"
        :key="key"
      >
        <v-card
          class="rounded-lg"
          outlined
          v-if="nestedVerticalTableForms(form.json_data)[key]"
        >
          <v-card-title>{{ key | snakeToTitle }}</v-card-title>
          <v-card-text>
            <div
              v-for="subKey in Object.keys(
                nestedVerticalTableForms(form.json_data)[key]
              )"
              :key="subKey"
            >
              <v-row
                v-if="nestedVerticalTableForms(form.json_data)[key][subKey]"
              >
                <v-col sm="12" lg="6" class="pb-1">
                  <strong>{{ subKey | snakeToTitle }}</strong>
                </v-col>
                <v-col sm="12" lg="6" class="pb-1">
                  {{ nestedVerticalTableForms(form.json_data)[key][subKey] }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <v-card
        outlined
        v-if="form.json_data['implants']"
        class="mb-5 rounded-lg"
      >
        <v-card-title>Implants</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Tooth</th>
                <th>Platform</th>
                <th>Scan Body</th>
                <th>Bi-Axial</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(implant, index) in form.json_data['implants']"
                :key="index"
              >
                <td>{{ implant.tooth }}</td>
                <td>
                  <small
                    >{{ implant.name }}{{ implant.implant_platform }}</small
                  >
                </td>
                <td>{{ implant.scan_body_type }}</td>
                <td>
                  <v-icon v-if="implant.biAxial">mdi-check</v-icon>
                  <v-icon v-if="!implant.biAxial">mdi-close</v-icon>
                </td>
              </tr>
              <tr v-if="form.json_data['implants'].length === 0">
                <td colspan="4" class="text-center">
                  No Implants Specified
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderForm",
  props: ["form", "group"],
  data() {
    return {
      basicKeys: [
        "solution",
        "attachments",
        "oep_api_secret",
        "client",
        "agree",
        "parent_case_id",
        "tag"
      ],
      nestedVerticalTableKeys: [
        "lower_options",
        "upper_options",
        "preop_options"
      ]
    };
  },
  computed: {
    ...mapGetters(["orderForms", "collection"])
  },
  methods: {
    ...mapActions(["setCaseUID", "setCurrentView"]),
    caseFromUID(uid) {
      return this.collection.cases.find(kase => kase.uid === uid);
    },
    openCase(uid) {
      this.setCaseUID(uid);
      this.setCurrentView("components");
    },
    basicForm(form) {
      return Object.keys(form).reduce((object, key) => {
        if (
          !this.basicKeys.includes(key) &&
          !this.nestedVerticalTableKeys.includes(key) &&
          key !== "implants"
        ) {
          object[key] = form[key];
        }
        return object;
      }, {});
    },
    nestedVerticalTableForms(form) {
      return Object.keys(form).reduce((object, key) => {
        if (this.nestedVerticalTableKeys.includes(key)) {
          object[key] = form[key];
        }
        return object;
      }, {});
    }
  },
  filters: {
    snakeToTitle(str) {
      str = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      return str
        .split("_")
        .map(function(item) {
          return item.charAt(0).toUpperCase() + item.substring(1);
        })
        .join(" ");
    }
  }
};
</script>
