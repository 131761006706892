<template>
  <v-snackbar timeout="-1" v-model="updateAvailable">
    A new version of OEP is available, page will auto reload in
    {{ countdown }} seconds.
    <template v-slot:action="{ attrs }">
      <v-btn color="red" text v-bind="attrs" @click="reload()">
        Reload Now
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
const Axs = require("axios");
const axsUpgrade = Axs.create();
const versionCheckUrl = "/api/version/";

export default {
  name: "UpdateAvailable",
  data() {
    return {
      version: null,
      updateAvailable: false,
      countdown: 600
    };
  },
  methods: {
    reload() {
      window.location.reload();
    },
    startCountdown() {
      this.updateAvailable = true;
      setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.reload();
        }
      }, 1000);
    },
    async fetchVersion() {
      axsUpgrade.get(versionCheckUrl).then(response => {
        if (this.version && response.data.version !== this.version) {
          setTimeout(() => {
            this.startCountdown();
          }, 600_000);
        }
        this.version = response.data.version;
      });
    }
  },
  mounted() {
    setInterval(this.fetchVersion, 10000);
  }
};
</script>
