<template>
  <v-card class="mb-5">
    <v-toolbar color="warning" dark>
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-alert</v-icon>
        Action Required
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      You have
      <span v-if="openApprovals.length === 1">
        an approval
      </span>
      <span v-if="openApprovals.length > 1">
        {{ openApprovals.length }} approvals
      </span>
      requiring attention, see below for more information.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ActionRequired",
  computed: {
    ...mapGetters(["branding", "openApprovals", "collection", "casesByDentate"])
  },
  methods: {
    caseFromUID(uid) {
      const kase = this.collection.cases.find(kase => kase.uid === uid);
      if (!kase) {
        return;
      }
      let dentate = "OTHER";
      if (this.casesByDentate) {
        if (this.casesByDentate.upper.includes(kase)) {
          dentate = "UPPER";
        }
        if (this.casesByDentate.lower.includes(kase)) {
          dentate = "LOWER";
        }
      }
      return {
        ...kase,
        dentate: dentate
      };
    }
  }
};
</script>
