<template>
  <div v-if="portalLink">
    <v-row>
      <v-col sm="12">
        <v-card class="mb-5 rounded-lg" :outlined="!!outlined">
          <v-card-title>
            <v-icon class="mr-2">mdi-link</v-icon>
            Portal Link
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="rounded-lg"
                  outlined
                  dense
                  readonly
                  label="URL"
                  ref="portalLink"
                  :value="portalLink"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      class="rounded-lg"
                      style="top: -8px"
                      @click="copyPortalLink()"
                    >
                      <v-icon class="mr-2">
                        mdi-content-copy
                      </v-icon>
                      Copy
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PortalLinkCard",
  props: ["portalKey", "outlined"],
  computed: {
    portalLink() {
      if (!this.portalKey) {
        return false;
      }
      const windowURL = window.location;
      const baseURL =
        windowURL.protocol +
        "//" +
        windowURL.host +
        "/" +
        windowURL.pathname.split("/")[1];
      return `${baseURL}/v2/customer.html?key=${this.portalKey}`;
    }
  },
  methods: {
    copyPortalLink() {
      navigator.clipboard.writeText(this.portalLink);
    }
  }
};
</script>
