<template>
  <div>
    <CollectionHeader />
    <v-card flat>
      <v-card-title>
        <v-icon class="mr-2">mdi-clipboard-text</v-icon>
        Order Forms
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row>
          <v-col
            sm="12"
            lg="6"
            v-for="(form, index) in orderForms"
            :key="`form${index}`"
          >
            <OrderForm :group="true" :form="form" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            sm="12"
            class="text-center my-10"
            v-if="orderForms.length === 0"
          >
            <v-icon>mdi-file-question</v-icon>
            <p>
              No Order Forms...
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import OrderForm from "@/collection/components/shared/OrderForm";

export default {
  name: "OrderForms",
  components: {
    CollectionHeader,
    OrderForm
  },
  computed: {
    ...mapGetters(["orderForms"])
  }
};
</script>
