<template>
  <v-row>
    <v-col sm="12" lg="12" v-if="simple">
      <ul>
        <li>
          <i>{{ Object.keys(cleanWasObjects)[0] | snakeToTitle }}</i>
          <span v-if="cleanWasObjects[Object.keys(cleanWasObjects)[0]]">
            from
            <strong>{{
              cleanWasObjects[Object.keys(cleanWasObjects)[0]]
            }}</strong>
          </span>
          to
          <strong>{{ cleanIsObjects[Object.keys(cleanIsObjects)[0]] }}</strong>
        </li>
      </ul>
    </v-col>
    <v-col sm="12" lg="6" v-if="!simple">
      <v-card elevation="0">
        <v-card-title>Before</v-card-title>
        <v-card-text>
          <ul>
            <Attribute
              v-for="(item, key) in wasObject"
              :key="key"
              :attribute="key"
              :value="item"
            />
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col sm="12" lg="6" v-if="!simple">
      <v-card elevation="0">
        <v-card-title>After</v-card-title>
        <v-card-text>
          <ul>
            <Attribute
              v-for="(item, key) in isObject"
              :key="key"
              :attribute="key"
              :value="item"
            />
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { diff } from "deep-object-diff";
import hiddenAttributes from "./hidden_attributes";
import Attribute from "./Attribute";

export default {
  name: "Changes",
  props: ["firstChange", "secondChange", "simple"],
  components: {
    Attribute
  },
  computed: {
    wasObject() {
      return diff(this.firstChange, this.secondChange);
    },
    cleanWasObjects() {
      return Object.keys(this.wasObject)
        .filter(key => !hiddenAttributes.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.wasObject[key];
          return obj;
        }, {});
    },
    isObject() {
      return diff(this.secondChange, this.firstChange);
    },
    cleanIsObjects() {
      return Object.keys(this.isObject)
        .filter(key => !hiddenAttributes.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.isObject[key];
          return obj;
        }, {});
    }
  },
  methods: {
    cleanValue(value) {
      switch (typeof value) {
        case "string":
        case "number":
          return value;
        case "boolean":
          return value ? "Yes" : "No";
        default:
          return false;
      }
    }
  },
  filters: {
    snakeToTitle(str) {
      str = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      return str
        .split("_")
        .map(function(item) {
          return item.charAt(0).toUpperCase() + item.substring(1);
        })
        .join(" ");
    }
  }
};
</script>
