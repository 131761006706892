<template>
  <v-card outlined v-if="kase.order_files" class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-file</v-icon>
      Order Files
    </v-card-title>
    <v-card-text v-if="uploading" class="text-center">
      <v-card height="256" flat elevation="0">
        <v-progress-circular size="100" indeterminate />
      </v-card>
    </v-card-text>
    <v-card-text v-if="!uploading">
      <v-card class="overflow-y-auto" height="256" flat elevation="0">
        <v-list dense>
          <v-list-item v-for="file in kase.order_files" :key="file.id">
            <v-list-item-icon @click="viewFile(file)" style="cursor: pointer">
              <v-icon>mdi-paperclip</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="viewFile(file)"
              style="cursor: pointer"
            >
              {{ file.filename | sanitizeFilename }}
              <small
                ><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="text-center mb-5" v-if="kase.order_files.length === 0">
          <v-icon large class="ma-5">mdi-sleep</v-icon>
          <br />
          No Order Files...
        </div>
      </v-card>
      <v-file-input
        class="rounded-lg"
        outlined
        dense
        v-model="uploads"
        label="Upload Order Files"
        multiple
        @change="triggerUploadFiles()"
      />
    </v-card-text>
    <FileViewModal v-model="file" type="order_file" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileViewModal from "@/collection/components/shared/FileViewModal";

export default {
  name: "OrderFiles",
  components: {
    FileViewModal
  },
  computed: {
    ...mapGetters(["kase"])
  },
  data() {
    return {
      file: null,
      uploads: [],
      uploading: false
    };
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async triggerUploadFiles() {
      this.uploading = true;
      const formData = new FormData();
      formData.append("action", "upload_files");
      formData.append("type", "order_file");
      this.uploads.forEach(upload => {
        formData.append("files", upload, upload.name);
      });
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.uploading = false;
          this.uploads = [];
          this.triggerReload();
        });
    },
    viewFile(file) {
      this.file = file;
    }
  }
};
</script>
