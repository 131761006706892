<template>
  <v-row justify="center">
    <v-dialog v-model="newApprovalModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="newApprovalModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>New Approval Request</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-form ref="approvalForm" lazy-validation>
            <v-autocomplete
              v-model="approvalType"
              :rules="[validateApprovalType]"
              dense
              outlined
              :items="approvalTypeItems"
              class="rounded-lg"
              label="Approval Type"
            />
            <v-autocomplete
              v-model="caseUID"
              :rules="[validateCaseComponent]"
              dense
              outlined
              :items="caseItems"
              v-if="!kase || !caseUID"
              class="rounded-lg"
              label="Case Component"
            />
            <v-text-field
              dense
              :rules="[validateDueTime]"
              outlined
              v-model="dueTime"
              label="Due Time"
              class="rounded-lg"
              type="datetime-local"
            />
            <p v-if="approvalDurationDays" class="mb-5">
              {{ selectedApprovalTypeName }} standard due time is
              <strong>{{ approvalDurationDays }}</strong> day<span
                v-if="approvalDurationDays > 1"
                >s</span
              >
            </p>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="reminders"
                  label="Send Reminders to Client"
                  class="my-0"
                />
              </v-col>
              <v-col cols="6">
                <v-chip color="primary" v-if="reminderFrequency">
                  <v-icon class="mr-2">mdi-alarm</v-icon>
                  Remind every {{ reminderFrequency }}
                </v-chip>
              </v-col>
            </v-row>
            <v-textarea
              dense
              outlined
              class="rounded-lg"
              v-model="message"
              label="Message to Client"
            />
            <v-file-input
              dense
              outlined
              label="Approval Files"
              class="rounded-lg"
              v-model="files"
              multiple
            />
            <v-row>
              <v-col class="pt-0 text-right">
                <v-chip
                  small
                  v-for="(file, index) in files"
                  :key="index"
                  close
                  class="mr-2 mt-1"
                  @click:close="removeFile(file)"
                >
                  <v-icon>mdi-paperclip</v-icon>
                  {{ file.name }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right" v-if="!loading">
                <v-btn
                  color="success"
                  class="rounded-lg"
                  @click="triggerRequestApproval()"
                >
                  <v-icon class="mr-2">mdi-send</v-icon>
                  Send
                </v-btn>
              </v-col>
              <v-col class="text-center" v-if="loading">
                <v-progress-linear indeterminate color="primary">
                </v-progress-linear>
                <br />
                Sending your approval request...
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NewApprovalModal",
  props: ["value", "kase"],
  watch: {
    approvalType(value) {
      if (!value) {
        return;
      }
      this.dueTime = this.defaultDueTime;
    }
  },
  data() {
    return {
      loading: false,
      approvalType: null,
      caseUID: null,
      dueTime: null,
      reminders: true,
      message: "",
      files: [],
      standardDueTimes: [
        {
          approvalCode: "TRY_IN_APPROVAL",
          days: 7
        },
        {
          default: true,
          days: 1
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["collection", "approvalTypes"]),
    approvalTypeItems() {
      return this.approvalTypes.map(type => {
        return {
          text: type.name,
          value: type.code
        };
      });
    },
    dueTimeWithZone() {
      if (!this.dueTime) {
        return;
      }
      return DateTime.fromISO(this.dueTime).toISO();
    },
    selectedApprovalTypeName() {
      if (!this.approvalType) {
        return;
      }
      const approvalType = this.approvalTypes.find(
        type => type.code === this.approvalType
      );
      if (!approvalType) {
        return;
      }
      return approvalType.name;
    },
    approvalDurationDays() {
      if (!this.approvalType) {
        return;
      }
      let dueTime = this.standardDueTimes.find(
        time => time.approvalCode === this.approvalType
      );
      if (!dueTime) {
        dueTime = this.standardDueTimes.find(time => time.default);
      }
      return dueTime.days;
    },
    defaultDueTime() {
      if (!this.approvalDurationDays) {
        return;
      }
      return DateTime.now()
        .plus({ days: this.approvalDurationDays })
        .toFormat("yyyy-MM-dd'T'HH:mm:ss");
    },
    caseItems() {
      return this.collection.cases.map(kase => {
        return {
          text: `${kase.job_type} (${kase.collection_reference})`,
          value: kase.uid
        };
      });
    },
    newApprovalModal: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.approvalForm.reset();
          this.$emit("input", value);
        }
        if (this.kase) {
          this.caseUID = this.kase.uid;
        }
      }
    },
    validateApprovalType() {
      if (!this.approvalType) {
        return "Must be selected";
      }
      return true;
    },
    validateCaseComponent() {
      if (!this.caseUID) {
        return "Must be selected";
      }
      return true;
    },
    validateDueTime() {
      if (!this.dueTime) {
        return "Must be provided";
      }
      if (DateTime.fromISO(this.dueTime).diffNow("seconds").seconds < 1_800) {
        return "Should be at least 30 minutes from now";
      }
      return true;
    },
    reminderFrequency() {
      if (!this.dueTime || !this.reminders) {
        return;
      }
      const frequencySeconds = DateTime.fromISO(this.dueTime).diffNow("seconds")
        .seconds;
      if (frequencySeconds <= 0) {
        return;
      }
      if (frequencySeconds < 3_600) {
        return `${DateTime.fromISO(this.dueTime)
          .diffNow("minutes")
          .minutes.toFixed(0)} minutes`;
      }
      if (frequencySeconds < 86_400) {
        return `${DateTime.fromISO(this.dueTime)
          .diffNow("hours")
          .hours.toFixed(0)} hours`;
      }
      return `${DateTime.fromISO(this.dueTime)
        .diffNow("days")
        .days.toFixed(0)} days`;
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index < 0) {
        return;
      }
      this.files.splice(index, 1);
    },
    async triggerRequestApproval() {
      if (!this.$refs.approvalForm.validate()) {
        return;
      }
      if (!this.message || (this.message && this.message.length === 0)) {
        if (
          !confirm("Your message is empty, are you sure you want to continue?")
        ) {
          return;
        }
      }
      if (!this.files || (this.files && this.files.length === 0)) {
        if (
          !confirm(
            "You have no approval files attached, are you sure you want to continue?"
          )
        ) {
          return;
        }
      }
      this.loading = true;
      this.startLoading();
      const formData = new FormData();
      formData.append("approval_type", this.approvalType);
      formData.append("case_uid", this.caseUID);
      formData.append("approval_due_at", this.dueTimeWithZone);
      formData.append("send_reminders", this.reminders);
      formData.append("message", this.message);
      this.files.forEach(file => {
        formData.append("files", file, file.name);
      });
      this.$axios
        .post("/api/v2/approvals/", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.loading = false;
          this.newApprovalModal = false;
        });
    }
  },
  mounted() {
    if (this.kase) {
      this.caseUID = this.kase.uid;
    }
  }
};
</script>
