<template>
  <v-card min-height="100%" outlined class="rounded-lg">
    <v-card-text>
      <v-text-field
        v-model="searchFilter"
        dense
        outlined
        clearable
        label="Search"
        class="mt-5 rounded-lg"
      />
      <v-autocomplete
        class="rounded-lg"
        v-model="jobTypeFilter"
        dense
        outlined
        clearable
        multiple
        :items="jobTypes"
        label="Filter by Job Type"
      >
        <template v-slot:item="data">
          <v-list-tile-content class="rounded-lg">
            <div class="ma-3">
              <v-avatar size="32" color="primary" class="mr-2">
                <span class="white--text">
                  {{ countMessagesForJobType(data.item) }}
                </span>
              </v-avatar>
              {{ data.item }}
            </div>
          </v-list-tile-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        class="rounded-lg"
        v-model="messageTypeFilter"
        dense
        outlined
        clearable
        multiple
        :items="populatedMessageTypes"
        label="Filter by Message Type"
      >
        <template v-slot:item="data">
          <div class="ma-3">
            <v-avatar size="32" color="primary" class="mr-2">
              <span class="white--text">
                {{ countMessagesForMessageType(data.item) }}
              </span>
            </v-avatar>
            {{ data.item }}
          </div>
        </template>
      </v-autocomplete>
      <v-autocomplete
        class="rounded-lg"
        v-model="approvalFilter"
        dense
        outlined
        clearable
        multiple
        :items="approvals"
        label="Filter by Approval Requests"
      >
        <template v-slot:item="data">
          <div class="ma-3">
            <v-avatar size="32" color="primary" class="mr-2">
              <span class="white--text">
                {{ countMessagesForApproval(data.item) }}
              </span>
            </v-avatar>
            {{ data.item }}
          </div>
        </template>
      </v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MessageFilter",
  props: ["value"],
  watch: {
    mergedFilters(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    ...mapGetters(["collection", "messages", "messageTypes", "messageFilter"]),
    searchFilter: {
      get() {
        if (this.messageFilter.searchFilter) {
          return this.messageFilter.searchFilter;
        }
        return "";
      },
      set(value) {
        this.setMessageFilter({
          ...this.messageFilter,
          searchFilter: value
        });
      }
    },
    jobTypeFilter: {
      get() {
        if (this.messageFilter.jobTypeFilter) {
          return this.messageFilter.jobTypeFilter;
        }
        return [];
      },
      set(value) {
        this.setMessageFilter({
          ...this.messageFilter,
          jobTypeFilter: value
        });
      }
    },
    messageTypeFilter: {
      get() {
        if (this.messageFilter.messageTypeFilter) {
          return this.messageFilter.messageTypeFilter;
        }
        return [];
      },
      set(value) {
        this.setMessageFilter({
          ...this.messageFilter,
          messageTypeFilter: value
        });
      }
    },
    approvalFilter: {
      get() {
        if (this.messageFilter.approvalFilter) {
          return this.messageFilter.approvalFilter;
        }
        return [];
      },
      set(value) {
        this.setMessageFilter({
          ...this.messageFilter,
          approvalFilter: value
        });
      }
    },
    mergedFilters() {
      if (
        !this.searchFilter &&
        this.jobTypeFilter.length === 0 &&
        this.messageTypeFilter.length === 0 &&
        this.approvalFilter.length === 0
      ) {
        return false;
      }

      let approvalUIDs = [];
      const messageTypeUIDs = [];
      const jobTypeUIDs = [];
      const approvalMessageUIDs = [];
      const communicationUIDS = [];
      let filteredMessages = this.messages;

      if (this.searchFilter) {
        filteredMessages = filteredMessages.filter(message => {
          return (
            message.author_reference
              .toUpperCase()
              .includes(this.searchFilter.toUpperCase()) ||
            (message.recipient_reference || "")
              .toUpperCase()
              .includes(this.searchFilter.toUpperCase()) ||
            message.note.toUpperCase().includes(this.searchFilter.toUpperCase())
          );
        });
        if (this.collection && this.collection.communications.length > 0) {
          communicationUIDS.push(
            ...this.collection.communications
              .filter(communication => {
                return this.toTitleCase(communication.communication_type)
                  .toUpperCase()
                  .includes(this.searchFilter.toUpperCase());
              })
              .map(message => message.uid)
          );
        }
      }

      if (
        this.jobTypeFilter.length === 0 &&
        this.messageTypeFilter.length === 0 &&
        this.approvalFilter.length === 0 &&
        communicationUIDS.length === 0
      ) {
        return filteredMessages.map(message => message.uid);
      }

      this.jobTypeFilter.forEach(filter => {
        const messages = filteredMessages.filter(message => {
          if (!message.case_uid) {
            return false;
          }
          const kase = this.collection.cases.find(
            kase => kase.uid === message.case_uid
          );
          if (!kase) {
            return false;
          }
          if (`${kase.job_type} (${kase.collection_reference})` !== filter) {
            return false;
          }
          return true;
        });
        const approvals = this.collection.approvals.filter(approval => {
          const kase = this.collection.cases.find(
            kase => kase.uid === approval.case_uid
          );
          if (!kase) {
            return false;
          }
          if (`${kase.job_type} (${kase.collection_reference})` !== filter) {
            return false;
          }
          return true;
        });
        if (messages.length === 0 && approvals.length === 0) {
          return;
        }
        jobTypeUIDs.push(
          ...messages.map(message => message.uid),
          ...approvals.map(approval => approval.uid)
        );
      });

      this.messageTypeFilter.forEach(filter => {
        const messageType = this.messageTypes.find(
          type => type.name === filter
        );
        if (!messageType) {
          return;
        }
        messageTypeUIDs.push(
          ...filteredMessages
            .filter(message => message.message_type === messageType.code)
            .map(message => message.uid)
        );
        if (messageType.code === "APPROVAL_MESSAGE") {
          messageTypeUIDs.push(
            ...this.collection.approvals.map(approval => approval.uid)
          );
        }
      });

      this.approvalFilter.forEach(filter => {
        approvalUIDs = this.collection.approvals
          .filter(approval => {
            const kase = this.collection.cases.find(
              kase => approval.case_uid === kase.uid
            );
            if (kase) {
              return (
                filter ===
                `${this.toTitleCase(approval.approval_type)} - ${kase.job_type}`
              );
            }
          })
          .map(approval => approval.uid);
        approvalMessageUIDs.push(...approvalUIDs);
        approvalMessageUIDs.push(
          ...filteredMessages
            .filter(message => approvalUIDs.includes(message.approval_uid))
            .map(message => message.uid)
        );
      });

      let filteredUIDs = [];

      if (jobTypeUIDs.length > 0) {
        filteredUIDs.push(...jobTypeUIDs);
      }

      if (messageTypeUIDs.length > 0) {
        if (filteredUIDs.length > 0) {
          filteredUIDs = filteredUIDs.filter(uid =>
            messageTypeUIDs.includes(uid)
          );
        } else {
          filteredUIDs.push(...messageTypeUIDs);
        }
      }

      if (approvalMessageUIDs.length > 0) {
        if (filteredUIDs.length > 0) {
          filteredUIDs = filteredUIDs.filter(uid =>
            approvalMessageUIDs.includes(uid)
          );
        } else {
          filteredUIDs.push(...approvalMessageUIDs);
        }
      }

      if (communicationUIDS.length > 0) {
        if (filteredUIDs.length > 0) {
          filteredUIDs = filteredUIDs.filter(uid =>
            communicationUIDS.includes(uid)
          );
        } else {
          filteredUIDs.push(...communicationUIDS);
        }
      }

      return filteredUIDs;
    },
    jobTypes() {
      return Array.from(
        new Set(
          this.messages
            .filter(message => !!message.case_uid)
            .map(message => {
              const kase = this.collection.cases.find(
                kase => kase.uid === message.case_uid
              );
              if (kase) {
                return `${kase.job_type} (${kase.collection_reference})`;
              }
            })
        )
      );
    },
    approvals() {
      return this.collection.approvals.map(approval => {
        const kase = this.collection.cases.find(
          kase => approval.case_uid === kase.uid
        );
        if (kase) {
          return `${this.toTitleCase(approval.approval_type)} - ${
            kase.job_type
          }`;
        }
      });
    },
    populatedMessageTypes() {
      return this.messageTypes
        .filter(type => this.countMessagesForMessageType(type.name) > 0)
        .map(type => type.name);
    }
  },
  methods: {
    ...mapActions(["setMessageFilter"]),
    countMessagesForJobType(jobType) {
      return this.messages.filter(message => {
        if (!message.case_uid) {
          return;
        }
        const kase = this.collection.cases.find(
          kase => kase.uid === message.case_uid
        );
        if (
          kase &&
          `${kase.job_type} (${kase.collection_reference})` === jobType
        ) {
          return true;
        }
        return false;
      }).length;
    },
    countMessagesForMessageType(messageTypeName) {
      const messageType = this.messageTypes.find(
        type => type.name === messageTypeName
      );
      if (!messageType) {
        return 0;
      }
      return this.messages.filter(
        message => message.message_type === messageType.code
      ).length;
    },
    countMessagesForApproval(approvalDescription) {
      const approval = this.collection.approvals.find(approval => {
        const kase = this.collection.cases.find(
          kase => approval.case_uid === kase.uid
        );
        if (kase) {
          return (
            approvalDescription ===
            `${this.toTitleCase(approval.approval_type)} - ${kase.job_type}`
          );
        }
      });
      if (!approval) {
        return 0;
      }
      return this.messages.filter(
        message => message.approval_uid === approval.uid
      ).length;
    },
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    }
  },
  mounted() {
    this.$emit("input", this.mergedFilters);
  }
};
</script>
