import Vue from "vue";
import VueLuxon from "vue-luxon";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueObserveVisibility from "vue-observe-visibility";
import vuetify from "@/plugins/vuetify";
import "@/plugins/axios";
import App from "./App.vue";
import store from "./store";
import initSentry from "@/plugins/sentry"

Vue.config.productionTip = false;
Vue.use(VueLuxon, {
  templates: {
    tz_med: {
      zone: "local",
      format: "med",
    },
    tz_date_med: {
      zone: "local",
      format: "date_med",
    },
    tz_date_full: {
      zone: "local",
      format: "date_full",
    },
  },
  input: {
    zone: "Australia/Melbourne",
    format: "iso",
  },
  output: {
    zone: "Australia/Melbourne",
    format: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  },
});
Vue.use(VueSmoothScroll);
Vue.use(VueObserveVisibility);

Vue.filter("sanitizeFilename", (value) => {
  if (!value) {
    return "";
  }
  return value
    .split("/")
    .pop()
    .slice(7);
});

initSentry()

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
