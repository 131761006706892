<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-account-arrow-right</v-icon>
      {{ outsource.partner }}
      <v-spacer />
      <v-chip :color="status.color" class="pl-1">
        <v-chip small class="mr-2" color="secondary">
          {{ outsource.upload_not_required ? "Physical" : "Digital" }}
        </v-chip>
        {{ status.text }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row v-if="outsource.tracking_number" class="mb-2">
        <v-col cols="4">
          Tracking Number:
        </v-col>
        <v-col cols="8">
          <strong>{{ outsource.tracking_number }}</strong>
        </v-col>
      </v-row>
      <v-row v-if="outsource.outsource_key && !outsource.end && !isIntegrated">
        <v-col>
          <v-text-field
            outlined
            dense
            readonly
            hide-details
            label="Share Link"
            ref="portalLink"
            :value="outsourceLink"
          >
            <template v-slot:append-outer>
              <v-btn
                class="rounded-lg"
                style="top: -8px"
                @click="copyValue(outsourceLink)"
              >
                <v-icon class="mr-2">
                  mdi-content-copy
                </v-icon>
                Copy
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-timeline dense>
        <v-timeline-item
          fill-dot
          small
          v-for="(item, index) in timeline"
          :key="index"
          :icon="item.icon"
        >
          <h5>{{ item.description }}</h5>
          <p>
            <i>{{ item.time | luxon({ output: "tz_med" }) }}</i>
          </p>
          <p v-if="item.message">
            {{ item.message }}
          </p>
        </v-timeline-item>
      </v-timeline>
      <div v-if="isIntegrated">
        <v-row>
          <v-col>
            <h3>
              <v-icon>mdi-sync</v-icon>
              Integrated Outsource Job
            </h3>
          </v-col>
        </v-row>
        <v-row
          v-for="(ior, index) in outsource.integrated_outsource_requests"
          :key="index"
        >
          <v-col>
            <v-row>
              <v-col sm="12" lg="4" class="pb-0">
                <strong>Status</strong>
              </v-col>
              <v-col sm="12" lg="8" class="pb-0">
                <v-chip v-if="!ior.sent_at && !ior.error" color="warning">
                  <v-icon class="mr-2">mdi-clock</v-icon>
                  Queued to Send
                </v-chip>
                <v-chip v-if="ior.error" color="error">
                  <v-icon class="mr-2">mdi-alert</v-icon>
                  Error
                </v-chip>
                <v-chip v-if="ior.sent_at && !ior.error" color="success">
                  <v-icon class="mr-2">mdi-send</v-icon>
                  Sent
                </v-chip>
                <v-chip
                  v-if="ior.redo_requested_at"
                  color="warning"
                  class="ml-2"
                >
                  <v-icon class="mr-2">mdi-refresh</v-icon>
                  Redo
                </v-chip>
              </v-col>
            </v-row>
            <v-row v-if="ior.retry_count > 0">
              <v-col sm="12" lg="4" class="pb-0">
                <strong>Retry Attempts</strong>
              </v-col>
              <v-col sm="12" lg="8">
                {{ ior.retry_count }}
                (will try up to 10 times)
              </v-col>
            </v-row>
            <v-row
              v-if="ior.error_detail && (ior.error || ior.retry_count > 0)"
            >
              <v-col sm="12" lg="4" class="pb-0">
                <strong>Last Error Message</strong>
              </v-col>
              <v-col sm="12" lg="8">
                <small>
                  <code>{{ ior.error_detail }}</code>
                </small>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="4" class="pb-0">
                <strong>Partner Reference</strong>
              </v-col>
              <v-col sm="12" lg="8" class="pb-0">
                <pre>{{ ior.partner_reference || "Pending" }}</pre>
              </v-col>
            </v-row>
            <EvidentStatus :ior="ior" v-if="ior.adapter_type === 'EVIDENT'" />
            <v-row>
              <v-col class="text-center">
                <small
                  >Last Updated:
                  {{ ior.modified_at | luxon({ output: "tz_med" }) }}</small
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  v-if="
                    ior.completed_at &&
                      !(ior.redo_requested_at && !ior.redo_sent_at)
                  "
                  :disabled="submitting"
                  class="mr-3 rounded-lg"
                  dark
                  color="warning"
                  @click="redoOutsource = outsource"
                >
                  <v-icon class="mr-2">mdi-refresh</v-icon>
                  Redo
                </v-btn>
                <v-btn
                  :disabled="submitting"
                  class="mr-3 rounded-lg"
                  dark
                  color="error"
                  v-if="!outsource.end"
                  @click="endOutsource()"
                >
                  <v-icon class="mr-2">mdi-stop</v-icon>
                  End
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <RedoModal v-model="redoOutsource" />
      </div>
      <div v-if="!isIntegrated">
        <v-form
          lazy-validation
          ref="outsourceMessageForm"
          v-if="status.text !== 'Complete'"
        >
          <v-textarea
            v-model="outsourceMessage"
            outlined
            dense
            :rules="[messageValidation]"
            class="rounded-lg"
            label="New Outsource Message"
          />
          <v-row>
            <v-col class="text-right">
              <v-btn
                :disabled="submitting"
                class="mr-3 rounded-lg"
                dark
                color="error"
                @click="endOutsource()"
              >
                <v-icon class="mr-2">mdi-stop</v-icon>
                End
              </v-btn>
              <v-btn
                :disabled="submitting"
                class="success rounded-lg"
                @click="sendMessage()"
              >
                <v-icon class="mr-2">mdi-send</v-icon>
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EvidentStatus from "./JobCard/EvidentStatus";
import RedoModal from "./JobCard/RedoModal";

export default {
  name: "JobCard",
  props: ["outsource"],
  components: {
    EvidentStatus,
    RedoModal,
  },
  data() {
    return {
      submitting: false,
      outsourceMessage: null,
      redoOutsource: null,
    };
  },
  computed: {
    ...mapGetters(["kase", "messages"]),
    status() {
      if (this.outsource.end) {
        return { text: "Complete", color: "success" };
      } else if (this.outsource.marked_as_completed) {
        return { text: "Review", color: "warning" };
      } else if (this.outsource.overdue_reminder) {
        return { text: "Overdue", color: "error" };
      } else if (this.outsource.partner_status === "IN_PROGRESS") {
        return { text: "In Progress", color: "primary" };
      } else {
        return { text: "Not Started", color: "default" };
      }
    },
    messageValidation() {
      if (!this.outsourceMessage) {
        return "Cannot be blank";
      }
      return true;
    },
    timeline() {
      const timeline = [];
      timeline.push({
        time: this.outsource.start,
        description: "Request Sent",
        icon: "mdi-send",
      });
      timeline.push({
        time: this.outsource.expected_return,
        description: "Due Time",
        icon: "mdi-alarm",
      });
      if (this.outsource.marked_as_completed) {
        timeline.push({
          time: this.outsource.marked_as_completed,
          description: "Partner Marked as Completed",
          icon: "mdi-account-check",
        });
      }
      if (this.outsource.end) {
        timeline.push({
          time: this.outsource.end,
          description: "Osteon Marked as Completed",
          icon: "mdi-check-all",
        });
      }
      if (this.outsource.due_soon_reminder) {
        timeline.push({
          time: this.outsource.due_soon_reminder,
          description: "Due Soon Reminder Sent",
          icon: "mdi-clock-fast",
        });
      }
      if (this.outsource.overdue_reminder) {
        timeline.push({
          time: this.outsource.overdue_reminder,
          description: "Overdue Reminder Sent",
          icon: "mdi-clock-alert",
        });
      }
      this.messages
        .filter((message) => {
          return message.outsource_uid === this.outsource.uid;
        })
        .forEach((note) => {
          timeline.push({
            time: note.created_at,
            description: `Message from ${note.author_reference}`,
            icon: "mdi-message",
            message: note.note,
          });
        });
      return timeline.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
    },
    outsourceLink() {
      if (!this.outsource) {
        return false;
      }
      const windowURL = window.location;
      const baseURL =
        windowURL.protocol +
        "//" +
        windowURL.host +
        "/" +
        windowURL.pathname.split("/")[1];
      return `${baseURL}/v2/outsource.html?key=${this.outsource.outsource_key}`;
    },
    isIntegrated() {
      return this.outsource.integrated_outsource_requests.length > 0;
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    copyValue(value) {
      navigator.clipboard.writeText(value);
    },
    async endOutsource() {
      if (!confirm("Are you sure?")) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "end_outsource",
          outsource_id: this.outsource.id,
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
        });
    },
    async sendMessage() {
      if (!this.$refs.outsourceMessageForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "send_outsource_message",
          outsource_id: this.outsource.id,
          message: this.outsourceMessage,
        })
        .then(() => {
          this.$refs.outsourceMessageForm.reset();
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
        });
    },
  },
};
</script>
