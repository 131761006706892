<template>
  <div v-if="invoiceExists">
    <v-alert type="warning" color="red" v-if="selectedInvoice.is_cash_customer">
      Cash Customers will not be auto invoiced. Manual Invoicing is required.
    </v-alert>
    <v-row class="mb-5">
      <v-col sm="12" lg="4">
        <v-card height="100%" outlined class="rounded-lg">
          <v-card-title>
            <v-row class="mb-2">
              <v-col sm="12" lg="10" class="pb-0">
                <v-icon class="mr-2">mdi-clipboard-plus</v-icon>
                <strong>Case Details</strong>
              </v-col>
              <v-col sm="12" lg="2" class="pb-0 text-right">
                <v-btn v-if="!isEdit" small @click="isEdit = true">
                  <v-icon small>mdi-lock</v-icon>
                </v-btn>
                <v-btn v-if="isEdit" small @click="isEdit = false">
                  <v-icon small>mdi-lock-open</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="12" lg="2" class="pb-0">
                <strong>Invoice #:</strong>
              </v-col>
              <v-col sm="12" lg="10" class="pb-0">
                {{ selectedInvoice.invoice_group_invoice_number }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="2" class="pb-0">
                <strong>Patient:</strong>
              </v-col>
              <v-col sm="12" lg="10" class="pb-0">
                {{ selectedInvoice.patient_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="2">
                <strong>Parts:</strong>
              </v-col>
              <v-col sm="12" lg="10">
                <ul
                  v-for="(related, index) in selectedInvoice.related_mkey_cases"
                  :key="index"
                >
                  <li>{{ related }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row v-if="!isEdit">
              <v-col sm="12" lg="2" class="pb-0">
                <strong>PO Number:</strong>
              </v-col>
              <v-col sm="12" lg="10" class="pb-0">
                {{ selectedInvoice.purchase_order_number }}
              </v-col>
            </v-row>
            <v-row v-if="isEdit" class="mt-5">
              <v-spacer></v-spacer>
              <v-col sm="12" lg="12">
                <v-text-field
                  outlined
                  dense
                  v-model="selectedInvoice.purchase_order_number"
                  label="Purchase Order Number"
                >
                </v-text-field>
                <v-col class="text-right pt-0">
                  <v-btn color="success" @click="updatePurchaseOrder()">
                    <v-icon class="mr-2">mdi-content-save</v-icon>
                    Save
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" lg="4">
        <v-card height="100%" outlined class="rounded-lg">
          <v-card-title>
            <v-row>
              <v-col sm="12" lg="12">
                <v-icon class="mr-2">mdi-truck</v-icon>
                Shipping Details
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="12" lg="3" class="pb-0">
                <strong>Client:</strong>
              </v-col>
              <v-col sm="12" lg="9" class="pb-0">
                {{ selectedInvoice.shipping_client_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3" class="pb-0">
                <strong>Address:</strong>
              </v-col>
              <v-col sm="12" lg="9" class="pb-0">
                {{ selectedInvoice.shipping_address.address_line_1 }}<br />
                <span v-if="selectedInvoice.shipping_address.address_line_2">
                  {{ selectedInvoice.shipping_address.address_line_2 }}<br />
                </span>
                {{ selectedInvoice.shipping_address.suburb }},
                {{ selectedInvoice.shipping_address.state }}
                {{ selectedInvoice.shipping_address.postcode }}<br />
                {{ selectedInvoice.shipping_address.country }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <strong>Courier:</strong>
              </v-col>
              <v-col sm="12" lg="9">
                {{ selectedInvoice.courier }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="12" lg="4">
        <v-card height="100%" outlined class="rounded-lg">
          <v-card-title>
            <v-row>
              <v-col sm="12" lg="12">
                <v-icon class="mr-2">mdi-currency-usd</v-icon>
                Bill To
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="12" lg="3" class="pb-0">
                <strong>Client:</strong>
              </v-col>
              <v-col sm="12" lg="9" class="pb-0">
                {{ selectedInvoice.billing_client_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <strong>Email:</strong>
              </v-col>
              <v-col sm="12" lg="9">
                {{ selectedInvoice.billing_invoice_email }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <strong>Credit Limit:</strong>
              </v-col>
              <v-col sm="12" lg="9">
                $ {{ selectedInvoice.bill_to_credit_limit }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <strong>Current Balance:</strong>
              </v-col>
              <v-col sm="12" lg="9">
                $ {{ selectedInvoice.bill_to_current_balance }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="3">
                <strong>Platform:</strong>
              </v-col>
              <v-col sm="12" lg="9">
                {{ invoicePlatformTag(selectedInvoice.invoice_platform) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card outlined class="rounded-lg">
      <v-card-title>
        <v-row class="mr-2">
          <v-col sm="12" lg="9">
            <v-icon class="mr-2">mdi-table</v-icon>
            Invoice Details
          </v-col>
          <v-col lg="3" class="text-right">
            <v-chip color="warning" v-if="!selectedInvoice.finalised"
              >Not Finalised</v-chip
            >
            <v-chip color="success" v-if="selectedInvoice.finalised"
              >Finalised {{ parseDate(selectedInvoice.invoice_date) }}</v-chip
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="ma-2">
        <v-col>
          <v-simple-table>
            <thead>
              <tr>
                <th>Part ID</th>
                <th>Dentate</th>
                <th>Description</th>
                <th>Quantity</th>
                <th class="text-center" style="width: 175px">
                  Unit Price ({{ selectedInvoice.currency }})
                </th>
                <th class="text-center" style="width: 175px">
                  Amount ({{ selectedInvoice.currency }})
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in lines" :key="index">
                <td>{{ line.case_mkey ? line.case_mkey : line.case_id }}</td>
                <td>
                  <span v-if="line.dentate === 'UPPER'">Upper</span>
                  <span v-if="line.dentate === 'LOWER'">Lower</span>
                  <span v-if="line.dentate === 'BOTH'">Both</span>
                  <span v-if="line.dentate === 'NONE'">None</span>
                </td>
                <td>{{ line.description }}</td>
                <td>{{ line.total_qty }}</td>
                <td>
                  <v-text-field
                    :value="line.unit_price.toFixed(2)"
                    class="right-aligned-input"
                    flat
                    solo
                    hide-details
                    disabled
                    background-color="transparent"
                    prefix="$"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    :value="line.total_amount.toFixed(2)"
                    class="right-aligned-input"
                    flat
                    solo
                    hide-details
                    disabled
                    background-color="transparent"
                    prefix="$"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-right">Freight:</td>
                <td>
                  <v-text-field
                    :value="selectedInvoice.freight.toFixed(2)"
                    class="right-aligned-input"
                    flat
                    solo
                    hide-details
                    disabled
                    background-color="transparent"
                    prefix="$"
                  ></v-text-field>
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td colspan="5" class="text-right">Total:</td>
                <td>
                  <v-text-field
                    :value="totalAmount"
                    class="right-aligned-input"
                    flat
                    solo
                    hide-details
                    disabled
                    background-color="transparent"
                    prefix="$"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-5">
      <v-col sm="12" lg="6">
        <ItemOptions :selected-invoice="selectedInvoice" @updated="updated()" />
      </v-col>
      <v-col sm="12" lg="6" v-if="selectedInvoice.invoice_number">
        <InvoiceFinalise
          :selected-invoice="selectedInvoice"
          :totalAmount="totalAmount"
          @updated="updated()"
        />
      </v-col>
      <v-col sm="12" lg="6">
        <InvoiceErrors :selected-invoice="selectedInvoice" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ItemOptions from "./ItemOptions";
import InvoiceFinalise from "./InvoiceFinalise";
import InvoiceErrors from "./InvoiceErrors";

export default {
  name: "InvoiceDetails",
  props: ["selectedInvoice"],
  components: {
    ItemOptions,
    InvoiceFinalise,
    InvoiceErrors
  },
  data() {
    return {
      isEdit: false
    };
  },
  computed: {
    lines() {
      if (!this.selectedInvoice.lines) {
        return [];
      }
      return [...this.selectedInvoice.lines].sort(
        (a, b) => b.total_amount - a.total_amount
      );
    },
    totalAmount() {
      if (!this.lines) {
        return 0;
      }
      const grossTotal = this.lines.reduce((total, line) => {
        if (!line.total_qty || !line.unit_price) {
          return total;
        }
        return total + line.total_amount;
      }, 0);
      return (grossTotal + this.selectedInvoice.freight).toFixed(2);
    },
    invoiceExists() {
      return this.selectedInvoice;
    }
  },
  methods: {
    parseDate(date) {
      return DateTime.fromSQL(date).toLocaleString(DateTime.DATETIME_MED);
    },
    invoicePlatformTag(platform) {
      return platform === "OEP"
        ? "Osteon"
        : platform === "NEXUS"
        ? "Nexus IOS"
        : "";
    },
    updated() {
      this.$emit("updated");
    },
    updatePurchaseOrder() {
      this.$emit("updatePurchaseOrder", this.selectedInvoice);
      this.isEdit = false;
    }
  }
};
</script>

<style scoped>
.right-aligned-input input {
  text-align: right !important;
}
</style>
