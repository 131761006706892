<template>
  <div v-if="message.attachments.length > 0">
    <ul style="padding-left: 0; list-style-type: none;">
      <li v-for="(attachment, index) in message.attachments" :key="index">
        <ImagePreview
          :image="attachment"
          v-if="isPreviewable(attachment) === 'image'"
          @clicked="viewFile"
        />
        <AudioPlayer
          :audio="attachment"
          v-if="isPreviewable(attachment) === 'audio'"
        />
        <a
          @click="viewFile(attachment)"
          v-if="isPreviewable(attachment) === false"
        >
          <v-icon small>mdi-paperclip</v-icon>
          {{ getFilename(attachment) | sanitizeFilename }}
        </a>
      </li>
    </ul>
    <FileViewModal v-model="file" type="message_attachment" />
  </div>
</template>

<script>
import FileViewModal from "@/collection/components/shared/FileViewModal";
import ImagePreview from "./Attachments/ImagePreview";
import AudioPlayer from "./Attachments/AudioPlayer";

export default {
  name: "Attachments",
  props: ["message"],
  data() {
    return {
      file: null
    };
  },
  components: {
    FileViewModal,
    ImagePreview,
    AudioPlayer
  },
  methods: {
    getFilename(attachment) {
      const result = attachment.file_name || attachment.filename;
      return result;
    },
    viewFile(file) {
      this.file = file;
    },
    isPreviewable(attachment) {
      let fileExt = attachment.filename || attachment.file_name
      fileExt = fileExt
        .toLowerCase()
        .split(".")
        .pop();
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        case "aac":
        case "mp4":
          return "audio";
        default:
          return false;
      }
    }
  }
};
</script>
