<template>
  <div>
    <v-card outlined class="pa-5 mb-5 rounded-lg">
      <v-row>
        <v-col sm="12" lg="6">
          <v-row>
            <v-col sm="12" lg="4" class="py-1">
              <strong>Courier</strong>
            </v-col>
            <v-col sm="12" lg="8" class="py-1">
              {{ dispatch.courier ? dispatch.courier : "None Provided" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="4" class="py-1">
              <strong>Tracking Reference</strong>
            </v-col>
            <v-col sm="12" lg="8" class="py-1">
              {{ dispatch.tracking_reference }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="4" class="py-1">
              <strong>Shipping Address</strong>
            </v-col>
            <v-col sm="12" lg="8" class="py-1">
              {{ dispatch.shipping_address }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="4" class="py-1">
              <strong>Dispatched at</strong>
            </v-col>
            <v-col sm="12" lg="8" class="py-1">
              {{ dispatch.dispatched_at | luxon({ output: "tz_med" }) }}
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="4" class="py-1">
              <strong>Dispatched by</strong>
            </v-col>
            <v-col sm="12" lg="8" class="py-1">
              {{ dispatch.dispatched_by }}
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" lg="6">
          <v-row>
            <v-card
              v-for="(kase, index) in caseComponents"
              :key="index"
              @click="openCase(kase.uid)"
              outlined
              width="45%"
              class="ma-2 rounded-lg"
            >
              <v-container>
                <v-row>
                  <v-col cols="10" class="pb-0">
                    <strong>{{ kase.case_mkey }}</strong>
                    <p>{{ kase.job_type }}</p>
                  </v-col>
                  <v-col cols="2" class="py-auto my-auto">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DispatchesCard",
  props: ["dispatch"],
  computed: {
    ...mapGetters(["collection"]),
    caseComponents() {
      return this.collection.cases.filter(kase => {
        return this.dispatch.case_uids.includes(kase.uid);
      });
    }
  },
  methods: {
    ...mapActions(["setCaseUID", "setCurrentView"]),
    openCase(uid) {
      this.setCaseUID(uid);
      this.setCurrentView("components");
    }
  }
};
</script>
