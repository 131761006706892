<template>
  <div>
    <v-card outlined class="rounded-lg">
      <v-card-title>
        <v-icon class="mr-2">mdi-history</v-icon>
        Case Audit
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-center ma-3" v-if="audit.length === 0">
              There is no history recorded for this case
            </div>
            <v-timeline align-top v-if="audit.length > 0">
              <v-timeline-item
                fill-dot
                icon="mdi-pencil"
                v-for="(change, index) in audit"
                :key="index"
              >
                <v-card dark outlined class="mb-5 rounded-lg" color="#147BD1">
                  <v-card-title class="pb-6" style="font-size: 12pt">
                    {{ change.modified_at | luxonRelative }}
                    <v-spacer />
                    {{ change.changed_values.status }}
                  </v-card-title>
                  <v-card-text class="white text--primary">
                    <v-row v-if="index === audit.length - 1">
                      <v-col sm="12" lg="12">
                        {{ change.modified_at | luxon({ output: "tz_med" }) }},
                        <strong>{{ change.modified_by }}</strong>
                        Created Case
                      </v-col>
                    </v-row>
                    <div v-if="index !== audit.length - 1">
                      <v-row>
                        <v-col sm="12" lg="12">
                          {{
                            change.modified_at | luxon({ output: "tz_med" })
                          }},
                          <strong>{{ change.modified_by }}</strong>
                          changed:
                          <ul v-if="needsModal(change, index)">
                            <li
                              v-for="(attribute,
                              subIndex) in change.changed_attributes"
                              :key="subIndex"
                            >
                              {{ attribute | snakeToTitle }}
                            </li>
                          </ul>
                          <Changes
                            v-if="!needsModal(change, index)"
                            :simple="true"
                            :first-change="change.changed_values"
                            :second-change="audit[index + 1].changed_values"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="needsModal(change, index)">
                        <v-col sm="12" lg="12">
                          <v-btn
                            light
                            small
                            class="rounded-lg"
                            @click="
                              showChangeModal(
                                change.changed_values,
                                audit[index + 1].changed_values
                              )
                            "
                          >
                            Show Details
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="changesModal" max-width="800">
        <v-card class="rounded-lg">
          <v-toolbar dark>
            <v-btn icon dark @click="changesModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Changes</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <Changes
              :first-change="firstChange"
              :second-change="secondChange"
            />
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Changes from "./Changes";

export default {
  name: "CaseAudit",
  props: ["audit"],
  components: {
    Changes
  },
  data() {
    return {
      changesModal: false,
      firstChange: {},
      secondChange: {}
    };
  },
  methods: {
    needsModal(change, index) {
      if (index === this.audit.length - 1) {
        return false;
      }
      if (
        change.changed_attributes.length === 1 &&
        typeof change.changed_values[change.changed_attributes[0]] ===
          "string" &&
        change.changed_values[change.changed_attributes[0]].length < 100
      ) {
        return false;
      }
      return true;
    },
    showChangeModal(a, b) {
      this.firstChange = a;
      this.secondChange = b;
      this.changesModal = true;
    }
  },
  filters: {
    snakeToTitle(str) {
      str = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      return str
        .split("_")
        .map(function(item) {
          return item.charAt(0).toUpperCase() + item.substring(1);
        })
        .join(" ");
    }
  }
};
</script>
