<template>
  <div>
    <div v-if="!aggregate">
      <ComponentChecklist
        v-for="(checklist, index) in sortedChecklists"
        :key="index"
        :checklist="checklist"
        :department="department"
        @item-checked="checkItem"
        @item-unchecked="uncheckItem"
      />
    </div>
    <div v-if="aggregate">
      <AggregateChecklist
        :checklist="aggregateChecklist"
        :department="department"
        @item-checked="checkItem"
        @item-unchecked="uncheckItem"
      />
    </div>
  </div>
</template>

<script>
import ComponentChecklist from "./QCChecklist/ComponentChecklist";
import AggregateChecklist from "./QCChecklist/AggregatedChecklist";

export default {
  name: "QCChecklist",
  props: ["caseUid", "caseUids", "department", "aggregate"],
  data() {
    return {
      checklists: [],
    };
  },
  components: {
    ComponentChecklist,
    AggregateChecklist,
  },
  computed: {
    allCaseUIDs() {
      if (this.caseUid) {
        return [this.caseUid];
      }
      return this.caseUids;
    },
    aggregateChecklist() {
      const uniqueChecklistItems = [
        ...new Set(
          this.checklists.flatMap((c) => c.checklist).map((c) => c.id)
        ),
      ];
      const checklistItemMap = uniqueChecklistItems.map((id) => {
        const item = this.checklists
          .flatMap((c) => c.checklist)
          .find((c) => c.id === id);
        if (item) {
          return {
            ...item,
            cases: this.checklists
              .filter((c) => c.checklist.map((c) => c.id).includes(id))
              .sort((a, b) => a.caseMkey.localeCompare(b.caseMkey))
              .map((c) => {
                const kase = { ...c };
                delete kase.checklist;
                return kase;
              }),
          };
        }
      });
      return checklistItemMap;
    },
    sortedChecklists() {
      return [...this.checklists].sort((a, b) =>
        a.caseMkey.localeCompare(b.caseMkey)
      );
    },
  },
  methods: {
    async fetchChecklists() {
      const requests = [];
      const results = [];
      this.allCaseUIDs.forEach((caseUid) => {
        requests.push(
          this.$axios.get(`api/v2/checklists/${caseUid}/`).then((response) => {
            results.push({
              caseUid: caseUid,
              checklist: response.data.checklist,
              caseMkey: response.data.case_mkey,
              jobType: response.data.job_type,
            });
          })
        );
      });
      Promise.all(requests).then(() => (this.checklists = results));
    },
    async checkItem(checklistItem) {
      this.$axios
        .post(`api/v2/checklists/${checklistItem.caseUid}/`, {
          item_id: checklistItem.id,
          done: checklistItem.done,
          not_required: checklistItem.not_required,
        })
        .finally(() => this.fetchChecklists());
    },
    async uncheckItem(checklistItem) {
      this.$axios
        .delete(
          `api/v2/checklists/${checklistItem.caseUid}/${checklistItem.id}/`
        )
        .finally(() => this.fetchChecklists());
    },
  },
  mounted() {
    this.fetchChecklists();
  },
};
</script>
