<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-information</v-icon>
      Basic Information
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Time Created</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-icon small>mdi-clock</v-icon>
          {{ kase.added | luxon({ output: "tz_med" }) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Group</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-chip
            small
            dark
            :color="groupColor(kase.group_number)"
            v-if="kase.group_number"
            @click="displayGroupEditModal = true"
          >
            {{ kase.group_number }}
          </v-chip>
          <v-btn
            class="rounded-lg"
            x-small
            color="success"
            @click="displayGroupEditModal = true"
            v-if="!kase.group_number"
          >
            <v-icon small>mdi-plus</v-icon>
            Group
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Department</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <v-chip
            small
            color="warning"
            class="rounded-lg"
            label
            v-if="kase.in_design"
          >
            <v-icon class="mr-2">mdi-pencil-ruler</v-icon>
            Design
          </v-chip>
          <v-chip
            small
            color="warning"
            class="rounded-lg"
            label
            v-if="kase.in_production"
          >
            <v-icon class="mr-2">mdi-factory</v-icon>
            Production
          </v-chip>
          <v-chip
            small
            color="warning"
            class="rounded-lg"
            label
            v-if="kase.in_lab"
          >
            <v-icon class="mr-2">mdi-beaker-outline</v-icon>
            Lab
          </v-chip>
          <v-chip
            small
            color="primary"
            class="rounded-lg"
            label
            v-if="kase.ordered"
          >
            <v-icon class="mr-2">mdi-inbox</v-icon>
            Ordered
          </v-chip>
          <v-chip
            small
            color="success"
            class="rounded-lg"
            label
            v-if="kase.dispatched"
          >
            <v-icon class="mr-2">mdi-truck-outline</v-icon>
            Dispatched
          </v-chip>
          <v-chip
            small
            color="error"
            class="rounded-lg"
            label
            v-if="kase.cancelled"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancelled
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Status</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <small
            v-if="!kase.dispatched && !kase.cancelled && !kase.ordered"
            class="mr-2"
          >
            {{ kase.status }}
          </small>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Delegated To</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <span v-if="isDelegated">
            <span v-if="kase.delegated_to_id">
              {{ kase.delegated_to }}
            </span>
            <span v-if="!kase.delegated_to_id">
              Awaiting Delegation
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Shipping Client</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0" v-if="collection.shipping_client">
          <a @click="showClient(collection.shipping_client.id)">{{
            collection.shipping_client.name
          }}</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Patient Reference</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ collection.patient_reference }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Legacy Case ID</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <pre>{{ kase.case_id }}</pre>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Tracking Number</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.tracking_number }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4">
          <strong>Warranty Void</strong>
        </v-col>
        <v-col sm="12" lg="8">
          <v-chip
            label
            dark
            class="rounded-lg"
            color="green"
            v-if="!kase.warranty_void"
            @click="toggleWarranty()"
            >Valid</v-chip
          >
          <v-chip
            label
            dark
            class="rounded-lg"
            color="red"
            v-if="kase.warranty_void"
            @click="toggleWarranty()"
            >Void</v-chip
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="displayGroupEditModal" max-width="1000">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayGroupEditModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Group Edit</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>Group</th>
                <th>Case M-Key</th>
                <th>Job Type</th>
                <th>Status</th>
                <th>Dispatch Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(related_case, index) in collection.cases"
                :key="index"
              >
                <td>
                  <v-checkbox
                    v-if="!related_case.group_number"
                    v-model="related_case.related"
                  />
                  <v-chip
                    dark
                    :color="groupColor(related_case.group_number)"
                    v-if="related_case.group_number"
                    class="my-4"
                    @click="related_case.group_number = null"
                  >
                    {{ related_case.group_number }}
                  </v-chip>
                </td>
                <td>
                  {{ related_case.case_mkey }}
                </td>
                <td>
                  <small>{{ related_case.job_type }}</small>
                </td>
                <td>
                  <small>{{ related_case.status }}</small>
                </td>
                <td>
                  <v-chip outlined small>{{
                    related_case.internal_due | luxon({ output: "tz_med" })
                  }}</v-chip>
                </td>
              </tr>
              <tr v-if="kase.related_cases && kase.related_cases.length === 0">
                <td colspan="5" class="text-center">
                  No Related Cases
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-row class="mt-5">
            <v-col class="text-left">
              <v-btn class="rounded-lg" color="primary" @click="joinCases()">
                <v-icon class="mr-2">mdi-vector-link</v-icon>
                Group
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn
                class="rounded-lg"
                color="success"
                @click="saveGroup()"
                :disabled="submitting"
              >
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import groupColor from "@/shared/group_color";

export default {
  name: "BasicInfo",
  data() {
    return {
      displayGroupEditModal: false,
      groupNumber: 0,
      submitting: false
    };
  },
  watch: {
    displayGroupEditModal(value) {
      if (value) {
        this.startEditing();
      } else {
        this.groupNumber = 0;
        this.triggerReload();
        this.stopEditing();
      }
    }
  },
  computed: {
    ...mapGetters(["collection", "kase", "isLoading"]),
    isDelegated() {
      return this.kase.in_design || this.kase.in_production || this.kase.in_lab;
    }
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "triggerReload",
      "startEditing",
      "stopEditing"
    ]),
    async toggleWarranty() {
      if (!confirm("Are you sure you wish to toggle warranty status?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "toggle_warranty"
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    async saveGroup() {
      this.submitting = true;
      for (const kase of this.collection.cases) {
        await this.saveCase(kase);
      }
      this.displayGroupEditModal = false;
      this.submitting = false;
    },
    async saveCase(kase) {
      return new Promise(resolve => {
        this.$axios
          .post(`/api/v2/case/${kase.case_id}/`, {
            action: "edit_group_number",
            group_number: kase.group_number
          })
          .then(() => {
            resolve();
          });
      });
    },
    groupColor(number) {
      return groupColor(number);
    },
    joinCases() {
      let groupedCases = [];
      this.groupNumber += 1;
      groupedCases = this.collection.cases;
      for (const kase of groupedCases) {
        if (kase.related) {
          kase.group_number = this.groupNumber;
          kase.related = false;
        }
      }
    },
    showClient(clientID) {
      window.open(`/static/v2/client.html?id=${clientID}`, "_blank");
    }
  }
};
</script>
