<template>
  <div>
    <v-alert
      v-if="comparison && customerWarning"
      class="rounded-lg"
      dense
      type="error"
    >
      Customer's due time has changed by
      <strong>{{ customerDelayTime }} business hours</strong>
      - they will need to be contacted.
    </v-alert>
    <v-alert v-if="comparison && !customerWarning" dense type="success">
      Customer's due time has not changed.
    </v-alert>
    <v-row align="center" class="my-0">
      <v-col cols="12" class="text-right py-0">
        <v-chip small outlined label class="rounded-lg">
          Start Time: {{ startTime | luxon({ output: "tz_med" }) }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col :cols="comparison ? 3 : 5">
        <strong>Ratios</strong>
      </v-col>
      <v-col cols="2">
        <strong>Minimum</strong>
      </v-col>
      <v-col cols="2">
        <strong>Allocated</strong>
      </v-col>
      <v-col
        :cols="comparison ? 4 : 2"
        :class="comparison ? 'text-center' : 'text-right'"
      >
        <strong>Due Times</strong>
      </v-col>
    </v-row>
    <v-row v-if="comparison" class="my-0">
      <v-col class="py-0" cols="8"></v-col>
      <v-col class="py-0 text-center" cols="2">
        <small>
          <strong>Original</strong>
        </small>
      </v-col>
      <v-col class="py-0 text-center" cols="2">
        <small>
          <strong>New</strong>
        </small>
      </v-col>
    </v-row>
    <v-row v-if="checkinRatio > 0" class="my-0">
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on">mdi-inbox-arrow-down</v-icon>
          </template>
          Check-in / Metrology
        </v-tooltip>
      </v-col>
      <v-col :cols="comparison ? 3 : 5">
        <v-progress-linear
          :value="checkinRatio"
          buffer-value="0"
          color="light-blue"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="2"> {{ checkinMinimumDuration }} Bus. Hrs. </v-col>
      <v-col cols="2"> {{ checkinDuration }} Bus. Hrs. </v-col>
      <v-col v-if="comparison" cols="2" class="text-right">
        <small>
          {{ comparisonCheckinDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
      <v-col cols="2" class="text-right">
        <small>
          {{ checkinDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
    </v-row>
    <v-row v-if="designRatio > 0" class="my-0">
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on">mdi-pencil-ruler</v-icon>
          </template>
          Design
        </v-tooltip>
      </v-col>
      <v-col :cols="comparison ? 3 : 5">
        <v-progress-linear
          :value="designRatio"
          :buffer-value="designConsumed"
          color="green"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
            <span v-if="designConsumed > value">
              &nbsp; Desired /
              <strong>{{ Math.ceil(designConsumed) }}%</strong>
              Actual
            </span>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="2"> {{ designMinimumDuration }} Bus. Hrs. </v-col>
      <v-col cols="2">
        {{ designDuration }} Bus. Hrs.
        <v-tooltip bottom v-if="designDelayed">
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on" class="mb-1" color="error" small>
              mdi-alert-circle
            </v-icon>
          </template>
          Design Delay
        </v-tooltip>
      </v-col>
      <v-col v-if="comparison" cols="2" class="text-right">
        <small>
          {{ comparisonDesignDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
      <v-col cols="2" class="text-right">
        <small>
          {{ designDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
    </v-row>
    <v-row v-if="machiningRatio > 0" class="my-0">
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on">mdi-factory</v-icon>
          </template>
          Production
        </v-tooltip>
      </v-col>
      <v-col :cols="comparison ? 3 : 5">
        <v-progress-linear
          :value="machiningRatio"
          :buffer-value="machiningConsumed"
          color="amber"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
            <span v-if="machiningConsumed !== value">
              &nbsp; Desired /
              <strong>{{ Math.ceil(machiningConsumed) }}%</strong>
              Actual
            </span>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="2"> {{ machiningMinimumDuration }} Bus. Hrs. </v-col>
      <v-col cols="2">
        {{ machiningDuration }} Bus. Hrs.
        <v-tooltip bottom v-if="machiningDelayed">
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on" class="mb-1" color="error" small>
              mdi-alert-circle
            </v-icon>
          </template>
          Production Delay
        </v-tooltip>
      </v-col>
      <v-col v-if="comparison" cols="2" class="text-right">
        <small>
          {{ comparisonMachiningDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
      <v-col cols="2" class="text-right">
        <small>
          {{ machiningDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
    </v-row>
    <v-row v-if="finishingRatio > 0" class="my-0">
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on">mdi-beaker-outline</v-icon>
          </template>
          Finishing / Dispatch
        </v-tooltip>
      </v-col>
      <v-col :cols="comparison ? 3 : 5">
        <v-progress-linear
          :value="finishingRatio"
          :buffer-value="finishingConsumed"
          color="deep-orange"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
            <span v-if="finishingConsumed !== value">
              &nbsp; Desired /
              <strong>{{ Math.ceil(finishingConsumed) }}%</strong>
              Actual
            </span>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="2"> {{ finishingMinimumDuration }} Bus. Hrs. </v-col>
      <v-col cols="2"> {{ finishingDuration }} Bus. Hrs. </v-col>
      <v-col v-if="comparison" cols="2" class="text-right">
        <small>
          {{ comparisonFinishingDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
      <v-col cols="2" class="text-right">
        <small>
          {{ finishingDue | luxon({ output: "tz_med" }) }}
        </small>
      </v-col>
    </v-row>
    <v-row v-if="customerDelay > 0" class="my-0">
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attr }">
            <v-icon v-bind="attr" v-on="on">mdi-account-clock</v-icon>
          </template>
          Customer Delay
        </v-tooltip>
      </v-col>
      <v-col :cols="comparison ? 3 : 5">
        <v-progress-linear
          :value="customerConsumed"
          :buffer-value="0"
          color="warning"
          height="25"
        >
          <template>
            <strong>{{ customerConsumed }}%</strong>
            &nbsp; ({{ customerDelay }} Bus. Hrs.)
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-divider v-if="comparison" />
    <v-row v-if="comparison" class="my-0">
      <v-col cols="6" />
      <v-col cols="3" class="text-center">
        <strong>Original</strong>
      </v-col>
      <v-col cols="3" class="text-center">
        <strong>New</strong>
      </v-col>
    </v-row>
    <v-row align="center" class="my-0">
      <v-col :cols="comparison ? 6 : 9" class="text-right py-1">
        <strong>Internal Due Time</strong>
      </v-col>
      <v-col :cols="comparison ? 6 : 3" class="text-right py-1">
        <v-chip label color="warning" class="rounded-lg">
          {{ dueTime | luxon({ output: "tz_med" }) }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row align="center" class="my-0">
      <v-col :cols="comparison ? 6 : 9" class="text-right py-1">
        <strong>Buffer Time</strong>
      </v-col>
      <v-col :cols="comparison ? 6 : 3" class="text-right py-1">
        {{ bufferTime }} Bus. Hrs.
      </v-col>
    </v-row>
    <v-row align="center" class="my-0">
      <v-col :cols="comparison ? 6 : 9" class="text-right py-1">
        <strong>Customer Due Time</strong>
      </v-col>
      <v-col v-if="comparison" cols="3" class="text-right py-1">
        <v-chip v-if="comparisonDueTime" label outlined class="mr-2 rounded-lg">
          {{ comparisonDueTime | luxon({ output: "tz_med" }) }}
        </v-chip>
      </v-col>
      <v-col cols="3" class="text-right py-1">
        <v-chip label color="success" class="rounded-lg">
          {{ customerDueTime | luxon({ output: "tz_med" }) }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CaseSchedule",
  props: ["schedule", "comparison"],
  computed: {
    ratios() {
      return this.schedule.ratios || {};
    },
    startTime() {
      return this.schedule.start_time;
    },
    dueTime() {
      return this.schedule.internal_due_time;
    },
    bufferTime() {
      if (this.schedule.buffer_time_seconds > 0) {
        return Math.floor(this.schedule.buffer_time_seconds / 60 / 60);
      }
      return "0";
    },
    customerDueTime() {
      return this.schedule.customer_due_time;
    },
    customerDelay() {
      return (this.schedule.customer_delay / 60 / 60).toFixed(1);
    },
    customerConsumed() {
      return Math.floor((this.customerDelay / this.totalDuration) * 100);
    },
    totalDuration() {
      return (
        this.checkinDuration +
        this.designDuration +
        this.machiningDuration +
        this.finishingDuration
      );
    },
    customerWarning() {
      return this.schedule.buffer_time_seconds < 0;
    },
    customerDelayTime() {
      if (this.schedule.buffer_time_seconds >= 0) {
        return 0;
      }
      return Math.floor(Math.abs(this.schedule.buffer_time_seconds) / 60 / 60);
    },
    comparisonDueTime() {
      if (!this.comparison) {
        return;
      }
      if (this.comparison.internal_due_time === this.dueTime) {
        return;
      }
      return this.comparison.internal_due_time;
    },
    checkinDue() {
      return this.schedule.metrology;
    },
    comparisonCheckinDue() {
      if (!this.comparison) {
        return;
      }
      if (this.comparison.metrology === this.checkinDue) {
        return;
      }
      return this.comparison.metrology;
    },
    checkinRatio() {
      return Math.floor((this.ratios.metrology || 0) * 100);
    },
    checkinDuration() {
      return Math.floor(this.schedule.actual_durations.metrology / 60 / 60);
    },
    checkinMinimumDuration() {
      return Math.floor(this.schedule.minimum_durations.metrology / 60 / 60);
    },
    designDue() {
      return this.schedule.design;
    },
    comparisonDesignDue() {
      if (!this.comparison) {
        return;
      }
      if (this.comparison.design === this.designDue) {
        return;
      }
      return this.comparison.design;
    },
    designRatio() {
      return Math.floor((this.ratios.design || 0) * 100);
    },
    designConsumed() {
      return Math.floor((this.designDuration / this.totalDuration) * 100);
    },
    designDuration() {
      return Math.floor(this.schedule.actual_durations.design / 60 / 60);
    },
    designMinimumDuration() {
      return Math.floor(this.schedule.minimum_durations.design / 60 / 60);
    },
    designDelayed() {
      return !!this.schedule.overrides.design;
    },
    machiningDue() {
      return this.schedule.machining;
    },
    comparisonMachiningDue() {
      if (!this.comparison) {
        return;
      }
      if (this.comparison.machining === this.machiningDue) {
        return;
      }
      return this.comparison.machining;
    },
    machiningRatio() {
      return Math.floor((this.ratios.machining || 0) * 100);
    },
    machiningConsumed() {
      return Math.floor((this.machiningDuration / this.totalDuration) * 100);
    },
    machiningDuration() {
      return Math.floor(this.schedule.actual_durations.machining / 60 / 60);
    },
    machiningMinimumDuration() {
      return Math.floor(this.schedule.minimum_durations.machining / 60 / 60);
    },
    machiningDelayed() {
      return !!this.schedule.overrides.machining;
    },
    finishingDue() {
      return this.schedule.dispatch;
    },
    comparisonFinishingDue() {
      if (!this.comparison) {
        return;
      }
      if (this.comparison.dispatch === this.finishingDue) {
        return;
      }
      return this.comparison.dispatch;
    },
    finishingRatio() {
      return Math.floor((this.ratios.dispatch || 0) * 100);
    },
    finishingConsumed() {
      return Math.floor((this.finishingDuration / this.totalDuration) * 100);
    },
    finishingDuration() {
      return Math.floor(this.schedule.actual_durations.dispatch / 60 / 60);
    },
    finishingMinimumDuration() {
      return Math.floor(this.schedule.minimum_durations.dispatch / 60 / 60);
    }
  }
};
</script>
