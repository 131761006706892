<template>
  <div style="min-height: 80px">
    <audio controls :src="audioURL" style="display: block;" />
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: ["audio"],
  data() {
    return {
      audioURL: null
    };
  },
  methods: {
    fetchFileURI() {
      if (!this.audio) {
        return;
      }
      this.$axios
        .get(`/api/v2/messages/attachments/${this.audio.uid}/`)
        .then(response => {
          this.audioURL = response.data.url;
        });
    }
  },
  mounted() {
    this.fetchFileURI();
  }
};
</script>
