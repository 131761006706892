<template>
  <div>
    <v-app-bar
      dense
      elevation="0"
      class="rounded-t-lg"
      :color="cardColor.color"
      :dark="cardColor.dark"
    >
      <v-toolbar-title>
        <v-chip outlined class="mr-2">
          <strong>{{ component.case_mkey }}</strong>
        </v-chip>
        <v-chip
          small
          dark
          :color="groupColor(component.group_number)"
          v-if="component.group_number"
          class="mr-2"
        >
          {{ component.group_number }}
        </v-chip>
      </v-toolbar-title>
      <v-spacer />
      <span v-if="isImmediate" class="mr-2">
        <v-icon>mdi-bell</v-icon>
        <CountDown v-model="alerting" :due-time="component.immediacy_due" />
      </span>
      <v-chip small outlined v-if="!component.cancelled">
        <v-icon small class="mr-2">mdi-clock</v-icon>
        {{ component.dispatch_due | luxon({ output: "tz_med" }) }}
      </v-chip>
    </v-app-bar>
    <v-card
      tile
      outlined
      class="rounded-b-lg"
      @click="openComponent(component.uid)"
    >
      <v-card-text class="py-0">
        <v-row align="center">
          <v-col cols="2" class="text-center">
            <ToothPicker
              convention="fdi"
              :selected="teeth"
              :labels="false"
              style="max-height: 85px"
            />
          </v-col>
          <v-col cols="10">
            <v-row no-gutters>
              <v-col cols="12">
                <strong>{{ component.job_type }}</strong>
                <v-chip
                  v-if="component.virtual_product"
                  x-small
                  outlined
                  label
                  class="ml-2"
                  >Virtual</v-chip
                >
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <small class="mr-2">
                  {{ component.status }}
                </small>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <small v-if="component.delegated_to" class="mr-2">
                  <v-icon small>mdi-account</v-icon>
                  {{ component.delegated_to }}
                </small>
                <span v-if="hasAlertingMessage">
                  <v-icon color="error">
                    mdi-message
                  </v-icon>
                </span>
              </v-col>
              <v-col class="text-right">
                <v-chip
                  small
                  color="warning"
                  class="rounded-lg"
                  label
                  v-if="component.in_design"
                >
                  <v-icon class="mr-2">mdi-pencil-ruler</v-icon>
                  Design
                </v-chip>
                <v-chip
                  small
                  color="warning"
                  class="rounded-lg"
                  label
                  v-if="component.in_production"
                >
                  <v-icon class="mr-2">mdi-factory</v-icon>
                  Production
                </v-chip>
                <v-chip
                  small
                  color="warning"
                  class="rounded-lg"
                  label
                  v-if="component.in_lab"
                >
                  <v-icon class="mr-2">mdi-beaker-outline</v-icon>
                  Lab
                </v-chip>
                <v-chip
                  small
                  color="primary"
                  class="rounded-lg"
                  label
                  v-if="component.ordered"
                >
                  <v-icon class="mr-2">mdi-inbox</v-icon>
                  Ordered
                </v-chip>
                <v-chip
                  small
                  color="success"
                  class="rounded-lg"
                  label
                  v-if="component.dispatched"
                >
                  <v-icon class="mr-2">mdi-truck-outline</v-icon>
                  Dispatched
                </v-chip>
                <v-chip
                  small
                  color="error"
                  class="rounded-lg"
                  label
                  v-if="component.cancelled"
                >
                  <v-icon class="mr-2">mdi-cancel</v-icon>
                  Cancelled
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ToothPicker from "@/shared/ToothPicker";
import groupColor from "@/shared/group_color";
import CountDown from "@/shared/CountDown";

export default {
  name: "CaseComponentCard",
  props: ["component"],
  components: {
    CountDown,
    ToothPicker
  },
  data() {
    return {
      alerting: false
    };
  },
  watch: {
    isImmediate(value) {
      if (!value) {
        this.alerting = false;
      }
    }
  },
  computed: {
    ...mapGetters(["messages"]),
    cardColor() {
      if (this.alerting) {
        return { color: "orange", dark: true };
      }
      if (this.component.redo) {
        return { color: "red darken-4", dark: true };
      } else {
        return { color: "default", dark: false };
      }
    },
    isImmediate() {
      if (this.component.immediacy_due) {
        return true;
      }
      return false;
    },
    hasAlertingMessage() {
      return (
        this.messages.filter(message => {
          return (
            message.case_uid === this.component.uid &&
            message.message_type === "ALERTING_MESSAGE" &&
            !message.resolved_at
          );
        }).length > 0
      );
    },
    teeth() {
      return this.component.implants.map(implant => implant.tooth);
    }
  },
  methods: {
    ...mapActions(["setCaseUID"]),
    openComponent(caseUID) {
      window.scrollTo(0, 0);
      this.setCaseUID(caseUID);
    },
    groupColor(number) {
      return groupColor(number);
    }
  }
};
</script>
