<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        dark
        color="orange"
        v-bind="attrs"
        v-on="on"
        v-if="binLocation && binLocation !== 'UNALLOCATED'"
      >
        <v-icon>mdi-map-marker-radius</v-icon>
        {{ binLocation }}
      </v-chip>
    </template>
    <span v-if="binLocationBranch"
      >Box Location in {{ binLocationBranch }}
    </span>
    <span v-if="!binLocationBranch">Box Location Branch is not supplied</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "BinLocation",
  props: ["binLocation", "binLocationBranch"]
};
</script>
