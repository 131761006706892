<template>
  <v-card outlined v-if="kase.internal_files" class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-file</v-icon>
      Internal Files
      <v-spacer />
      <v-btn small v-if="!autoUploadTriggered" @click="triggerAutoUpload()">
        <v-icon small class="mr-2 rounded-lg">mdi-cloud-upload</v-icon>
        Auto Upload
      </v-btn>
      <v-btn small v-if="autoUploadTriggered" color="success">
        <v-icon small class="mr-2 rounded-lg">mdi-check</v-icon>
        Auto Upload
      </v-btn>
    </v-card-title>
    <v-card-text v-if="uploading" class="text-center">
      <v-card height="256" flat elevation="0">
        <v-progress-circular size="100" indeterminate />
      </v-card>
    </v-card-text>
    <v-card-text v-if="!uploading">
      <v-card class="overflow-y-auto" height="256" flat elevation="0">
        <v-list dense>
          <v-list-item v-for="file in kase.internal_files" :key="file.id">
            <v-list-item-icon @click="viewFile(file)" style="cursor: pointer">
              <v-icon>mdi-paperclip</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="viewFile(file)"
              style="cursor: pointer"
            >
              {{ file.filename | sanitizeFilename }}
              <small
                ><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="text-center mb-5" v-if="kase.internal_files.length === 0">
          <v-icon large class="ma-5">mdi-sleep</v-icon>
          <br />
          No Internal Files...
        </div>
      </v-card>
      <v-file-input
        class="rounded-lg"
        outlined
        dense
        v-model="uploads"
        label="Upload Internal Files"
        multiple
        @change="triggerUploadFiles()"
      />
    </v-card-text>
    <FileViewModal v-model="file" type="internal_file" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileViewModal from "@/collection/components/shared/FileViewModal";

export default {
  name: "InternalFiles",
  components: {
    FileViewModal
  },
  computed: {
    ...mapGetters(["kase"])
  },
  data() {
    return {
      autoUploadTriggered: false,
      file: null,
      uploads: [],
      uploading: false
    };
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async triggerUploadFiles() {
      this.uploading = true;
      const formData = new FormData();
      formData.append("action", "upload_files");
      formData.append("type", "internal_file");
      this.uploads.forEach(upload => {
        formData.append("files", upload, upload.name);
      });
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.uploading = false;
          this.uploads = [];
          this.triggerReload();
        });
    },
    async triggerAutoUpload() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "auto_internal_upload"
        })
        .then(() => {
          this.autoUploadTriggered = true;
          this.stopLoading();
        });
    },
    viewFile(file) {
      this.file = file;
    }
  }
};
</script>
