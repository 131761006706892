<template>
  <v-container class="fill-height pa-0 ma-0">
    <v-card elevation="0" width="100%">
      <span v-for="(item, index) in timeline" :key="index">
        <Message
          :read-only="readOnly"
          v-if="item.__type === 'message'"
          :message="item"
        />
        <Approval
          :read-only="readOnly"
          v-if="item.__type === 'approval'"
          :approval="item"
        />
      </span>
      <div class="my-5 text-center" v-if="timeline.length === 0">
        <p>
          <v-icon large>mdi-chat-sleep</v-icon>
        </p>
        <p>
          No Messages
        </p>
      </div>
    </v-card>
    <Compose @sent="delayScrollToBottom()" v-if="!readOnly" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Approval from "@/shared/Portal/shared/Approval";
import Message from "@/shared/Portal/Chat/Message";
import Compose from "@/shared/Portal/Chat/Compose";

export default {
  name: "Chat",
  props: ["readOnly"],
  components: {
    Message,
    Approval,
    Compose
  },
  watch: {
    timeline() {
      if (this.totalTimelineItems !== this.timeline.length) {
        this.totalTimelineItems = this.timeline.length;
        this.delayScrollToBottom();
      }
    }
  },
  data() {
    return {
      totalTimelineItems: 0
    };
  },
  computed: {
    ...mapGetters(["collection", "chatHighlightUID", "messages"]),
    timeline() {
      let items = [];
      if (this.collection && this.messages && this.messages.length > 0) {
        items = items.concat(
          this.messages.map(message => {
            return {
              ...message,
              __type: "message"
            };
          })
        );
      }
      if (
        this.collection &&
        this.collection.approvals &&
        this.collection.approvals.length > 0
      ) {
        items = items.concat(
          this.collection.approvals.map(approval => {
            return {
              ...approval,
              __type: "approval"
            };
          })
        );
      }
      items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      return items;
    }
  },
  methods: {
    delayScrollToBottom() {
      setTimeout(this.scrollToTarget, 600);
    },
    scrollToTarget() {
      if (this.chatHighlightUID) {
        const approval = document.getElementById("approvalPointer");
        if (approval) {
          this.$smoothScroll({ scrollTo: approval, offset: -200 });
          return;
        }
      }
      window.scrollTo(0, document.documentElement.scrollHeight);
    }
  },
  mounted() {
    this.totalTimelineItems = this.timeline.length;
    this.delayScrollToBottom();
  }
};
</script>
