<template>
  <v-tooltip bottom v-if="hasLock && !me.staging">
    <template v-slot:activator="{ on, attrs }">
      <v-progress-linear
        indeterminate
        v-bind="attrs"
        v-on="on"
        height="25"
        class="mb-5"
        color="warning"
      >
        <strong>File Operation in Progress</strong>
      </v-progress-linear>
    </template>
    Osteon file servers are currently working on the files for this case.
    <br />
    Please do not perform any file operations until this process is completed.
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FileLockMonitor",
  data() {
    return {
      hasLock: false,
      focused: true,
      refreshIntervalID: null
    };
  },
  computed: {
    ...mapGetters(["collection", "me"])
  },
  methods: {
    focusMonitor() {
      this.focused = document.hasFocus();
      if (!this.focused && this.refreshIntervalID) {
        clearInterval(this.refreshIntervalID);
        this.refreshIntervalID = null;
      }
      if (this.focused && !this.refreshIntervalID) {
        this.fetchLockStatus();
        this.refreshIntervalID = setInterval(this.fetchLockStatus, 2000);
      }
    },
    async fetchLockStatus() {
      const collectionUID = this.collection.uid;
      if (!collectionUID) {
        return;
      }
      this.$axios
        .get(`/api/v2/event/${collectionUID}/?queue=true`)
        .then(response => {
          this.hasLock = response.data.locked;
        });
    }
  },
  mounted() {
    this.fetchLockStatus();
    setInterval(this.focusMonitor, 1000);
  }
};
</script>
