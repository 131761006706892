<template>
  <div>
    <v-card v-if="selectedInvoiceItemOptions" outlined class="rounded-lg">
      <v-card-title>
        <v-row class="mb-2">
          <v-col sm="12" lg="9">
            <v-icon class="mr-2">mdi-format-list-checks</v-icon>
            Options
          </v-col>
          <v-col
            v-if="!finalised && selectedInvoiceItemOptions.item_options"
            sm="12"
            lg="3"
            class="text-right"
          >
            <v-btn class="rounded-lg" v-if="!edit" small @click="edit = true">
              <v-icon small>mdi-lock</v-icon>
            </v-btn>
            <v-btn class="rounded-lg" v-if="edit" small @click="edit = false">
              <v-icon small>mdi-lock-open</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="loading">
          <v-col class="text-center my-5">
            <v-progress-circular indeterminate size="128" color="primary">
              Loading
            </v-progress-circular>
          </v-col>
        </v-row>
        <v-form v-if="!loading" ref="itemOptionsForm" lazy-validation>
          <v-row>
            <v-col>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th style="width: 50px"></th>
                    <th>Item</th>
                    <th style="width: 150px" class="text-center">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,
                    index) in selectedInvoiceItemOptions.item_options"
                    :key="index"
                  >
                    <td>
                      <v-checkbox v-model="item.is_selected" :disabled="!edit">
                      </v-checkbox>
                    </td>
                    <td>{{ item.description }}</td>
                    <td>
                      <v-text-field
                        v-model.number="item.amount"
                        class="right-aligned-input rounded-lg"
                        :disabled="!edit"
                        :rules="[
                          item.amount < 0
                            ? 'Value must be an integer'
                            : item.is_percentage && item.amount > 100
                            ? 'Value must be between 0 and 100'
                            : true
                        ]"
                        :prefix="item.is_percentage ? '%' : '$'"
                        background-color="transparent"
                        type="number"
                        v-if="item.is_selected"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr v-if="!selectedInvoiceItemOptions">
                    <td colspan="3" class="text-center">
                      <p class="mt-5">
                        This case has no options available
                      </p>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="edit">
          <v-col class="text-right">
            <v-btn
              class="rounded-lg"
              color="success"
              @click="updateCaseItems()"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="!selectedInvoiceItemOptions" outlined class="rounded-lg">
      <v-card-title>
        <v-row class="mb-2">
          <v-col sm="12" lg="9">
            <v-icon class="mr-2">mdi-format-list-checks</v-icon>
            Options
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th style="width: 50px"></th>
                  <th>Item</th>
                  <th style="width: 150px" class="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!selectedInvoiceItemOptions">
                  <td colspan="3" class="text-center">
                    <p class="mt-5">
                      This case has no options available
                    </p>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ItemOptions",
  props: ["selectedInvoice"],
  data() {
    return {
      loading: true,
      edit: false,
      itemOptions: []
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.startEditing();
      } else {
        this.stopEditing();
      }
    }
  },
  computed: {
    ...mapGetters(["collection"]),
    finalised() {
      return this.selectedInvoice.finalised;
    },
    kase() {
      return this.collection.cases.find(
        kase => kase.case_id === this.selectedInvoice.case_number
      );
    },
    selectedInvoiceItemOptions() {
      return this.itemOptions.filter(
        itemOption => itemOption.case_id === this.selectedInvoice.case_number
      )[0];
    }
  },
  methods: {
    ...mapActions([
      "startEditing",
      "stopEditing",
      "startLoading",
      "stopLoading",
      "setInvoiceUpdated"
    ]),
    async fetchCaseItemOptions() {
      return new Promise(resolve => {
        if (this.isEditing) {
          return resolve();
        }
        this.$axios
          .get(`/api/v2/case/${this.kase.case_id}/item_options/`)
          .then(response => {
            this.itemOptions = response.data;
            resolve();
          });
      });
    },
    async updateCaseItems() {
      if (!this.$refs.itemOptionsForm.validate()) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.selectedInvoice.case_number}/`, {
          action: "edit_case_items",
          case_item_options: this.selectedInvoiceItemOptions
        })
        .then(() => {
          this.edit = false;
          this.$emit("updated");
          this.stopLoading();
        });
    }
  },
  mounted() {
    this.fetchCaseItemOptions().then(() => {
      this.loading = false;
    });
  }
};
</script>
