<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(kase, index) in aggregateCases" :key="index">
      <v-expansion-panel-header color="secondary">
        <v-row>
          <v-col cols="12" lg="6">
            {{ kase.job_type }}
          </v-col>
          <v-col cols="12" lg="6" :class="!smallScreen ? 'text-right' : ''">
            <Status :kase="kase" class="mr-2" />
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ExpandedDetail :kase="kase" :read-only="readOnly" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import Status from "./StatusChip";
import ExpandedDetail from "./ExpandedDetail";

export default {
  name: "NexusComponents",
  props: ["cases", "readOnly"],
  components: {
    Status,
    ExpandedDetail
  },
  computed: {
    ...mapGetters(["collection"]),
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    productClasses() {
      return [...new Set(this.cases.map(kase => kase.product_class))];
    },
    aggregateCases() {
      const cases = [];
      this.productClasses.forEach(productClass => {
        if (productClass === "Other") {
          cases.push(
            ...this.cases.filter(kase => kase.product_class === productClass)
          );
          return;
        }
        const groupedCases = [...this.cases]
          .filter(kase => kase.product_class === productClass)
          .sort((a, b) => {
            const aApprovalCount = this.collection.approvals.filter(
              approval => {
                return (
                  approval.case_uid === a.uid &&
                  !approval.approved_at &&
                  !approval.cancelled_at
                );
              }
            ).length;
            const bApprovalCount = this.collection.approvals.filter(
              approval => {
                return (
                  approval.case_uid === b.uid &&
                  !approval.approved_at &&
                  !approval.cancelled_at
                );
              }
            ).length;
            if (aApprovalCount === 0 && bApprovalCount === 0) {
              return b.customer_due.localeCompare(a.customer_due);
            }
            return bApprovalCount - aApprovalCount;
          });
        cases.push({
          ...groupedCases[0],
          grouped_case_uids: groupedCases
            .filter(kase => kase.uid !== groupedCases[0].uid)
            .map(kase => kase.uid),
          job_type: productClass
        });
      });
      return cases.sort((a, b) => {
        if (
          a.job_type === "Final Prosthesis" &&
          b.job_type !== "Final Prosthesis"
        ) {
          return 1;
        }
        if (
          a.job_type !== "Final Prosthesis" &&
          b.job_type === "Final Prosthesis"
        ) {
          return -1;
        }
        return 0;
      });
    }
  }
};
</script>
