<template>
  <v-card class="mb-5">
    <v-toolbar color="secondary" dark>
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-information</v-icon>
        Case {{ collection.collection_id }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>
        <v-icon class="mr-2">mdi-hospital</v-icon>
        {{ collection.client_name }}
      </p>
      <p v-if="showAccountNumber && collection.client_account_number">
        <v-icon class="mr-2">mdi-wallet</v-icon>
        {{ collection.client_account_number }}
      </p>
      <p>
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ collection.patient_reference }}
      </p>
      <p class="mb-0">
        <v-btn-toggle v-model="toothConvention">
          <v-btn x-small value="fdi">FDI</v-btn>
          <v-btn x-small value="palmer">Pal.</v-btn>
          <v-btn x-small value="universal">Uni.</v-btn>
        </v-btn-toggle>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  props: ["showAccountNumber"],
  computed: {
    ...mapGetters(["collection", "internationalToothConvention"]),
    toothConvention: {
      get() {
        return this.internationalToothConvention;
      },
      set(value) {
        this.setInternationalToothConvention(value);
      }
    }
  },
  methods: {
    ...mapActions(["setInternationalToothConvention"])
  }
};
</script>
