var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-5 rounded-lg",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Finalise Invoice ")],1),(!_vm.selectedInvoice.finalised)?_c('v-card-text',[_c('p',[_vm._v(" Finalising this invoice will mean no additional ammendments can be made. ")]),_c('p',[_vm._v(" The invoice will be emailed immediately to:"),_c('br'),_c('code',[_vm._v(_vm._s(_vm.selectedInvoice.client_email_address))])]),_c('p',[_vm._v(" By clicking the button below, you're confirming the invoice has been reviewed and is correct. ")]),(
        !(typeof _vm.selectedInvoice.ready_to_finalise === 'undefined' ||
        _vm.selectedInvoice.ready_to_finalise === null
          ? true
          : _vm.selectedInvoice.ready_to_finalise)
      )?_c('p',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-clock-alert")]),_vm._v(" There are pending cases that are awaiting dispatch before this invoice can be finalised. ")],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pb-5 text-right"},[_c('v-btn',{staticClass:"mr-2 rounded-lg",attrs:{"disabled":_vm.disableSplitInvoice},on:{"click":function($event){_vm.splitInvoiceDialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-format-page-split")]),_vm._v(" Split Invoice ")],1),_c('v-btn',{staticClass:"mr-2 rounded-lg",attrs:{"disabled":_vm.processing ||
              !(typeof _vm.selectedInvoice.ready_to_finalise === 'undefined' ||
              _vm.selectedInvoice.ready_to_finalise === null
                ? true
                : _vm.selectedInvoice.ready_to_finalise)},on:{"click":function($event){return _vm.triggerFinalise('manually_invoiced')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_vm._v(" Manually Invoice ")],1),_c('v-btn',{staticClass:"mr-2 rounded-lg",attrs:{"color":"warning","disabled":_vm.processing ||
              !(typeof _vm.selectedInvoice.ready_to_finalise === 'undefined' ||
              _vm.selectedInvoice.ready_to_finalise === null
                ? true
                : _vm.selectedInvoice.ready_to_finalise)},on:{"click":function($event){return _vm.triggerFinalise('finalise_invoice')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alert")]),_vm._v(" Finalise Now ")],1)],1)],1)],1):_vm._e(),(_vm.selectedInvoice.finalised)?_c('v-card-text',[_c('strong',[_vm._v("Invoice has been finalised")])]):_vm._e(),_c('InvoiceSplitModal',{attrs:{"selectedInvoice":_vm.selectedInvoice,"dialog":_vm.splitInvoiceDialog,"totalAmount":_vm.totalAmount},on:{"close":_vm.close,"confirmSplit":_vm.confirmSplit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }