<template>
  <v-sheet>
    <CollectionHeader />
    <v-card flat>
      <v-card-title>
        <v-icon class="mr-2">mdi-message</v-icon>
        Notes &amp; Messages
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row>
          <v-col sm="12" lg="2">
            <MessageFilter v-model="filters" />
          </v-col>
          <v-col sm="12" lg="10">
            <Messages
              :approvals="approvals"
              :messages="filteredMessages"
              :communications="filteredCommunications"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import Messages from "@/collection/components/shared/Messages";
import MessageFilter from "./GlobalMessages/MessageFilter";

export default {
  name: "GlobalMessages",
  components: {
    CollectionHeader,
    MessageFilter,
    Messages
  },
  data() {
    return {
      filters: false
    };
  },
  computed: {
    ...mapGetters(["collection", "messages", "communicationLogs"]),
    approvals() {
      const approvals = this.collection.approvals.filter(
        approval => !approval.cancelled_at
      );
      if (!this.filters) {
        return approvals;
      }
      return approvals.filter(approval => this.filters.includes(approval.uid));
    },
    filteredMessages() {
      if (!this.filters) {
        return this.messages;
      }
      return this.messages.filter(message =>
        this.filters.includes(message.uid)
      );
    },
    filteredCommunications() {
      if (!this.filters) {
        return this.communicationLogs;
      }
      return this.filters;
    }
  }
};
</script>
