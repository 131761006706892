<template>
  <v-navigation-drawer app right clipped class="text-left" width="150">
    <v-list nav rounded>
      <v-list-item-group v-model="currentInvoice" color="default">
        <v-list-item v-for="(item, index) in invoices" :key="index">
          <v-list-item-avatar class="mr-2">
            <v-icon class="grey" dark>
              mdi-receipt
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="
                item.invoice_group_invoice_number
                  ? item.invoice_group_invoice_number
                  : item.invoice_group_main_case_mkey
                  ? item.invoice_group_main_case_mkey
                  : item.case_number
              "
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "InvoiceList",
  props: ["value", "invoices"],
  computed: {
    currentInvoice: {
      get() {
        const currentInvoice = this.invoices.find(
          invoice => invoice.case_number === this.value
        );
        if (!currentInvoice) {
          return 0;
        }
        return this.invoices.indexOf(currentInvoice);
      },
      set(value) {
        if (this.invoices.length >= value) {
          this.$emit("input", this.invoices[value].case_number);
        }
      }
    }
  }
};
</script>
