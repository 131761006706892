<template>
  <div>
    <v-card
      outlined
      tile
      id="messages"
      class="overflow-y-auto overflow-x-hidden rounded-t-lg"
      max-height="60vh"
      min-height="50vh"
    >
      <v-row>
        <v-col
          sm="12"
          class="text-center my-10"
          v-if="timeline && timeline.length === 0"
        >
          <v-icon large class="mb-5">mdi-chat-sleep</v-icon>
          <br />
          No Messages...
        </v-col>
      </v-row>
      <span v-for="(item, index) in timeline" :key="index">
        <Message
          v-if="item.__type === 'message'"
          :collection="collection"
          :message="item"
          :highlight="item.uid === highlightMessageUID"
          @filter="setMessageFilter"
          @updated="triggerReload"
        />
        <Approval
          v-if="item.__type === 'approval'"
          :collection="collection"
          :approval="item"
          @filter="setMessageFilter"
          @updated="triggerReload"
        />
        <Communication
          v-if="item.__type === 'communication'"
          :collection="collection"
          :communications="item"
          :case-link="false"
        />
      </span>
    </v-card>
    <Compose :kase="kase" @sent="delayScrollToBottom()" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Message from "@/shared/Messages/Message";
import Approval from "@/shared/Messages/Approval";
import Communication from "@/shared/Communications/Communication";
import Compose from "./Messages/Compose";
import fetchURLParam from "@/shared/url_params";

export default {
  name: "Messages",
  props: ["messages", "kase", "approvals", "communications"],
  watch: {
    messageFilter() {
      this.delayScrollToBottom();
    }
  },
  data() {
    return {
      highlightMessageUID: null,
    };
  },
  components: {
    Message,
    Approval,
    Compose,
    Communication,
  },
  computed: {
    ...mapGetters(["collection", "messageFilter"]),
    timeline() {
      let items = [];
      if (this.messages && this.messages.length > 0) {
        items = items.concat(
          this.messages.map((message) => {
            return {
              ...message,
              __type: "message",
            };
          })
        );
      }
      if (this.approvals && this.approvals.length > 0) {
        items = items.concat(
          this.approvals.map((approval) => {
            return {
              ...approval,
              __type: "approval",
            };
          })
        );
      }
      if (this.communications && this.communications.length > 0) {
        items = items.concat(
          this.communications.map((communication) => {
            return {
              ...communication,
              __type: "communication",
            };
          })
        );
      }
      items.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      return items;
    },
  },
  methods: {
    ...mapActions(["setMessageFilter", "triggerReload"]),
    delayScrollToBottom() {
      setTimeout(() => {
        const container = this.$el.querySelector("#messages");
        container.scrollTop = container.scrollHeight;
      }, 600);
    },
    scrollToBottom() {
      const container = this.$el.querySelector("#messages");
      container.scrollTop = container.scrollHeight;
    },
  },
  mounted() {
    this.delayScrollToBottom();
    this.highlightMessageUID = fetchURLParam("muid");
  },
};
</script>
