<template>
  <v-col class="pa-1">
    <v-card outlined class="rounded-lg">
      <v-card-subtitle>
        <v-row>
          <v-col sm="2" lg="2" class="py-1">
            <v-icon class="mr-2">mdi-tooth</v-icon>
            <strong>{{ getToothNumber(implant.tooth) }}</strong>
          </v-col>
          <v-col sm="6" lg="6" v-if="!isPontic" class="py-1">
            <strong>{{ implant.platform }}</strong>
            <v-tooltip v-if="!implant.platform_valid" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-1"
                  color="error"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              Implant Platform Not Available
            </v-tooltip>
          </v-col>
          <v-col sm="8" lg="8" v-if="isPontic" class="py-1"
            ><strong>Pontic</strong></v-col
          >
          <v-col v-if="!isPontic" sm="4" lg="4" class="text-right py-1">
            <v-chip
              small
              label
              value="biaxial"
              :color="implant.biaxial ? 'success' : 'default'"
              class="mr-2 mb-2 rounded-lg"
              :input-value="!isEdit"
              :style="!isEdit ? 'cursor: default' : 'cursor: pointer'"
              @click="toggleBiaxial()"
            >
              <v-icon v-if="implant.biaxial" small class="mr-2">
                mdi-check
              </v-icon>
              <v-icon v-if="!implant.biaxial" small class="mr-2">
                mdi-close
              </v-icon>
              Bi-Axial
            </v-chip>
            <v-chip
              small
              label
              value="engaging"
              :color="implant.engaging ? 'success' : 'default'"
              class="mr-2 mb-2 rounded-lg"
              :input-value="!isEdit"
              :style="!isEdit ? 'cursor: default' : 'cursor: pointer'"
              @click="toggleEngaging()"
            >
              <v-icon v-if="implant.engaging" small class="mr-2">
                mdi-check
              </v-icon>
              <v-icon v-if="!implant.engaging" small class="mr-2">
                mdi-close
              </v-icon>
              Engaging
            </v-chip>
          </v-col>
          <v-col sm="2" lg="2" v-if="isPontic && isEdit">
            <v-row>
              <v-spacer />
              <v-card-actions class="mr-4">
                <v-icon text fab @click="editImplant()">
                  mdi-pencil
                </v-icon>
                <v-icon text fab @click="deleteImplant()">
                  mdi-delete
                </v-icon>
              </v-card-actions>
            </v-row>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text v-if="!isPontic">
        <v-row class="mr-1">
          <v-col
            :sm="isEdit ? 10 : 12"
            :lg="isEdit ? 10 : 12"
            class="py-1 pr-2"
          >
            <v-row>
              <v-col>
                <div class="mr-0 d-inline">
                  <v-tooltip v-if="!implant.using_defaults.screw" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-1"
                        color="error"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    Non-default screw selected
                  </v-tooltip>
                  <v-tooltip top v-if="implant.screw">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        small
                        label
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2 rounded-lg"
                      >
                        <v-icon left>
                          mdi-screw-round-top
                        </v-icon>
                        {{ implant.screw.name }}
                      </v-chip>
                    </template>
                    <span>Screw</span>
                  </v-tooltip>
                </div>
                <v-tooltip top v-if="soh">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      label
                      :color="soh.quantity_on_hand <= 0 ? 'error' : ''"
                      :outlined="soh.quantity_on_hand > 0"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                    >
                      <v-icon left>
                        mdi-archive-outline
                      </v-icon>
                      {{ soh.quantity_on_hand }}
                    </v-chip>
                  </template>
                  <span>
                    SOH for {{ branch }}
                    <span v-if="soh.location">
                      - Location <strong>{{ soh.location }}</strong></span
                    >
                  </span>
                </v-tooltip>
                <v-tooltip top v-if="implant.screw_turns">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      outlined
                      label
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                    >
                      <v-icon left>
                        mdi-refresh
                      </v-icon>
                      {{ implant.screw_turns }}
                    </v-chip>
                  </template>
                  <span>Turns</span>
                </v-tooltip>
                <v-tooltip top v-if="implant.measuring_cylinder">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      outlined
                      label
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                    >
                      <v-icon left>
                        mdi-ruler
                      </v-icon>
                      {{ implant.measuring_cylinder }} :
                      {{ implant.measuring_cylinder_height }}
                    </v-chip>
                  </template>
                  <span>Measuring Cylinder : Height</span>
                </v-tooltip>
                <v-tooltip
                  v-if="implant.to_tibase && !implant.using_defaults.tibase"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-1"
                      color="error"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  Non-default ti-base
                </v-tooltip>
                <v-tooltip top v-if="implant.to_tibase">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      label
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                    >
                      <v-icon left>
                        mdi-screw-lag
                      </v-icon>
                      {{ implant.tibase.name }}
                    </v-chip>
                  </template>
                  <span>Ti-Base</span>
                </v-tooltip>
              </v-col>
              <v-col class="text-right px-0" cols="auto">
                <v-tooltip top v-if="implant.library_id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      outlined
                      label
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2 rounded-lg"
                    >
                      <v-icon left>
                        mdi-bookmark-box-multiple
                      </v-icon>
                      <pre>{{ implant.library_id }}</pre>
                    </v-chip>
                  </template>
                  <span>Library ID</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col class="py-1" v-if="isEdit">
            <v-row>
              <v-spacer />
              <v-card-actions>
                <v-icon text fab @click="editImplant()">
                  mdi-pencil
                </v-icon>
                <v-icon text fab @click="deleteImplant()">
                  mdi-delete
                </v-icon>
              </v-card-actions>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import internationalToothNumbers from "@/shared/international_tooth_numbers";

export default {
  name: "ImplantCard",
  props: [
    "implant",
    "isPontic",
    "isEdit",
    "internationalToothConvention",
    "branch"
  ],
  data() {
    return {
      selectedOptions: null,
      soh: null
    };
  },
  methods: {
    deleteImplant() {
      this.$emit("deleteImplant", this.implant);
    },
    editImplant() {
      this.$emit("editImplant", this.implant);
    },
    toggleEngaging() {
      this.$emit("toggleEngaging", this.implant);
    },
    toggleBiaxial() {
      this.$emit("toggleBiaxial", this.implant);
    },
    getToothNumber(toothNumber) {
      const convention = this.internationalToothConvention
        ? this.internationalToothConvention
        : "fdi";
      return internationalToothNumbers[toothNumber][convention].padStart(
        2,
        "0"
      );
    },
    async getScrewSOH() {
      if (!this.branch || !this.implant || !this.implant.screw) {
        this.soh = null;
        return;
      }
      this.$axios
        .get(
          `/api/v2/components/${this.branch}/${this.implant.screw.code}/soh/`
        )
        .then(response => {
          this.soh = response.data;
        })
        .catch(() => {
          this.soh = null;
        });
    }
  },
  mounted() {
    this.getScrewSOH();
  }
};
</script>
