<template>
  <v-card
    v-if="selectedInvoice && selectedInvoice.errors.length > 0"
    outlined
    class="rounded-lg"
  >
    <v-card-title>
      <v-icon class="mr-2">mdi-alert</v-icon>
      Invoicing Issues
    </v-card-title>
    <v-card-text>
      <ul>
        <li v-for="(error, index) in selectedInvoice.errors" :key="index">
          <strong
            >{{ error.case_mkey ? error.case_mkey : error.case_id }} -
            {{ error.bar_style_name }}:</strong
          >
          {{ error.error }}
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "InvoiceErrors",
  props: ["selectedInvoice"]
};
</script>
