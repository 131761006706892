<template>
  <v-row>
    <v-col sm="12">
      <v-card outlined class="rounded-lg">
        <v-card-title>
          <v-icon class="mr-2">mdi-message</v-icon>
          Notes &amp; Messages
        </v-card-title>
        <v-card-text>
          <v-row v-if="filter">
            <v-col>
              <v-alert v-model="filterAlert" dismissible dense type="info">
                Showing only messages for approval:
                <i>{{ this.messageFilter.approvalFilter[0] }}</i>
              </v-alert>
            </v-col>
          </v-row>
          <Messages
            :messages="filteredMessages"
            :approvals="approvals"
            :kase="kase"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Messages from "@/collection/components/shared/Messages";

export default {
  name: "Notes",
  components: {
    Messages
  },
  computed: {
    ...mapGetters(["collection", "kase", "messages", "messageFilter"]),
    filterAlert: {
      get() {
        return !!this.filter;
      },
      set(value) {
        if (!value) {
          this.setMessageFilter({});
        }
      }
    },
    filter() {
      if (!this.messageFilter.approvalFilter) {
        return false;
      }
      const approvals = this.collection.approvals.filter(approval => {
        return (
          this.kase.uid === approval.case_uid &&
          this.messageFilter.approvalFilter[0] ===
            `${this.toTitleCase(approval.approval_type)} - ${
              this.kase.job_type
            }`
        );
      });
      if (approvals.length === 0) {
        return false;
      }
      const filter = approvals.map(approval => approval.uid);
      return filter.concat(
        this.messages
          .filter(message => {
            return approvals
              .map(approval => approval.uid)
              .includes(message.approval_uid);
          })
          .map(message => message.uid)
      );
    },
    approvals() {
      if (!this.filter) {
        return this.collection.approvals.filter(approval => {
          return approval.case_uid === this.kase.uid && !approval.cancelled_at;
        });
      }
      return this.collection.approvals.filter(approval =>
        this.filter.includes(approval.uid)
      );
    },
    filteredMessages() {
      if (this.filter) {
        return this.messages.filter(message =>
          this.filter.includes(message.uid)
        );
      }
      return this.messages.filter(message => {
        return (
          message.case_uid === this.kase.uid ||
          (message.client_uid === this.collection.shipping_client.uid &&
            (message.message_type === "CLIENT_NOTE" ||
              message.message_type === "CLIENT_MESSAGE" ||
              message.message_type === "CUSTOMER_SCAN_ADVICE")) ||
          (message.case_collection_uid === this.collection.uid &&
            message.message_type === "CUSTOMER_SERVICE_NOTE")
        );
      });
    }
  },
  methods: {
    ...mapActions(["setMessageFilter"]),
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    }
  }
};
</script>
