<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-image</v-icon>
      Capture Image
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="text-center">
          <v-btn class="rounded-lg" @click="toggleCamera()">
            <span v-if="!isCameraOpen">
              <v-icon class="mr-2">mdi-play</v-icon>
              Start Camera
            </span>
            <span v-if="isCameraOpen">
              <v-icon class="mr-2">mdi-stop</v-icon>
              Stop Camera
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="isCameraOpen && isLoading">
        <v-col class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
      <v-row v-if="isCameraOpen" v-show="!isLoading">
        <v-col class="text-center">
          <v-flex>
            <video
              v-show="!isPhotoTaken"
              ref="camera"
              width="1920"
              height="1440"
              autoplay
              style="width: 100%; height: auto"
            ></video>
            <canvas
              v-show="isPhotoTaken"
              id="photoTaken"
              ref="canvas"
              width="1920"
              height="1440"
              style="width: 100%; height: auto"
            ></canvas>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-5">
      <v-row v-if="isCameraOpen && !isLoading">
        <v-col class="text-center">
          <v-btn class="rounded-lg" @click="takePhoto()" fab color="error">
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-center" v-if="isPhotoTaken">
          <v-btn
            class="rounded-lg"
            @click="downloadImage()"
            fab
            color="success"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CameraCapture",
  props: ["caseId", "name"],
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isLoading: false
    };
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;
      const constraints = (window.constraints = {
        audio: false,
        video: true
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    stopCameraStream() {
      const tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 1920, 1440);
    },
    downloadImage() {
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");
      this.isLoading = true;
      this.$axios
        .post(`/api/v2/case/${this.caseId}/camera_upload/`, {
          name: this.name,
          image: canvas
        })
        .then(() => {
          this.isPhotoTaken = false;
          this.isLoading = false;
        });
    }
  }
};
</script>
