<template>
  <div class="text-center" style="min-width: 70px">
    <v-progress-circular indeterminate v-if="loading" color="white" />
    <v-btn
      class="rounded-lg"
      v-if="!loading && !hasErrors"
      @click="triggerRefresh"
      outlined
      dark
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-tooltip bottom v-if="!loading && hasErrors">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="triggerRefresh"
          outlined
          color="error"
          v-bind="attrs"
          v-on="on"
          dark
        >
          <v-icon>mdi-alert</v-icon>
        </v-btn>
      </template>
      <span v-for="(error, index) in errors" :key="index"
        ><div>{{ error }}</div></span
      >
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["loading", "errors"],
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0;
    }
  },
  methods: {
    triggerRefresh() {
      this.$emit("refresh", true);
    }
  }
};
</script>
