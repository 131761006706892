<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-history</v-icon>
      Progress
    </v-card-title>
    <v-card-text>
      <v-timeline align-top dense>
        <v-timeline-item
          small
          fill-dot
          dense
          right
          v-for="(item, index) in timeline"
          :key="index"
          :icon="item.icon"
          :color="item.completed ? 'green' : 'primary'"
        >
          <h4>{{ item.name }}</h4>
          {{ item.date | luxon({ output: "tz_med" }) }}
          <div v-if="item.dateCompleted">
            <small>
              <v-icon small>mdi-check</v-icon>
              {{ item.dateCompleted | luxon({ output: "tz_med" }) }}
            </small>
            <span v-if="item.completedBy">
              <v-chip x-small label outlined class="ml-2">
                {{ item.completedBy }}
              </v-chip>
            </span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "Progress",
  computed: {
    ...mapGetters(["kase"]),
    caseStep() {
      if (this.kase.cancelled) {
        return 0;
      } else if (this.kase.ordered) {
        return 1;
      } else if (this.kase.in_design) {
        return 2;
      } else if (this.kase.in_production) {
        return 3;
      } else if (this.kase.in_lab) {
        return 4;
      } else if (this.kase.dispatched) {
        return 5;
      }
      return 0;
    },
    timeline() {
      const timelineItems = [];
      timelineItems.push({
        name: "Case Received",
        date: this.kase.date_received,
        dateCompleted: this.kase.date_received,
        completedBy:
          this.kase.check_in_user ||
          (this.kase.shipping_client && this.kase.shipping_client.name),
        icon: "mdi-truck-check",
        completed: this.kase.date_received || this.caseStep > 0
      });
      timelineItems.push({
        name: "Check-in / Metrology",
        date: this.kase.metrology_due,
        dateCompleted: this.kase.date_metrology_complete,
        completedBy: this.kase.metrology_user,
        icon: "mdi-ruler",
        completed: this.kase.date_metrology_complete || this.caseStep > 1
      });
      timelineItems.push({
        name: "Design / Approval",
        date: this.kase.design_due,
        dateCompleted: this.kase.date_design_complete,
        completedBy: this.kase.design_user,
        icon: "mdi-pencil-ruler",
        completed: this.kase.date_design_complete || this.caseStep > 2
      });
      timelineItems.push({
        name: "Client Approval",
        date: this.kase.design_due,
        dateCompleted: this.kase.date_approval_complete,
        completedBy:
          this.kase.shipping_client && this.kase.shipping_client.name,
        icon: "mdi-account-check",
        completed: this.kase.date_approval_complete || this.caseStep > 2
      });
      timelineItems.push({
        name: "Production / Lab",
        date: this.kase.machining_due,
        dateCompleted: this.kase.date_machining_complete,
        completedBy: this.kase.machine_user,
        icon: "mdi-hammer-wrench",
        completed: this.kase.date_machining_complete || this.caseStep > 4
      });
      timelineItems.push({
        name: "Assembly / Dispatch",
        date: this.kase.dispatch_due,
        dateCompleted: this.kase.date_dispatched,
        icon: "mdi-package-variant-closed",
        completed: this.kase.date_dispatched || this.caseStep === 5
      });
      timelineItems.push({
        name: "Date Promised",
        date: DateTime.fromISO(this.kase.date_promised).toISO(),
        icon: "mdi-reminder",
        completed: false
      });
      if (this.kase.approval_email_times) {
        this.kase.approval_email_times.forEach(time => {
          timelineItems.push({
            name: "Approval Email Sent",
            date: time,
            dateCompleted: time,
            icon: "mdi-email",
            completed: true
          });
        });
      }
      return timelineItems
        .filter(item => item.date)
        .sort((a, b) => {
          return a.date.localeCompare(b.date);
        });
    }
  }
};
</script>
