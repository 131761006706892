<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-toolbar-title v-if="$refs.calendar">
          {{ focus | luxon }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-btn small color="primary" class="ml-4 rounded-lg" @click="setToday">
          Today
        </v-btn>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="400">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :events="machineEvents"
        type="week"
        interval-height="20"
        color="primary"
        :interval-style="intervalStyle"
        @mousemove:time="moveStartTime"
        @mousedown:event="setStartTime"
      >
        <template v-slot:day-body="{ date }">
          <div
            class="v-current-time"
            v-if="currentDate(date) === new Date().getDate()"
            :class="{ first: date }"
            :style="{ top: nowY }"
          />
        </template>
        <template v-slot:event="data">
          <div v-if="data.event._type === 'project'">
            <h3 class="mt-1 ml-2">
              <v-icon dark small>mdi-select-group</v-icon>
              {{ data.event.name }}
            </h3>
            <span v-if="data.event.createdBy">
              <v-icon dark small class="ml-2">mdi-account</v-icon>
              {{ data.event.createdBy }}<br />
            </span>
            <v-icon dark small class="ml-2">mdi-inbox</v-icon>
            {{ data.event.cases.length }} Cases<br />
            <span v-if="data.event.status">
              <v-chip
                x-small
                v-if="data.event.status === 'Pending'"
                class="ma-2"
                >Programming</v-chip
              >
              <v-chip x-small v-if="data.event.status === 'Ready'" class="ma-2"
                >Make Ready</v-chip
              >
            </span>
          </div>
          <div v-if="data.event._type === 'scanGaugeBatch'">
            <h3 class="mt-1 ml-2">
              <v-icon dark small>mdi-package-variant-closed</v-icon>
              {{ data.event.name }}
            </h3>
            <span v-if="data.event.createdBy">
              <v-icon dark small class="ml-2">mdi-account</v-icon>
              {{ data.event.createdBy }}<br />
            </span>
            <div class="ml-2" style="white-space: pre-wrap !important;">
              ({{ data.event.referenceCode }})
              {{ data.event.implantName }}
            </div>
          </div>
          <div
            v-if="data.event._type === 'plan'"
            :style="`color: ${data.event.textColor} !important`"
          >
            <div v-for="iteration in 100" :key="iteration" class="ma-5">
              <h3 class="mt-1 ml-2">{{ data.event.name }}</h3>
            </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "MachineCalendar",
  props: [
    "value",
    "project",
    "scanGaugeBatch",
    "machine",
    "duration",
    "projects",
    "scanGaugeBatches",
    "plans",
    "employees"
  ],
  data() {
    return {
      startTime: null,
      focus: null,
      ready: false,
      moving: true
    };
  },
  computed: {
    machineEvents() {
      const events = this.projects
        .filter(project => {
          if (this.project) {
            return (
              project.machine_id === this.machine.id &&
              project.id !== this.project.id
            );
          } else {
            return project.machine_id === this.machine.id;
          }
        })
        .map(project => {
          const employee = this.employees.find(
            employee => employee.username === project.created_by
          );
          let color = "warning";
          switch (project.status) {
            case "Pending":
              color = "warning";
              break;
            case "Ready":
              color = "success";
              break;
            case "Complete":
              color = "grey";
              break;
          }
          return {
            _type: "project",
            name: project.name,
            start: new Date(project.start_time),
            end: new Date(project.end_time),
            color: color,
            timed: true,
            cases: project.cases,
            createdBy: employee
              ? `${employee.first_name} ${employee.last_name}`
              : project.created_by,
            status: project.status,
            projectID: project.id
          };
        });
      if (!this.startTime) {
        return events;
      }
      if (this.project) {
        events.push({
          _type: "project",
          _movable: true,
          name: this.project.name,
          start: this.startTime,
          end: new Date(this.startTime.getTime() + this.duration * 60000),
          color: "primary",
          timed: true,
          cases: this.project.cases,
          projectID: this.project.id
        });
      }
      if (this.scanGaugeBatch) {
        const employee = this.employees.find(
          employee => employee.username === this.scanGaugeBatch.created_by
        );
        const startTime = new Date(this.startTime);
        events.push({
          _type: "scanGaugeBatch",
          _movable: true,
          name: this.scanGaugeBatch.batch_key,
          start: startTime,
          end: new Date(startTime).setTime(
            startTime.getTime() + this.duration * 60 * 60 * 1000
          ),
          color: "primary",
          timed: true,
          createdBy: employee
            ? `${employee.first_name} ${employee.last_name}`
            : this.scanGaugeBatch.created_by,
          referenceCode: this.scanGaugeBatch.implant_reference_code,
          implantName: this.scanGaugeBatch.implant_name
        });
      }
      this.scanGaugeBatches
        .filter(batch => {
          return (
            !!batch.started_at &&
            !!batch.duration &&
            !!batch.machine &&
            batch.machine === this.machine.id
          );
        })
        .forEach(batch => {
          const startTime = new Date(batch.started_at);
          events.push({
            _type: "scanGaugeBatch",
            name: batch.batch_key,
            start: startTime,
            end: new Date(startTime).setTime(
              startTime.getTime() + batch.duration * 60 * 60 * 1000
            ),
            color: "grey",
            timed: true,
            createdBy: batch.created_by,
            referenceCode: batch.implant_reference_code,
            implantName: batch.implant_name
          });
        });
      this.plans
        .filter(plan => plan.machine_id === this.machine.id)
        .forEach(plan => {
          events.push({
            _type: "plan",
            planID: plan.id,
            name: plan.name,
            start: new Date(plan.start_time),
            end: new Date(plan.end_time),
            color: "white",
            textColor: plan.color,
            timed: true
          });
        });
      return events;
    },
    today() {
      return new Date().toISOString().substring(0, 10);
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    }
  },
  methods: {
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    moveStartTime(event) {
      if (!this.moving) {
        return;
      }
      this.startTime = new Date(
        this.roundTime(new Date(`${event.date} ${event.time}`))
      );
    },
    setStartTime(clickedEvent) {
      if (!clickedEvent.event._movable) {
        return;
      }
      if (this.moving) {
        this.$emit("input", this.startTime);
      }
      this.moving = !this.moving;
    },
    roundTime(time, down = true) {
      const roundTo = 5; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    intervalStyle(interval) {
      if (interval.day !== new Date().getDate()) {
        return null;
      }
      return { backgroundColor: "rgba(25, 118, 210, 0.3)" };
    },
    currentDate(date) {
      return DateTime.fromSQL(date).day;
    }
  },
  mounted() {
    if (this.value) {
      this.moving = false;
      this.startTime = new Date(this.value);
    }
    this.focus = this.today;
    this.ready = true;
    setTimeout(() => {
      this.$refs.calendar.scrollToTime({
        hour: new Date().getHours(),
        minute: 0
      });
    }, 100);
  }
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
