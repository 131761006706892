<template>
  <v-row justify="center">
    <v-dialog persistent v-model="dialog" max-width="500">
      <v-card class="rounded-lg">
        <v-card-title class="text-h5 justify-center warning">
          <v-icon dark class="mr-2">mdi-alert</v-icon>
          <h4 class="white--text">{{ title }}</h4>
          <v-icon dark class="ml-2">mdi-alert</v-icon>
        </v-card-title>
        <v-card-text class="text-h6">
          <span style="white-space: pre-wrap">{{ message }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="confirm(false)" class="mr-2 rounded-lg">
            <v-icon class="mr-2">mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn color="success" @click="confirm(true)" class="rounded-lg">
            <v-icon class="mr-2">mdi-check</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: ["value", "title", "message"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    confirm(value) {
      this.dialog = false;
      this.$emit("confirmation", value);
    }
  }
};
</script>
