<template>
  <v-row v-if="collection">
    <v-col
      cols="12"
      lg="6"
      v-for="(form, index) in collection.order_forms"
      :key="index"
    >
      <Form :form="form" class="mb-5" />
    </v-col>
    <v-col
      cols="12"
      v-if="collection.order_forms.length === 0"
      class="text-center"
    >
      <v-card elevation="0">
        <div class="pa-5">
          <p>
            <v-icon large>mdi-file-question</v-icon>
          </p>
          <p>
            No Order Forms Received
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./OrderForms/Form";

export default {
  name: "OrderForms",
  components: {
    Form
  },
  computed: {
    ...mapGetters(["collection"])
  }
};
</script>
