<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-clock</v-icon>
      Department Due Times
    </v-card-title>
    <v-card-text>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          v-if="loading"
          indeterminate
          size="256"
          color="primary"
          class="mb-5"
        >
          Loading
        </v-progress-circular>
      </div>
      <CaseSchedule :schedule="schedule" v-if="!loading" />
      <v-row class="mt-5" v-if="kase.active">
        <v-col sm="12" class="text-right">
          <v-btn class="rounded-lg" @click="changeTimes()">
            <v-icon class="mr-2">mdi-wrench-clock</v-icon>
            Modify Times
          </v-btn>
        </v-col>
      </v-row>
      <ChangeTimesModal
        v-if="kase.active"
        v-model="changeModal"
        @changed="fetchCaseSchedule()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CaseSchedule from "@/shared/CaseSchedule";
import ChangeTimesModal from "./ChangeTimesModal";

export default {
  name: "Schedule",
  data() {
    return {
      loading: true,
      schedule: null,
      changeModal: false
    };
  },
  watch: {
    kase(value) {
      if (!value) {
        return;
      }
      this.fetchCaseSchedule();
    }
  },
  components: {
    CaseSchedule,
    ChangeTimesModal
  },
  computed: {
    ...mapGetters(["kase"])
  },
  methods: {
    async fetchCaseSchedule() {
      if (!this.kase) {
        return;
      }
      if (!this.kase.case_id) {
        return;
      }
      this.$axios
        .get(`/api/v2/case/${this.kase.case_id}/schedule/`)
        .then(response => {
          this.schedule = response.data;
          this.loading = false;
        });
    },
    changeTimes() {
      this.changeModal = true;
    }
  },
  mounted() {
    this.fetchCaseSchedule();
  }
};
</script>
