<template>
  <v-card outlined class="pb-5 rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-check</v-icon>
      Finalise Invoice
    </v-card-title>
    <v-card-text v-if="!selectedInvoice.finalised">
      <p>
        Finalising this invoice will mean no additional ammendments can be made.
      </p>
      <p>
        The invoice will be emailed immediately to:<br />
        <code>{{ selectedInvoice.client_email_address }}</code>
      </p>
      <p>
        By clicking the button below, you're confirming the invoice has been
        reviewed and is correct.
      </p>
      <p
        v-if="
          !(typeof selectedInvoice.ready_to_finalise === 'undefined' ||
          selectedInvoice.ready_to_finalise === null
            ? true
            : selectedInvoice.ready_to_finalise)
        "
      >
        <v-icon class="mr-2">mdi-clock-alert</v-icon>
        There are pending cases that are awaiting dispatch before this invoice
        can be finalised.
      </p>
      <v-row>
        <v-col class="pb-5 text-right">
          <v-btn
            class="mr-2 rounded-lg"
            :disabled="disableSplitInvoice"
            @click="splitInvoiceDialog = true"
          >
            <v-icon class="mr-2">mdi-format-page-split</v-icon>
            Split Invoice
          </v-btn>
          <v-btn
            class="mr-2 rounded-lg"
            :disabled="
              processing ||
                !(typeof selectedInvoice.ready_to_finalise === 'undefined' ||
                selectedInvoice.ready_to_finalise === null
                  ? true
                  : selectedInvoice.ready_to_finalise)
            "
            @click="triggerFinalise('manually_invoiced')"
          >
            <v-icon class="mr-2">mdi-pencil</v-icon>
            Manually Invoice
          </v-btn>
          <v-btn
            class="mr-2 rounded-lg"
            color="warning"
            :disabled="
              processing ||
                !(typeof selectedInvoice.ready_to_finalise === 'undefined' ||
                selectedInvoice.ready_to_finalise === null
                  ? true
                  : selectedInvoice.ready_to_finalise)
            "
            @click="triggerFinalise('finalise_invoice')"
          >
            <v-icon class="mr-2">mdi-alert</v-icon>
            Finalise Now
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="selectedInvoice.finalised">
      <strong>Invoice has been finalised</strong>
    </v-card-text>
    <InvoiceSplitModal
      :selectedInvoice="selectedInvoice"
      :dialog="splitInvoiceDialog"
      :totalAmount="totalAmount"
      @close="close"
      @confirmSplit="confirmSplit"
    ></InvoiceSplitModal>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import InvoiceSplitModal from "./InvoiceSplitModal";

export default {
  name: "InvoiceFinalise",
  props: ["selectedInvoice", "totalAmount"],
  components: {
    InvoiceSplitModal
  },
  data() {
    return {
      processing: false,
      splitInvoiceDialog: false
    };
  },
  computed: {
    disableSplitInvoice() {
      const splitAllowed = this.selectedInvoice.split_invoice_allowed
        ? this.selectedInvoice.split_invoice_allowed
        : false;
      if (splitAllowed) {
        return (
          (this.processing ||
            (!this.selectedInvoice.ready_to_finalise ||
            this.selectedInvoice.ready_to_finalise === null
              ? true
              : this.selectedInvoice.ready_to_finalise)) &&
          this.selectedInvoice.related_case_details.length <= 1
        );
      }
      return true;
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    close(value) {
      this.splitInvoiceDialog = value;
    },
    confirmSplit(value) {
      this.splitInvoiceDialog = false;
      this.triggerSplitInvoice(value);
    },
    triggerFinalise(triggerAction) {
      let message =
        "Are you sure you want to finalise this invoice? THIS CANNOT BE REVERSED";
      message =
        triggerAction === "manually_invoiced"
          ? "This process will need to be invoiced manually. THIS CANNOT BE REVERSED. Do you want to continue?"
          : message;
      if (!confirm(message)) {
        return;
      }
      this.processing = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.selectedInvoice.case_number}/`, {
          action: triggerAction,
          invoice_number: this.selectedInvoice.invoice_number
        })
        .then(() => {
          this.$emit("updated");
          this.stopLoading();
          this.processing = false;
        })
        .catch(error => {
          this.$emit("updated");
          this.stopLoading();
          const errorMessage = error.response.data
            ? error.response.data +
              "\nInvoice could not be finalised, please try again"
            : "Invoice could not be finalised, please try again";
          alert(errorMessage);
          this.processing = false;
        });
    },
    triggerSplitInvoice(value) {
      this.processing = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.selectedInvoice.case_number}/`, {
          action: "split_invoice",
          split_invoice: value
        })
        .then(() => {
          this.$emit("updated");
          this.stopLoading();
          this.processing = false;
        })
        .catch(() => {
          this.$emit("updated");
          this.stopLoading();
          alert("Invoice could not be updated, please try again.");
          this.processing = false;
        });
    }
  }
};
</script>
