<template>
  <v-container>
    <v-card min-height="80vh" width="100%" color="transparent" outlined>
      <Header :show-account-number="showAccountNumber" />
      <ActionRequired v-if="openApprovals.length > 0" />
      <Cancelled v-if="!collection.cases" />
      <v-row>
        <v-col
          v-for="(dentate, index) in dentates"
          :key="index"
          lg="6"
          col="12"
        >
          <Detail :dentate="dentate" :read-only="readOnly" />
        </v-col>
      </v-row>
      <v-row v-if="collection.cases">
        <v-col cols="12" class="text-center">
          <small>
            * Finished prostheses will only continue to production after the
            final
            <strong>Try-In Approval</strong> or final
            <strong>Design Approval</strong> - dispatch times are only valid
            once <strong>Try-In Approval</strong> or final
            <strong>Design Approval</strong> is completed.
          </small>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./CaseOverview/Header";
import ActionRequired from "./CaseOverview/ActionRequired";
import Cancelled from "./CaseOverview/Cancelled";
import Detail from "./CaseOverview/Detail";

export default {
  name: "CaseOverview",
  props: ["readOnly", "showAccountNumber"],
  components: {
    Header,
    ActionRequired,
    Cancelled,
    Detail
  },
  computed: {
    ...mapGetters(["dentates", "openApprovals", "collection"])
  }
};
</script>
