<template>
  <v-row justify="center">
    <v-dialog v-model="displayCompleteModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayCompleteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mark as Done</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-alert dark color="warning">
                <p>
                  This component is a
                  <v-chip light small label>Virtual</v-chip> product. If you
                  mark this as done, the component status will be set to
                  <v-chip light small label>Dispatched</v-chip> and an invoice
                  raised for the component.
                </p>
                <p>
                  <v-icon class="mr-2">mdi-alert</v-icon>
                  <strong
                    >This cannot be reverted if you make a mistake.</strong
                  >
                </p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="displayCompleteModal">
            <v-col>
              <QCChecklist :case-uid="kase.uid" :department="department" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                class="rounded-lg"
                :disabled="submitting"
                color="success"
                @click="openConfirmationDialog()"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                Mark as Done
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="dialog"
      @confirmation="setConfirmation"
      :title="title"
      :message="message"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import departments from "@/shared/departments";
import QCChecklist from '@/shared/QCChecklist'
import ConfirmDialog from "@/shared/ConfirmDialog";

export default {
  name: "CompleteModal",
  components: {
    QCChecklist,
    ConfirmDialog,
  },
  watch: {
    confirmation(value) {
      if (!value) {
        this.resetValues();
      }
      this.updateCase();
    },
  },
  data() {
    return {
      submitting: false,
      dialog: false,
      message: "",
      title: "",
      confirmation: null,
    };
  },
  computed: {
    ...mapGetters([
      "completeModal",
      "collection",
      "kase",
      "statuses",
      "employees",
      "me",
      "branches",
    ]),
    displayCompleteModal: {
      get() {
        return this.completeModal;
      },
      set(value) {
        if (!value) {
          this.triggerReload();
          this.hideCompleteModal();
        }
      },
    },
    caseStatus() {
      return this.kase.status_id;
    },
    department() {
      const statusDepartment = this.statuses.find(
        (status) => status.id === this.kase.status_id
      );
      if (!statusDepartment) {
        return;
      }
      if (statusDepartment.name === "ORDERED") {
        return this.departments?.find(
          (department) => department.value === "ADMIN"
        );
      }
      if (statusDepartment.in_design) {
        return this.departments?.find(
          (department) => department.value === "DESIGN"
        );
      }
      if (statusDepartment.in_finishing) {
        return this.departments?.find(
          (department) => department.value === "POLISHING"
        );
      }
      if (statusDepartment.in_production) {
        return this.departments?.find(
          (department) => department.value === "MACHINING"
        );
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "hideCompleteModal",
      "triggerReload",
    ]),
    async updateCase() {
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "mark_as_done",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.displayCompleteModal = false;
          this.submitting = false;
        });
    },
    setConfirmation(value) {
      this.confirmation = value;
    },
    openConfirmationDialog() {
      this.title = "WARNING";
      this.message =
        "This component will be marked as Dispatched and an invoice will be raised.\nThis cannot be undone - Are you sure?";
      this.dialog = true;
    },
    resetValues() {
      this.confirmation = null;
      this.displayCompleteModal = false;
    },
  },
  created() {
    this.departments = departments;
  },
};
</script>
