<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-folder-account</v-icon>
      OS Shared Files
    </v-card-title>
    <v-card-text v-if="uploading" class="text-center">
      <v-card height="256" flat elevation="0">
        <v-progress-circular size="100" indeterminate />
      </v-card>
    </v-card-text>
    <v-card-text v-if="!uploading">
      <v-card class="overflow-y-auto" height="256" flat elevation="0">
        <v-list dense>
          <v-list-item v-for="file in kase.outsource_sent_files" :key="file.id">
            <v-list-item-icon @click="viewFile(file)" style="cursor: pointer">
              <v-icon>mdi-paperclip</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="viewFile(file)"
              style="cursor: pointer"
            >
              {{ file.filename | sanitizeFilename }}
              <small
                ><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon @click="deleteFile(file)">mdi-delete</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <div
          class="text-center mb-5"
          v-if="kase.outsource_sent_files.length === 0"
        >
          <v-icon large class="ma-5">mdi-sleep</v-icon>
          <br />
          No Shared OS Files...
        </div>
      </v-card>
      <v-file-input
        class="rounded-lg"
        outlined
        dense
        v-model="uploads"
        label="Upload Shared Files"
        multiple
        @change="triggerUploadFiles()"
      />
      <FileViewModal v-model="sentFile" type="outsource_sent_file" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileViewModal from "@/collection/components/shared/FileViewModal";

export default {
  name: "OutsourceSharedFiles",
  components: {
    FileViewModal
  },
  data() {
    return {
      autoUploadTriggered: false,
      uploads: [],
      uploading: false,
      sentFile: null
    };
  },
  computed: {
    ...mapGetters(["kase"])
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async triggerUploadFiles() {
      this.uploading = true;
      const formData = new FormData();
      formData.append("action", "upload_files");
      formData.append("type", "outsource_sent_file");
      this.uploads.forEach(upload => {
        formData.append("files", upload, upload.name);
      });
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.uploading = false;
          this.uploads = [];
          this.triggerReload();
        });
    },
    async deleteFile(file) {
      if (!confirm("Are you sure to delete file?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "delete_file",
          type: "outsource_received_file",
          file_id: file.id
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
        });
    },
    viewFile(file) {
      this.sentFile = file;
    }
  }
};
</script>
