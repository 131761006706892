<template>
  <v-sheet v-if="collection">
    <CollectionHeader />
    <v-row>
      <v-col sm="12" md="8">
        <v-card outlined class="rounded-lg">
          <v-card-title>
            <v-icon class="mr-2">mdi-hexagon-multiple</v-icon>
            Case Components
            <v-row>
              <v-col sm="12" class="text-right mb-1">
                <v-btn
                  class="rounded-lg"
                  color="success"
                  @click="addComponentModal = true"
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  Add Component
                </v-btn>
              </v-col>
            </v-row>
            <v-tabs v-model="tab">
              <v-tab v-for="(dentate, index) in dentatesTabs" :key="index">
                <v-icon class="mr-2">{{ dentate.icon }}</v-icon>
                {{ dentate.value }}
                <v-chip small color="primary" class="ml-2">
                  {{ dentate.count }}
                </v-chip>
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(dentate, index) in dentatesTabs" :key="index">
                <v-row>
                  <v-col
                    sm="12"
                    lg="6"
                    v-for="(component, index) in filteredComponents"
                    :key="index"
                  >
                    <CaseComponentCard :component="component" />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <CollectionInfo class="mb-5" />
        <OrderStage class="mb-5" />
        <PortalLinkCard :portalKey="collection.portal_key" :outlined="true" />
        <AlertingMessageAlerts />
      </v-col>
    </v-row>
    <AddComponentModal v-model="addComponentModal" />
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import CollectionHeader from "@/collection/components/shared/CollectionHeader";
import OrderStage from "@/collection/components/OrderStage";
import AlertingMessageAlerts from "@/collection/components/shared/AlertingMessageAlerts";
import CaseComponentCard from "./CaseComponents/CaseComponentCard";
import CollectionInfo from "@/collection/components/CollectionInfo";
import AddComponentModal from "./CaseComponents/AddComponentModal";
import PortalLinkCard from "@/shared/PortalLinkCard";

export default {
  name: "CaseComponents",
  data() {
    return {
      addComponentModal: false,
      tab: null
    };
  },
  components: {
    CollectionHeader,
    AlertingMessageAlerts,
    CaseComponentCard,
    CollectionInfo,
    OrderStage,
    AddComponentModal,
    PortalLinkCard
  },
  watch: {
    kase() {
      this.setCorrectDentateTab();
    }
  },
  computed: {
    ...mapGetters(["collection", "dentates", "casesByDentate", "kase"]),
    dentatesTabs() {
      return this.dentates.map(dentate => {
        if (dentate === "UPPER") {
          return {
            value: "Upper",
            icon: "mdi-arrow-up",
            count: this.casesByDentate.upper.length
          };
        }
        if (dentate === "LOWER") {
          return {
            value: "Lower",
            icon: "mdi-arrow-down",
            count: this.casesByDentate.lower.length
          };
        }
        if (dentate === "BOTH") {
          return {
            value: "Both",
            icon: "mdi-arrow-up-down",
            count: this.casesByDentate.both.length
          };
        }
        return {
          value: "None",
          icon: "mdi-circle-outline",
          count: this.casesByDentate.none.length
        };
      });
    },
    filteredComponents() {
      if (!this.dentates) {
        return [];
      }
      if (this.dentates[this.tab] === "UPPER") {
        return this.sanitizeComponents(this.casesByDentate.upper);
      }
      if (this.dentates[this.tab] === "LOWER") {
        return this.sanitizeComponents(this.casesByDentate.lower);
      }
      if (this.dentates[this.tab] === "BOTH") {
        return this.sanitizeComponents(this.casesByDentate.both);
      }
      return this.sanitizeComponents(this.casesByDentate.none);
    }
  },
  methods: {
    sanitizeComponents(components) {
      const actives = [];
      const redos = [];
      const dispatched = [];
      const cancelled = [];

      components.forEach(component => {
        if (component.active && !component.redo) {
          actives.push(component);
        } else if (component.active && component.redo) {
          redos.push(component);
        } else if (!component.active && component.dispatched) {
          dispatched.push(component);
        } else {
          cancelled.push(component);
        }
      });
      return [...actives, ...redos, ...dispatched, ...cancelled];
    },
    setCorrectDentateTab() {
      if (!this.kase) {
        return;
      }
      if (this.casesByDentate.upper.includes(this.kase)) {
        this.tab = this.dentates.indexOf("UPPER");
        return;
      }
      if (this.casesByDentate.lower.includes(this.kase)) {
        this.tab = this.dentates.indexOf("LOWER");
        return;
      }
      if (this.casesByDentate.both.includes(this.kase)) {
        this.tab = this.dentates.indexOf("BOTH");
        return;
      }
      if (this.casesByDentate.none.includes(this.kase)) {
        this.tab = this.dentates.indexOf("NONE");
      }
    }
  },
  mounted() {
    this.setCorrectDentateTab();
  }
};
</script>
