<template>
  <v-row>
    <v-col sm="12" v-if="form">
      <OrderForm :group="false" :form="form" class="mb-5" />
    </v-col>
    <v-col sm="12" class="text-center my-10" v-if="!form">
      <v-icon>mdi-file-question</v-icon>
      <p>
        No Order Forms...
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import OrderForm from "@/collection/components/shared/OrderForm";

export default {
  name: "Form",
  components: {
    OrderForm
  },
  computed: {
    ...mapGetters(["kase", "orderForms"]),
    form() {
      return this.orderForms.find(form => form.case_uid === this.kase.uid);
    }
  }
};
</script>
