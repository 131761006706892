<template>
  <v-row>
    <v-col sm="12" lg="4">
      <strong>Partner Status</strong>
    </v-col>
    <v-col sm="12" lg="8">
      <pre>{{ evidentStatus }}</pre>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EvidentStatus",
  props: ["ior"],
  computed: {
    evidentStatus() {
      if (this.ior.response && this.ior.response.status) {
        return this.ior.response.status;
      }
      return "Unknown";
    }
  }
};
</script>
