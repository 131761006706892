<template>
  <div>
    <v-btn
      small
      outlined
      color="error"
      class="rounded-lg"
      v-if="!message.resolved_at"
      @click="resolve()"
    >
      <v-icon small class="mr-2">mdi-check</v-icon>
      Resolve
    </v-btn>
    <small v-if="message.resolved_at">
      <v-icon small>mdi-check</v-icon>
      Resolved by {{ message.resolved_by }} -
      {{ message.resolved_at | luxon({ output: "tz_med" }) }}
    </small>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AlertingMessage",
  props: ["message"],
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    async resolve() {
      if (!confirm("Are you sure?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/message/${this.message.uid}/`, {
          action: "resolve"
        })
        .then(() => {
          this.message.resolved_at = new Date().toISOString();
          this.message.resolved_by = "You";
          this.$emit("updated", this.message);
          this.stopLoading();
        });
    }
  }
};
</script>
