<template>
  <v-card outlined class="rounded-lg">
    <v-card-text>
      <v-card
        dark
        :color="statusColor"
        outlined
        class="rounded-lg"
        @click="openCase(approvalCase.uid)"
      >
        <v-card-text>
          <v-row align="center">
            <v-col sm="6" class="py-1">
              <strong>{{ toTitleCase(approval.approval_type) }}</strong>
            </v-col>
            <v-col sm="6" class="py-1 text-right">
              <v-chip small color="error" v-if="overdue">
                <v-icon class="mr-2">mdi-alert</v-icon>
                Overdue
              </v-chip>
            </v-col>
            <v-col sm="3" class="py-1">
              <v-chip small outlined class="mr-2">
                <strong>{{ approvalCase.case_mkey }}</strong>
              </v-chip>
            </v-col>
            <v-col sm="6" class="py-1">
              <small>
                <strong class="white--text">{{ approvalCase.job_type }}</strong>
              </small>
            </v-col>
            <v-col sm="3" class="py-1 text-right">
              <v-chip small outlined>
                {{ status }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row class="my-3" v-if="status !== 'Pending'" />
      <v-row class="my-1" v-if="status === 'Pending'">
        <v-col sm="6">
          <v-btn
            elevation="0"
            small
            color="primary"
            class="mr-2 rounded-lg"
            @click="openMessaging()"
          >
            <v-icon small class="mr-2">mdi-message</v-icon>
            Message
          </v-btn>
        </v-col>
        <v-col sm="6" class="text-right">
          <v-btn
            elevation="0"
            small
            color="success"
            @click="triggerApprove()"
            class="mr-2 rounded-lg"
          >
            <v-icon small class="mr-2">mdi-thumb-up</v-icon>
            Approve
          </v-btn>
          <v-btn
            elevation="0"
            class="rounded-lg"
            small
            color="error"
            @click="triggerCancellation()"
          >
            <v-icon small class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-1" v-if="nextReminder">
        <v-col cols="8">
          <v-icon class="mx-2">mdi-alarm</v-icon>
          Next reminder in {{ nextReminder | luxonRelative }}
        </v-col>
        <v-col cols="4" class="text-right">
          <a @click="triggerReminderCancellation()">Cancel Reminders</a>
        </v-col>
      </v-row>
      <v-card outlined max-height="500" class="mb-5 overflow-y-auto rounded-lg">
        <v-card-text>
          <v-timeline dense align-top>
            <v-timeline-item
              fill-dot
              small
              v-for="(item, index) in timeline"
              :key="index"
              :color="item.color"
              :icon="item.icon"
            >
              <h5>{{ item.description }}</h5>
              <p>
                <i>{{ item.time | luxon({ output: "tz_med" }) }}</i>
              </p>
              <p v-if="item.message" style="white-space: pre-wrap">
                {{ item.message }}
              </p>
              <Attachments
                v-if="item.object"
                :message="item.object"
                class="mr-5"
              />
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
      <v-card outlined class="rounded-lg">
        <v-card-text v-if="uploading" class="text-center">
          <v-progress-circular size="100" indeterminate />
        </v-card-text>
        <v-card-text v-if="!uploading">
          <v-list dense>
            <v-list-item v-for="file in approval.approval_files" :key="file.id">
              <v-list-item-icon>
                <v-icon>mdi-paperclip</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ file.filename | sanitizeFilename }}
                <small
                  ><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small
                >
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon @click="viewFile(file)">mdi-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-icon v-if="status === 'Pending'">
                <v-icon @click="deleteFile(file)">mdi-delete</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <div
            class="text-center mb-5"
            v-if="approval.approval_files.length === 0"
          >
            No Approval Files Uploaded
          </div>
          <v-file-input
            outlined
            dense
            v-model="uploads"
            class="rounded-lg"
            label="Upload Approval Files"
            multiple
            v-if="status === 'Pending'"
            @change="triggerUploadFiles()"
          />
          <FileViewModal
            v-model="file"
            type="approval_file"
            :case-uid="approval.case_uid"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import Attachments from "@/shared/Messages/Actions/Attachments";
import FileViewModal from "@/collection/components/shared/FileViewModal";

export default {
  name: "ApprovalCard",
  props: ["approval", "kase"],
  data() {
    return {
      uploads: [],
      uploading: false,
      file: null,
    };
  },
  components: {
    Attachments,
    FileViewModal,
  },
  computed: {
    ...mapGetters(["collection", "messages"]),
    status() {
      if (this.approval.cancelled_at) {
        return "Cancelled";
      }
      if (this.approval.approved_at) {
        return "Approved";
      }
      return "Pending";
    },
    statusColor() {
      if (this.status === "Cancelled") {
        return "error";
      }
      if (this.status === "Approved") {
        return "success";
      }
      return "warning";
    },
    overdue() {
      if (this.status !== "Pending") {
        return false;
      }
      const dueTime = DateTime.fromISO(this.approval.approval_due_at);
      if (DateTime.now() > dueTime) {
        return true;
      }
      return false;
    },
    nextReminder() {
      if (
        !this.approval.send_reminders ||
        !this.approval.reminder_interval_seconds ||
        !this.approval.next_reminder_due
      ) {
        return;
      }
      return this.approval.next_reminder_due;
    },
    approvalCase() {
      return this.collection.cases.find(
        (kase) => kase.uid === this.approval.case_uid
      );
    },
    timeline() {
      const timeline = [];
      timeline.push({
        time: this.approval.created_at,
        description: `Approval Requested by ${this.approval.created_by ||
          "Unknown"}`,
        icon: "mdi-send",
        color: "success",
      });
      timeline.push({
        time: this.approval.approval_due_at,
        description: "Due Time",
        icon: "mdi-alarm",
        color: "warning",
      });
      if (this.approval.approved_at) {
        timeline.push({
          time: this.approval.approved_at,
          description: `Approved by ${this.approval.approved_by}`,
          icon: "mdi-account-check",
          color: "success",
        });
      }
      if (this.approval.cancelled_at) {
        timeline.push({
          time: this.approval.cancelled_at,
          description: `Cancelled by ${this.approval.cancelled_by}`,
          icon: "mdi-cancel",
          color: "error",
        });
      }
      this.messages
        .filter((message) => {
          return message.approval_uid === this.approval.uid;
        })
        .forEach((note) => {
          timeline.push({
            time: note.created_at,
            description: `Message from ${note.author_reference}`,
            icon: "mdi-message",
            message: note.note,
            color: "primary",
            object: note,
          });
        });
      return timeline.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
    },
  },
  methods: {
    ...mapActions([
      "setCaseUID",
      "setCaseTab",
      "setCurrentView",
      "startLoading",
      "stopLoading",
      "triggerReload",
      "setMessageFilter",
    ]),
    openMessaging() {
      if (this.kase) {
        this.setCaseTab("notes");
      } else {
        this.setCurrentView("messages");
      }
      this.setMessageFilter({
        approvalFilter: [
          `${this.toTitleCase(this.approval.approval_type)} - ${
            this.approvalCase.job_type
          }`,
        ],
      });
    },
    async triggerApprove() {
      if (
        !confirm("Are you sure you want to approve on behalf of the client?")
      ) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/approval/${this.approval.uid}/`, {
          action: "approve",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    async triggerCancellation() {
      if (!confirm("Are you sure you want to cancel this approval request?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/approval/${this.approval.uid}/`, {
          action: "cancel",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    async triggerReminderCancellation() {
      if (
        !confirm(
          "Are you sure you want to cancel reminders on this approval request?"
        )
      ) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/approval/${this.approval.uid}/`, {
          action: "cancel_reminders",
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    },
    async triggerUploadFiles() {
      this.uploading = true;
      const formData = new FormData();
      this.uploads.forEach((upload) => {
        formData.append("files", upload, upload.name);
      });
      this.$axios
        .post(`/api/v2/approval/${this.approval.uid}/attach/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.uploading = false;
          this.uploads = [];
          this.triggerReload();
        });
    },
    async deleteFile(file) {
      if (!confirm("Are you sure to delete file?")) {
        return;
      }
      this.startLoading();
      this.$axios.delete(`/api/v2/approval/file/${file.id}/`).then(() => {
        this.triggerReload();
        this.stopLoading();
      });
    },
    viewFile(file) {
      this.file = file;
    },
    openCase(uid) {
      this.setCaseUID(uid);
      this.setCurrentView("components");
    },
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
  },
};
</script>
