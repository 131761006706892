<template>
  <v-card class="rounded-lg">
    <v-toolbar
      dense
      elevation="0"
      :color="toolbarColor.color"
      :dark="toolbarColor.dark"
      class="mb-5 rounded-lg"
    >
      <h1>
        <v-avatar left>
          <v-icon>mdi-inbox</v-icon>
        </v-avatar>
        {{ kase.case_mkey }}
        <v-chip
          outlined
          v-if="kase.redo && kase.redo == 'internal'"
          class="mr-2"
          >Internal Redo</v-chip
        >
        <v-chip
          outlined
          v-if="kase.redo && kase.redo == 'external'"
          class="mr-2"
          >External Redo</v-chip
        >
        <v-chip outlined v-if="collection.vip" class="mr-2">VIP</v-chip>
        <v-chip outlined label v-if="kase.virtual_product" class="mr-2"
          >Virtual</v-chip
        >
        <BinLocation
          v-if="collection.bin_location"
          :bin-location="collection.bin_location"
          :bin-location-branch="collection.bin_location_branch"
        />
      </h1>
      <v-spacer />
      <h5 class="d-none d-md-flex">
        {{ kase.job_type }} for {{ collection.patient_reference }}
      </h5>
      <v-spacer />
      <h5>
        Dispatch Time:
        <v-chip outlined>{{
          kase.internal_due | luxon({ output: "tz_med" })
        }}</v-chip>
      </h5>
      <v-spacer v-if="isImmediate" />
      <span v-if="isImmediate">
        <h1>
          <v-icon>mdi-bell</v-icon>
          <CountDown v-model="alerting" :due-time="kase.immediacy_due" />
        </h1>
      </span>
      <template v-slot:extension>
        <v-tabs
          class="rounded-b-lg"
          background-color="secondary"
          dark
          v-model="tab"
          align-with-title
        >
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab.value }}
          </v-tab>
          <v-spacer />
          <OEPHelper
            :collection="collection"
            :kase="kase"
            @installPopup="showInstall()"
          />
        </v-tabs>
      </template>
    </v-toolbar>
    <InstallPopup v-model="installPopup" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CountDown from "@/shared/CountDown";
import OEPHelper from "@/shared/OEPHelper";
import InstallPopup from "@/shared/InstallPopup";
import BinLocation from "@/shared/BinLocation";

export default {
  name: "ComponentHeader",
  props: ["value"],
  components: {
    CountDown,
    OEPHelper,
    InstallPopup,
    BinLocation
  },
  watch: {
    value(value) {
      this.tabKey = value;
    },
    tabKey(value) {
      this.$emit("input", value);
    },
    isImmediate(value) {
      if (!value) {
        this.alerting = false;
      }
    }
  },
  data() {
    return {
      tabKey: "overview",
      alerting: false,
      installPopup: false,
      tabs: [
        { key: "overview", value: "Overview" },
        { key: "notes", value: "Notes" },
        { key: "checklist", value: "Checklist" },
        { key: "timeline", value: "Timeline" },
        { key: "approval", value: "Approval" },
        { key: "outsourcing", value: "Outsourcing" },
        { key: "forms", value: "Order Form" },
        { key: "files", value: "Files" },
        { key: "audit", value: "Audit" }
      ]
    };
  },
  computed: {
    ...mapGetters(["collection", "kase"]),
    tab: {
      set(value) {
        this.tabKey = this.tabs[value].key;
      },
      get() {
        if (!this.tabKey) {
          return 0;
        }
        const tab = this.tabs.find(tab => this.tabKey === tab.key);
        if (!tab) {
          return 0;
        }
        return this.tabs.indexOf(tab);
      }
    },
    toolbarColor() {
      if (this.alerting) {
        return { color: "orange", dark: true };
      }
      if (this.kase.redo) {
        return { color: "red darken-4", dark: true };
      } else if (this.collection.vip) {
        return { color: "blue darken-4", dark: true };
      } else {
        return { color: "white", dark: false };
      }
    },
    isImmediate() {
      if (this.kase && this.kase.immediacy_due) {
        return true;
      }
      return false;
    }
  },
  methods: {
    showInstall() {
      this.installPopup = true;
    }
  }
};
</script>
