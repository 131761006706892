<template>
  <div>
    <v-toolbar flat class="mb-5">
      <v-btn class="mr-4 rounded-lg" color="success" @click="newModal = true">
        <v-icon class="mr-2">mdi-plus</v-icon>
        New Approval Request
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col sm="12" lg="4">
        <PortalLinkCard :portalKey="collection.portal_key" :outlined="true" />
      </v-col>
      <v-col sm="12" lg="4" v-for="(approval, index) in approvals" :key="index">
        <ApprovalCard :approval="approval" :kase="kase" />
      </v-col>
    </v-row>
    <NewApprovalModal v-model="newModal" :kase="kase" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApprovalCard from "@/collection/components/shared/Approvals/ApprovalCard";
import NewApprovalModal from "@/collection/components/shared/Approvals/NewApprovalModal";
import PortalLinkCard from "@/shared/PortalLinkCard";

export default {
  name: "Approval",
  components: {
    ApprovalCard,
    NewApprovalModal,
    PortalLinkCard
  },
  data() {
    return {
      newModal: false
    };
  },
  computed: {
    ...mapGetters(["kase", "collection"]),
    approvals() {
      return this.collection.approvals.filter(
        approval => approval.case_uid === this.kase.uid
      );
    }
  }
};
</script>
