<template>
  <v-card class="mb-5">
    <v-toolbar color="error" dark>
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-alert</v-icon>
        Case Unavailable
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      This case is no longer available as it has been cancelled.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Cancelled"
};
</script>
