<template>
  <div>
    <v-alert
      class="rounded-lg"
      dense
      v-for="(message, index) in alertingMessages"
      :key="index"
      type="error"
    >
      <p class="mb-0" style="white-space: pre-wrap;">{{ message.note }}</p>
      <p class="mb-0">
        <small>
          <i
            >{{ message.author_reference }} -
            {{ message.created_at | luxon({ output: "tz_med" }) }}</i
          >
        </small>
      </p>
      <p class="mb-0">
        <v-chip x-small label class="rounded-lg">{{
          fetchCaseDescription(message.case_uid)
        }}</v-chip>
      </p>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AlertingMessageAlerts",
  computed: {
    ...mapGetters(["messages", "collection"]),
    alertingMessages() {
      return this.messages.filter(message => {
        return (
          message.message_type === "ALERTING_MESSAGE" && !message.resolved_at
        );
      });
    }
  },
  methods: {
    fetchCaseDescription(uid) {
      if (!this.collection.cases) {
        return;
      }
      const kase = this.collection.cases.find(kase => kase.uid === uid);
      if (!kase) {
        return;
      }
      return `${kase.job_type} (${kase.collection_reference})`;
    }
  }
};
</script>
