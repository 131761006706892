<template>
  <div style="max-width: 200px; min-height: 200px;">
    <v-row
      v-if="!imageURL"
      class="fill-height ma-0"
      align="center"
      justify="center"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-img
      style="cursor: pointer"
      @click="clicked"
      v-if="imageURL"
      :src="imageURL"
      class="rounded-xl"
      max-height="200"
    />
  </div>
</template>

<script>
export default {
  name: "ImagePreview",
  props: ["image"],
  data() {
    return {
      imageURL: null,
    };
  },
  methods: {
    fetchFileURI() {
      if (!this.image) {
        return;
      }
      this.$axios
        .get(`/api/v2/messages/attachments/${this.image.uid}/`)
        .then((response) => {
          this.imageURL = response.data.url;
        });
    },
    clicked() {
      this.$emit("clicked", this.image);
    },
  },
  mounted() {
    this.fetchFileURI();
  },
};
</script>
