import env from "@/plugins/axios_options";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

export default function initSentry() {
  if (env.sentryEnv != "development") {
    Sentry.init({
      Vue,
      dsn:
      "https://3a26b981dd77f2d8dd14a6c6193e7cab@o1047672.ingest.sentry.io/4506238054498304",
      integrations: [new Sentry.Replay()],
      environment: env.sentryEnv,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}
