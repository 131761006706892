<template>
  <v-card outlined class="mb-5 rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-file</v-icon>
      OS Received Files
    </v-card-title>
    <v-card-text>
      <v-card class="overflow-y-auto" height="256" flat elevation="0">
        <v-list dense>
          <v-list-item
            v-for="file in kase.outsource_received_files"
            :key="file.id"
          >
            <v-list-item-icon @click="viewFile(file)" style="cursor: pointer">
              <v-icon>mdi-paperclip</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              @click="viewFile(file)"
              style="cursor: pointer"
            >
              {{ file.filename | sanitizeFilename }}
              <small
                ><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small
              >
              <strong v-if="file.partner">{{ file.partner }}</strong>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div
          class="text-center mb-5"
          v-if="kase.outsource_received_files.length === 0"
        >
          <v-icon large class="ma-5">mdi-sleep</v-icon>
          <br />
          No Received OS Files...
        </div>
      </v-card>
      <FileViewModal v-model="receivedFile" type="outsource_received_file" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileViewModal from "@/collection/components/shared/FileViewModal";

export default {
  name: "OutsourceReceivedFiles",
  components: {
    FileViewModal
  },
  data() {
    return {
      receivedFile: null
    };
  },
  computed: {
    ...mapGetters(["kase"])
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    viewFile(file) {
      this.receivedFile = file;
    }
  }
};
</script>
