<template>
  <v-snackbar timeout="5000" bottom right rounded="lg" v-model="installPopup">
    <v-icon class="mr-5">mdi-information</v-icon>
    <small
      >If you haven't got the OEP Helper installed, please download and
      install.</small
    >
    <template v-slot:action="{ attrs }">
      <v-btn color="success" text v-bind="attrs" @click="downloadOEPHelper()">
        Download Now
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "InstallPopup",
  props: ["value"],
  computed: {
    installPopup: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          return false;
        }
      }
    }
  },
  methods: {
    downloadOEPHelper() {
      window.open(
        "https://storage.googleapis.com/osteon-software-distribution/OEPHelper.zip"
      );
    }
  }
};
</script>
