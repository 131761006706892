<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-state-machine</v-icon>
      Order Stage
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="collection.order_stage"
        outlined
        dense
        class="rounded-lg"
        :items="orderStageItems"
        placeholder="Order Stage"
        @change="updateCollection()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrderStage",
  computed: {
    ...mapGetters(["collection", "orderStages"]),
    orderStageItems() {
      return [{ text: "None", value: null }, ...this.orderStages];
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async updateCollection() {
      this.startLoading();
      this.$axios
        .patch(`/api/v2/collection/${this.collection.uid}/`, {
          order_stage: this.collection.order_stage
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    }
  }
};
</script>
