export default function(param, value) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (value) {
    urlParams.set(param, value);
  } else {
    urlParams.delete(param);
  }
  return window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${urlParams}`
  );
}
