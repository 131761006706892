<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(kase, index) in cases" :key="index">
      <v-expansion-panel-header>
        <v-row>
          <v-col cols="12" lg="6">
            {{ kase.job_type }}
          </v-col>
          <v-col cols="12" lg="6" :class="!smallScreen ? 'text-right' : ''">
            <Status :kase="kase" class="mr-2" />
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ExpandedDetail :kase="kase" :read-only="readOnly" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Status from "./StatusChip";
import ExpandedDetail from "./ExpandedDetail";

export default {
  name: "OsteonComponents",
  props: ["cases", "readOnly"],
  components: {
    Status,
    ExpandedDetail
  },
  computed: {
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    }
  }
};
</script>
