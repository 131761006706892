<template>
  <div>
    <v-chip v-if="me.staging" color="warning" class="mr-3">
      Staging
    </v-chip>
    <v-menu min-width="450" max-width="450" open-on-hover dark offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="rounded-lg" outlined dark v-on="on" v-bind="attrs">
          <v-icon>mdi-view-grid</v-icon>
        </v-btn>
      </template>
      <v-card class="rounded-lg">
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              class="pa-1"
              v-for="(link, index) in activeLinks"
              :key="index"
            >
              <v-card
                outlined
                min-height="100%"
                light
                class="rounded-lg d-flex"
                style="cursor: pointer;"
              >
                <v-card-text
                  class="text-center px-1"
                  @click="navigateTo(link.system)"
                >
                  <v-icon class="mb-1">{{ link.icon }}</v-icon>
                  <br />
                  <small>{{ link.name }}</small>
                </v-card-text>
                <v-card-text
                  class="text-center px-1 grey darken-4 white--text rounded-0 rounded-r-lg"
                  v-if="link.system === 'checkin'"
                  style="line-height: 1rem"
                  @click="navigateTo('newCheckin')"
                >
                  <v-icon color="white">{{ link.icon }}</v-icon>
                  <br />
                  <small>New </small>
                  <br />
                  <small>
                    {{ link.name }}
                  </small>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" class="pa-1">
              <v-card
                @click="logout()"
                outlined
                min-height="100%"
                color="error"
                class="rounded-lg"
              >
                <v-card-text class="text-center">
                  <v-icon class="mb-1">mdi-logout</v-icon>
                  <br />
                  <small>Logout</small>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationMenu",
  props: ["currentSystem", "restricted"],
  data() {
    return {
      links: [
        {
          system: "case",
          name: "Cases",
          restricted: false,
          icon: "mdi-clipboard-plus-outline",
        },
        {
          system: "client",
          name: "Clients",
          restricted: false,
          icon: "mdi-hospital-box",
        },
        {
          system: "customer_service",
          name: "Customer Service",
          restricted: false,
          icon: "mdi-face-agent",
        },
        {
          system: "checkin",
          name: "Checkin",
          restricted: false,
          icon: "mdi-inbox-arrow-down",
        },
        {
          system: "design",
          name: "Design",
          restricted: false,
          icon: "mdi-pencil-ruler",
        },
        {
          system: "production",
          name: "Production",
          restricted: false,
          icon: "mdi-factory",
        },
        {
          system: "finishing",
          name: "Finishing",
          restricted: false,
          icon: "mdi-beaker-outline",
        },
        {
          system: "warehouse",
          name: "Warehouse",
          restricted: false,
          icon: "mdi-inbox",
        },
        {
          system: "accounts",
          name: "Accounts",
          restricted: true,
          icon: "mdi-bank",
        },
        {
          system: "gauge_manufacturing",
          name: "Gauge MRP",
          restricted: true,
          icon: "mdi-package-variant-closed",
        },
        {
          system: "management",
          name: "Management",
          restricted: true,
          icon: "mdi-lock",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["me"]),
    activeSystem() {
      return this.links.findIndex((link) => link.system === this.currentSystem);
    },
    activeLinks() {
      if (!this.restricted) {
        return this.links;
      }
      return this.links.filter((link) => !link.restricted);
    },
  },
  methods: {
    navigateTo(system) {
      if (system === "newCheckin")
        return (document.location = "/clients/checkin");
      document.location = `${system}.html`;
    },
    logout() {
      document.location = "/oauth2/logout/";
    },
  },
};
</script>
