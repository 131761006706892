<template>
  <v-card outlined class="rounded-lg">
    <v-card-subtitle>
      <v-row>
        <v-col sm="12" lg="9" class="mb-2">
          <h4>
            <v-icon class="mr-2">mdi-screw-round-top</v-icon>
            {{ componentType }}
          </h4>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-row v-for="(component, index) in components" :key="index">
        <v-col sm="12" lg="7" class="py-0">
          <v-autocomplete
            v-model="component.component_id"
            outlined
            dense
            :items="componentItems"
            :label="componentType"
            class="rounded-lg"
            @change="updateComponentCode(component)"
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="8" lg="4" class="py-0">
          <v-text-field
            v-model.number="component.quantity"
            min="1"
            type="number"
            outlined
            dense
            :rules="[component.quantity > 0]"
            :error-messages="updateErrorMessage(component)"
            class="rounded-lg"
            label="Quantity"
          >
          </v-text-field>
        </v-col>
        <v-col sm="4" lg="1" class="pa-0">
          <v-btn v-if="edit" small text fab @click="deleteComponent(component)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            class="rounded-lg"
            v-if="edit"
            @click="addComponent()"
            :disabled="canAddComponent"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            New
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ComponentTypeCard",
  props: ["componentType", "componentItems", "components", "edit"],
  computed: {
    canAddComponent() {
      if (!this.components) {
        return false;
      }
      return this.components.some(component => component.component_id === null);
    }
  },
  methods: {
    addComponent() {
      this.$emit("addComponent", {
        code: null,
        component_id: null,
        quantity: 1,
        name: null,
        description: null
      });
    },
    deleteComponent(component) {
      this.$emit("deleteComponent", component);
    },
    updateComponentCode(component) {
      if (!component.component_id) {
        return;
      }
      const filteredComponent = this.componentItems.filter(
        componentItem => componentItem.value === component.component_id
      );
      component.code = filteredComponent?.[0].code;
      component.name = filteredComponent?.[0].name;
      component.description = filteredComponent?.[0].description;
    },
    updateErrorMessage(component) {
      if (component && component.quantity > 0) {
        return "";
      }
      return "Quantity is required";
    }
  }
};
</script>
