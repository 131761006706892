<template>
  <v-card elevation="0" outlined class="rounded-lg">
    <v-card-subtitle>
      <v-row>
        <v-col sm="12" lg="9" class="mb-2">
          <h4>
            <v-icon class="mr-2">mdi-currency-usd</v-icon>
            Pricing Kits
          </h4>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-row v-for="(pricing, index) in pricingKits" :key="index">
        <v-col sm="12" lg="7" class="py-0">
          <v-autocomplete
            v-model="pricing.kit_id"
            outlined
            dense
            :items="kitItems"
            :label="'Pricing Kit'"
            class="rounded-lg"
            @change="updatePricingKit(pricing)"
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="8" lg="4" class="py-0">
          <v-autocomplete
            v-model="pricing.kit_dentate_name"
            outlined
            dense
            :items="dentateItems"
            :label="'Dentate'"
            class="rounded-lg"
            @change="updatePricingKit(pricing)"
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="4" lg="1" class="pa-0">
          <v-btn v-if="edit" small text fab @click="deletePricingKit(pricing)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            v-if="edit"
            class="rounded-lg"
            @click="addPricingKit()"
            :disabled="canAddKit"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            New
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PricingKitCard",
  props: ["kitItems", "pricingKits", "edit"],
  computed: {
    canAddKit() {
      if (!this.pricingKits) {
        return false;
      }
      return this.pricingKits.some(pricing => pricing.kit_id === null);
    }
  },
  data() {
    return {
      dentateItems: ["UPPER", "LOWER"]
    };
  },
  methods: {
    addPricingKit() {
      const emptyKit = {
        kit_collection_id: null,
        kit_dentate_id: null,
        kit_dentate_name: "UPPER",
        kit_id: null,
        kit_code: null,
        kit_name: null,
        kit_implant_qty: null
      };
      this.$emit("addPricingKit", emptyKit);
    },
    deletePricingKit(pricing) {
      this.$emit("deletePricingKit", pricing);
    },
    updatePricingKit(pricing) {
      if (!pricing.kit_id) {
        return;
      }
      const filteredKit = this.kitItems.filter(
        kitItem => kitItem.value === pricing.kit_id
      );
      pricing.kit_code = filteredKit?.[0].value;
      pricing.kit_name = filteredKit?.[0].text;
    }
  }
};
</script>
