<template>
  <div>
    <v-toolbar flat class="mb-5">
      <v-btn
        class="mr-4 rounded-lg"
        color="success"
        @click="showOutsourceModal()"
      >
        <v-icon class="mr-2">mdi-plus</v-icon>
        Start New
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!autoUploadTriggered"
        class="mr-4 rounded-lg"
        @click="triggerAutoUpload()"
      >
        <v-icon class="mr-2">mdi-cloud-upload</v-icon>
        Auto Upload
      </v-btn>
      <v-btn v-if="autoUploadTriggered" class="mr-4 rounded-lg" color="success">
        <v-icon class="mr-2">mdi-check</v-icon>
        Auto Upload
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col sm="12" lg="4">
        <OutsourceSharedFiles class="mb-5" />
        <OutsourceReceivedFiles />
      </v-col>
      <v-col
        sm="12"
        lg="4"
        v-for="outsource in kase.outsources"
        :key="outsource.id"
      >
        <JobCard :outsource="outsource" />
      </v-col>
    </v-row>
    <OutsourceModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JobCard from "./Outsource/JobCard";
import OutsourceModal from "./Outsource/OutsourceModal";
import OutsourceSharedFiles from "@/collection/components/ComponentDrawer/Files/OutsourceSharedFiles";
import OutsourceReceivedFiles from "@/collection/components/ComponentDrawer/Files/OutsourceReceivedFiles";

export default {
  name: "Outsource",
  components: {
    JobCard,
    OutsourceModal,
    OutsourceSharedFiles,
    OutsourceReceivedFiles
  },
  data() {
    return {
      autoUploadTriggered: false
    };
  },
  computed: {
    ...mapGetters(["kase"])
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "showOutsourceModal"]),
    async triggerAutoUpload() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "auto_outsource_upload"
        })
        .then(() => {
          this.autoUploadTriggered = true;
          this.stopLoading();
        });
    }
  }
};
</script>
