<template>
  <v-card outlined class="rounded-lg mb-5" v-if="checklist">
    <v-card-title @click="naughty()">
      <v-chip outlined class="mr-2">{{ checklist.caseMkey }}</v-chip>
      <h6>{{ checklist.jobType }}</h6>
      <v-spacer />
      <span v-if="clicks >= 5">
        <v-icon class="mr-2">mdi-arm-flex</v-icon>
        Harrison Mode
      </span>
    </v-card-title>
    <v-card-text>
      <v-row v-if="filteredAndSortedItems.length === 0">
        <v-col cols="12" class="text-center my-10">
          <p>
            <v-icon>mdi-sleep</v-icon>
          </p>
          <p>
            No Checklist Items
          </p>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in filteredAndSortedItems"
        :key="index"
        dense
        class="my-0 py-0"
      >
        <v-col sm="12" lg="6" align-self="center">
          <h4>
            <v-icon v-if="item.checked_at" class="mr-2">mdi-check</v-icon>
            <v-icon v-if="!item.checked_at" class="mr-2"
              >mdi-circle-small</v-icon
            >
            {{ item.name }}
          </h4>
        </v-col>
        <v-col sm="12" lg="2" align-self="center">
          <v-chip label outlined x-small>
            {{ departmentNameFromCode(item.department) }}
          </v-chip>
        </v-col>

        <v-col align-self="center" cols="2">
          <v-checkbox
            :input-value="item.done"
            value
            dense
            hide-details
            class="pa-0 ma-0 mb-1"
            :disabled="item.not_required"
            @click.native.prevent.stop.capture="toggleChecklistItemDone(item)"
          >
            <template v-slot:label>
              <span style="font-size: 13px;">Done</span>
            </template>
          </v-checkbox>
          <v-checkbox
            :input-value="item.not_required"
            value
            dense
            hide-details
            class="pa-0 ma-0"
            :disabled="item.done"
            @click.native.prevent.stop.capture="
              toggleChecklistItemNotRequired(item)
            "
          >
            <template v-slot:label>
              <span style="font-size: 13px;">Not Required</span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col sm="12" lg="2" class="mt-5 text-right">
          <small>
            <v-chip v-if="item.checked_by_name" small dark color="green">
              <v-icon class="mr-2">mdi-check</v-icon>
              {{ item.checked_by_name }}
            </v-chip>
            <br />
            {{ item.checked_at | luxon({ output: "tz_med" }) }}
          </small>
        </v-col>
        <v-col cols="12" v-if="index + 1 !== filteredAndSortedItems.length">
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import departments from "@/shared/departments";

export default {
  name: "ComponentChecklist",
  props: ["checklist", "department"],
  data() {
    return {
      clicks: 0
    };
  },
  computed: {
    filteredAndSortedItems() {
      if (!this.checklist.checklist) return [];
      return [
        ...this.checklist.checklist.filter(item => {
          if (!this.department || this.department.value === "ALL") {
            return true;
          }
          return item.department === this.department.value;
        })
      ].sort((a, b) => {
        return a.department.localeCompare(b.department) || a.id - b.id;
      });
    }
  },
  methods: {
    departmentNameFromCode(code) {
      return this.departments.find(department => department.value === code)
        .name;
    },
    toggleChecklistItemDone(checklistItem) {
      if (checklistItem.checked_at) {
        return this.uncheckItem(checklistItem);
      }
      return this.markAsDone(checklistItem);
    },
    toggleChecklistItemNotRequired(checklistItem) {
      if (checklistItem.checked_at) {
        return this.uncheckItem(checklistItem);
      }
      return this.markAsNotRequired(checklistItem);
    },
    async markAsDone(checklistItem) {
      this.$emit("item-checked", {
        ...checklistItem,
        caseUid: this.checklist.caseUid,
        done: true,
        not_required: false
      });
    },
    async markAsNotRequired(checklistItem) {
      this.$emit("item-checked", {
        ...checklistItem,
        caseUid: this.checklist.caseUid,
        done: false,
        not_required: true
      });
    },
    async uncheckItem(checklistItem) {
      if (confirm("Are you sure you wish to uncheck?") === false) {
        return;
      }
      this.$emit("item-unchecked", {
        ...checklistItem,
        caseUid: this.checklist.caseUid
      });
    },
    naughty() {
      this.clicks++;
      setTimeout(() => {
        this.clicks = 0;
      }, 1_000);
      if (this.clicks === 5) {
        this.filteredAndSortedItems.forEach(item => {
          if (!item.checked_at) {
            this.markAsDone(item);
          }
        });
      }
    }
  },
  created() {
    this.departments = departments;
  }
};
</script>
