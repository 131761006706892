<template>
  <div>
    <div v-if="loading" class="text-center ma-5">
      <v-progress-circular indeterminate size="256" color="primary">
        Loading Active Cases
      </v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-app-bar elevation="0" outlined class="mb-5 rounded-lg">
        <h3>
          <v-avatar>
            <v-icon>mdi-playlist-play</v-icon>
          </v-avatar>
          All Active Cases
        </h3>
        <v-row>
          <v-spacer />
          <v-col sm="6" lg="3">
            <v-select
              v-model="countryFilter"
              clearable
              dense
              outlined
              :items="countryItems"
              class="mt-5 mr-2 rounded-lg"
            >
              <template v-slot:selection="{ item }">
                <v-row align="center" class="ml-1">
                  <CountryFlags
                    :country="item.value"
                    size="normal"
                    class="mr-1 mb-1"
                  />
                  <span>{{ item.text }}</span>
                </v-row>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <CountryFlags
                          :country="item.value"
                          size="normal"
                          class="mr-1 my-auto"
                        />
                        {{ item.text }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-card outlined class="rounded-lg">
        <v-data-table
          :headers="headers"
          :items="filteredCollections"
          @click:row="openCollection"
          class="row-pointer"
        >
          <template v-slot:item.icon>
            <v-row>
              <v-col class="text-right">
                <v-avatar left size="32" color="#147BD1">
                  <v-icon color="white">mdi-hexagon-multiple</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.collection_id="{ item }">
            <v-row>
              <v-col>
                <strong>{{ item.collection_id }}</strong>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.country="{ item }">
            <v-row>
              <v-col>
                <CountryFlags :country="item.country" size="normal" />
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.client_name="{ item }">
            <v-row>
              <v-col>
                {{ item.client_name }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.patient_reference="{ item }">
            <v-row>
              <v-col>
                {{ item.patient_reference }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.first_case_start_time="{ item }">
            <v-row>
              <v-col>
                <v-chip outlined small>{{
                  item.first_case_start_time | luxon({ output: "tz_date_med" })
                }}</v-chip>
                <span
                  v-if="
                    item.first_case_start_time !== item.last_case_start_time
                  "
                >
                  -
                  <v-chip outlined small>{{
                    item.last_case_start_time | luxon({ output: "tz_date_med" })
                  }}</v-chip>
                </span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.status>
            <v-row>
              <v-col>
                <v-chip small color="success">Active</v-chip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.case_count="{ item }">
            <v-row>
              <v-col>
                <v-chip small outlined label>
                  {{ item.case_count }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import CountryFlags from "@/shared/CountryFlags";

export default {
  name: "ActiveCollections",
  components: {
    CountryFlags
  },
  data() {
    return {
      loading: true,
      countryFilter: null,
      headers: [
        { text: "", value: "icon", sortable: false },
        { text: "Case ID", value: "collection_id" },
        { text: "Country / Region", value: "country" },
        { text: "Client", value: "client_name" },
        { text: "Patient Reference", value: "patient_reference" },
        { text: "Date Range", value: "first_case_start_time" },
        { text: "Status", value: "status" },
        { text: "Components", value: "case_count" }
      ],
      activeCollections: []
    };
  },
  methods: {
    openCollection(collection) {
      window.open(`/static/v2/collection.html?uid=${collection.uid}`);
    },
    async fetchAllActive() {
      this.$axios.get("/api/v2/collections/active/").then(response => {
        this.activeCollections = response.data;
        this.loading = false;
      });
    }
  },
  computed: {
    countryItems() {
      const countryItems = [{ text: "All Countries/Regions", value: null }];
      this.activeCollections.forEach(collection => {
        if (
          collection.country &&
          !countryItems.map(item => item.value).includes(collection.country)
        ) {
          countryItems.push({
            text: collection.country,
            value: collection.country
          });
        }
      });
      return countryItems;
    },
    filteredCollections() {
      if (!this.countryFilter) {
        return this.activeCollections;
      }
      return this.activeCollections.filter(
        collection => collection.country === this.countryFilter
      );
    }
  },
  mounted() {
    this.fetchAllActive();
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
