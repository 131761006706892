<template>
  <div>
    <v-card outlined class="rounded-lg">
      <v-card-title>
        <v-icon class="mr-2">mdi-account</v-icon>
        Delegation Audit
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-center ma-3" v-if="audit.length === 0">
              There is no delegation history recorded for this case
            </div>
            <v-card class="overflow-y-auto" max-height="600" elevation="0">
              <v-timeline dense v-if="audit.length > 0">
                <v-timeline-item
                  fill-dot
                  small
                  icon="mdi-handshake"
                  v-for="(change, index) in audit"
                  :key="index"
                >
                  <strong>
                    {{ employeeNameFromID(change.delegated_to_id) }}
                  </strong>
                  <br />
                  {{ employeeDepartmentFromID(change.delegated_to_id) }}
                  <br />
                  <i>{{ change.modified_at | luxonRelative }}</i>
                </v-timeline-item>
              </v-timeline>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DelegationAudit",
  props: ["audit"],
  computed: {
    ...mapGetters(["employees"])
  },
  methods: {
    employeeNameFromID(id) {
      const employee = this.employees.find(employee => employee.id === id);
      if (!employee) {
        return "Unknown";
      }
      return `${employee.first_name} ${employee.last_name}`;
    },
    employeeDepartmentFromID(id) {
      const employee = this.employees.find(employee => employee.id === id);
      if (!employee) {
        return;
      }
      return employee.department;
    }
  }
};
</script>
