function getConf() {
  const req = new XMLHttpRequest();
  req.open("GET", "/conf", false);
  req.send();
  return JSON.parse(req.responseText);
}
const conf = getConf();

export default {
  baseURL: conf.BACKEND_URL,
  oepURL: conf.OEP_URL,
  sentryEnv: conf.SENTRY_ENV,
};
