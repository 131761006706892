<template>
  <v-row justify="center" v-if="selectedInvoice">
    <v-dialog v-model="dialog" max-width="1000" @click:outside="close">
      <v-card v-if="selectedInvoice" outlined class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Split Invoice
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row class="pt-5">
            <v-col sm="12" lg="6">
              <v-card outlined class="ml-4 fill-height rounded-lg">
                <v-card-title>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-layout class="align-center justify-center fill-height">
                        <v-icon left v-bind="attrs" v-on="on">
                          mdi-receipt-text-outline
                        </v-icon>
                        {{ selectedInvoice.invoice_group_invoice_number }}
                      </v-layout>
                    </template>
                    <span>Existing Invoice</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <draggable
                    v-model="originalInvoiceList"
                    :move="onMove"
                    v-bind="dragOptions"
                    @start="isDragging = true"
                    @end="isDragging = false"
                    :empty-insert-threashold="500"
                  >
                    <v-card
                      v-for="(card, index) in originalInvoiceList"
                      :key="index"
                      class="my-2 pa-2 rounded-lg"
                      outlined
                    >
                      <v-row>
                        <v-col sm="12" lg="4" class="mb-0 ml-2">
                          <v-chip outlined>
                            <strong>{{ card.case_mkey }}</strong>
                          </v-chip>
                        </v-col>
                        <v-col sm="12" lg="6" class="mb-0 ml-2">
                          <small>
                            {{ card.job_type_name }}
                          </small>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12" lg="12" class="text-right">
                          <v-chip
                            small
                            v-if="card.status_code == 'dispatched'"
                            color="green"
                            class="mr-2"
                            outlined
                            label
                            >{{ card.status }}</v-chip
                          >
                          <v-chip
                            small
                            v-if="card.status_code !== 'dispatched'"
                            color="orange"
                            class="mr-2"
                            outlined
                            label
                            >{{ card.status }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      v-if="originalInvoiceList.length === 0"
                      outlined
                      style="border-style: dashed;"
                      class="rounded-lg"
                    >
                      <v-card-text class="text-center my-10">
                        Drag components here to remove from new invoice group
                      </v-card-text>
                    </v-card>
                  </draggable>
                </v-card-text>
                <v-card flat>
                  <v-card-text class="text-right font-weight-bold">
                    Total: $ {{ totalAmount }}
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
            <v-col sm="12" lg="6">
              <v-card outlined class="mb-2 mr-4 fill-height rounded-lg">
                <v-card-title>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-layout class="align-center justify-center fill-height">
                        <v-icon left v-bind="attrs" v-on="on">
                          mdi-receipt-text-outline
                        </v-icon>
                        New Invoice Group
                      </v-layout>
                    </template>
                    <span>Drop cases here to generate a new invoice group</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <draggable
                    v-model="newInvoiceList"
                    :move="onMove"
                    v-bind="dragOptions"
                    :empty-insert-threashold="500"
                  >
                    <v-card
                      v-for="(card, index) in newInvoiceList"
                      :key="index"
                      class="my-2 pa-2 rounded-lg"
                      outlined
                    >
                      <v-row>
                        <v-col sm="12" lg="4" class="mb-4 ml-2">
                          <v-chip outlined>
                            <strong>{{ card.case_mkey }}</strong>
                          </v-chip>
                        </v-col>
                        <v-col sm="12" lg="6" class="mb-4 ml-2">
                          <small>
                            {{ card.job_type_name }}
                          </small>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="12" lg="6" class="text-left ma-0 py-0">
                          <v-text-field
                            small
                            class="right-aligned-input rounded-lg"
                            v-model.number="card.split_amount"
                            :rules="[
                              card.split_amount <= 0
                                ? 'Value must be more than $0'
                                : totalAmount - totalSplitAmount <= 0
                                ? 'Value must not exceed Total Amount'
                                : true
                            ]"
                            background-color="transparent"
                            type="number"
                            label="Split Amount"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col sm="12" lg="6" class="text-right">
                          <v-chip
                            small
                            v-if="card.status_code == 'dispatched'"
                            color="green"
                            class="mr-2"
                            outlined
                            label
                            >{{ card.status }}</v-chip
                          >
                          <v-chip
                            small
                            v-if="card.status_code !== 'dispatched'"
                            color="orange"
                            class="mr-2"
                            outlined
                            label
                            >{{ card.status }}</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card
                      v-if="newInvoiceList.length === 0"
                      outlined
                      style="border-style: dashed;"
                      class="rounded-lg"
                    >
                      <v-card-text class="text-center my-10">
                        Drag components here to create new invoice group
                      </v-card-text>
                    </v-card>
                  </draggable>
                </v-card-text>
                <v-card flat v-if="totalSplitAmount">
                  <v-card-text class="text-right font-weight-bold">
                    Total: $ {{ totalSplitAmount }}
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn color="error" @click="close()" class="mr-2 rounded-lg">
                <v-icon class="mr-2">mdi-close</v-icon>
                Cancel
              </v-btn>
              <v-btn
                color="success"
                :disabled="disableConfirm"
                class="rounded-lg"
                @click="confirmSplit()"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "InvoiceSplitModal",
  props: ["dialog", "selectedInvoice", "totalAmount"],
  components: {
    draggable
  },
  data() {
    return {
      originalInvoiceList: [],
      newInvoiceList: [],
      editable: true,
      isDragging: false,
      delayedDragging: false
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    disableConfirm() {
      if (
        this.newInvoiceList.length === 0 ||
        this.originalInvoiceList.length === 0 ||
        !this.totalSplitAmount ||
        this.totalSplitAmount === 0 ||
        this.totalAmount - this.totalSplitAmount <= 0
      ) {
        return true;
      }
      return false;
    },
    totalSplitAmount() {
      if (this.newInvoiceList.length > 0) {
        return this.newInvoiceList.reduce((total, value) => {
          total += value.split_amount;
          return total;
        }, 0);
      }
      return 0;
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.originalInvoiceList = this.selectedInvoice.related_case_details;
      }
    }
  },
  methods: {
    close() {
      this.originalInvoiceList = [];
      this.newInvoiceList = [];
      this.$emit("close", false);
    },
    confirmSplit() {
      const payload = {
        invoice_group_uid: this.selectedInvoice.invoice_group_uid,
        groups: [
          { cases: this.originalInvoiceList },
          { cases: this.newInvoiceList }
        ]
      };
      this.$emit("confirmSplit", payload);
      this.originalInvoiceList = [];
      this.newInvoiceList = [];
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    }
  },
  mounted() {
    this.originalInvoiceList = this.selectedInvoice.related_case_details;
  }
};
</script>
