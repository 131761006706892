<template>
  <v-app>
    <NavigationDrawer />
    <AppBar />
    <ComponentDrawer />
    <v-main>
      <v-overlay v-if="isBlockLoading" class="text-center">
        <v-progress-circular :size="70" color="#147BD1" indeterminate />
        <h3 class="mt-3">{{ loadingMessage }}</h3>
      </v-overlay>
      <v-container fluid v-if="!isBlockLoading">
        <v-row v-if="currentView === 'noCollection'">
          <v-col sm="12">
            <Search class="mt-10" />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'components'">
          <v-col sm="12">
            <CaseComponents />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'messages'">
          <v-col sm="12">
            <GlobalMessages />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'approvals'">
          <v-col sm="12">
            <Approvals />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'orderForms'">
          <v-col sm="12">
            <OrderForms />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'checklists'">
          <v-col sm="12">
            <Checklists />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'invoices'">
          <v-col sm="12">
            <Invoices />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'dispatches'">
          <v-col sm="12">
            <Dispatches />
          </v-col>
        </v-row>
        <v-row v-if="currentView === 'active'">
          <v-col sm="12">
            <ActiveCollections />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" class="text-center mt-10" v-if="collectionUID">
            <v-divider />
            <small>
              Collection UID:
              <code>{{ collectionUID }}</code>
            </small>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app dark height="50">
      <v-row>
        <v-col cols="8" class="mt-2">
          <v-icon class="mr-2">mdi-clock</v-icon>
          {{ now }}
        </v-col>
        <v-col cols="4">
          <GlobalSearch />
        </v-col>
      </v-row>
    </v-footer>
    <UpdateAvailable />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import NavigationDrawer from "@/collection/components/NavigationDrawer";
import ComponentDrawer from "@/collection/components/ComponentDrawer";
import AppBar from "@/collection/components/AppBar";
import GlobalSearch from "@/shared/GlobalSearch";
import loadingMessages from "@/shared/loading_messages";
import Search from "@/collection/components/Search";
import CaseComponents from "@/collection/components/CaseComponents";
import GlobalMessages from "@/collection/components/GlobalMessages";
import Approvals from "@/collection/components/Approvals";
import OrderForms from "@/collection/components/OrderForms";
import Checklists from "@/collection/components/Checklists";
import Invoices from "@/collection/components/Invoices";
import Dispatches from "@/collection/components/Dispatches";
import ActiveCollections from "@/collection/components/ActiveCollections";
import UpdateAvailable from "@/shared/UpdateAvailable";

export default {
  name: "App",
  components: {
    NavigationDrawer,
    ComponentDrawer,
    AppBar,
    GlobalSearch,
    Search,
    CaseComponents,
    GlobalMessages,
    Approvals,
    OrderForms,
    Checklists,
    Invoices,
    Dispatches,
    ActiveCollections,
    UpdateAvailable
  },
  data: () => ({
    loading: true,
    loadingMessage: "",
    now: null
  }),
  watch: {
    isBlockLoading() {
      this.loadingMessage = this.generateLoadingMessage();
    }
  },
  methods: {
    updateClock() {
      this.now = DateTime.now().toLocaleString(DateTime.TIME_WITH_SECONDS);
    },

    generateLoadingMessage() {
      return loadingMessages[
        Math.floor(Math.random() * loadingMessages.length)
      ];
    }
  },
  computed: {
    ...mapGetters([
      "isBlockLoading",
      "currentView",
      "me",
      "collectionUID",
      "caseID"
    ])
  },
  mounted() {
    this.updateClock();
    setInterval(this.updateClock, 1000);
  }
};
</script>
