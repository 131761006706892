<template>
  <v-chip small :color="color" :outlined="outlined" :class="klass">
    <v-icon small class="mr-2">{{ icon }}</v-icon>
    {{ status }}
  </v-chip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StatusChip",
  props: ["kase"],
  computed: {
    ...mapGetters(["branding", "collection"]),
    openApproval() {
      if (!this.collection) {
        return false;
      }
      if (this.collection.approvals.length === 0) {
        return false;
      }
      if (
        this.collection.approvals.filter(approval => {
          if (this.kase.grouped_case_uids) {
            if (
              approval.case_uid !== this.kase.uid &&
              !this.kase.grouped_case_uids.includes(approval.case_uid)
            ) {
              return false;
            }
          } else {
            if (approval.case_uid !== this.kase.uid) {
              return false;
            }
          }
          return !approval.approved_at && !approval.cancelled_at;
        }).length > 0
      ) {
        return true;
      }
      return false;
    },
    status() {
      if (!this.kase) {
        return;
      }
      if (this.openApproval) {
        return "Approval Required";
      }
      if (this.kase.dispatched) {
        return "Dispatched";
      }
      if (this.kase.cancelled) {
        return "Cancelled";
      }
      return this.kase.status;
    },
    color() {
      if (this.status === "Approval Required") {
        return "warning";
      }
      if (this.status === "Dispatched") {
        return "success";
      }
      if (this.status === "Completed") {
        return "success";
      }
      if (this.status === "Cancelled") {
        return "error";
      }
      return "primary";
    },
    icon() {
      if (this.status === "Approval Required") {
        return "mdi-alert";
      }
      if (this.status === "Dispatched") {
        return "mdi-truck";
      }
      if (this.status === "Completed") {
        return "mdi-check";
      }
      if (this.status === "Cancelled") {
        return "mdi-cancel";
      }
      return "mdi-progress-clock";
    },
    outlined() {
      return this.status !== "Approval Required";
    },
    klass() {
      if (this.status !== "Approval Required") {
        return "";
      }
      if (this.branding === "osteon") {
        return "pulsing-light";
      }
      return "pulsing-dark";
    }
  }
};
</script>

<style>
.pulsing-light {
  animation: pulse-light 1s infinite;
}

.pulsing-dark {
  animation: pulse-dark 1s infinite;
}

@keyframes pulse-light {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse-dark {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
</style>
