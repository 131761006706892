<template>
  <v-menu
    light
    max-width="600"
    min-width="600"
    max-height="500"
    open-on-hover
    class="overflow-y-auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="color" v-on="on" v-bind="attrs">
        {{ icon }}
      </v-icon>
    </template>
    <v-card class="rounded-lg">
      <v-toolbar dense dark>
        <v-spacer />
        <v-btn small color="primary" class="rounded-lg" @click="addNote()">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add Note
        </v-btn>
      </v-toolbar>
      <v-list>
        <v-list-item v-if="!hasNotes">
          <v-list-item-content>
            <v-row>
              <v-col class="text-center mb-5">
                <v-icon class="my-5">mdi-sleep</v-icon>
                <br />
                No attached notes
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(note, index) in notes" :key="index">
          <v-list-item-content>
            <Message :full-width="true" :message="note" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import Message from "@/shared/Messages/Message";

export default {
  name: "NotePopup",
  props: ["collectionUid", "notes"],
  components: {
    Message
  },
  computed: {
    hasNotes() {
      if (!this.notes) {
        return false;
      }
      return this.notes.length > 0;
    },
    icon() {
      if (this.hasNotes) {
        return "mdi-note";
      }
      return "mdi-note-outline";
    },
    color() {
      if (this.hasNotes) {
        return "warning";
      }
      return null;
    }
  },
  methods: {
    addNote() {
      window.open(
        `/static/v2/collection.html?uid=${this.collectionUid}&view=messages&messageTypeCode=CUSTOMER_SERVICE_NOTE`,
        "_blank"
      );
    }
  }
};
</script>
