<template>
  <span v-if="cases && cases.length > 0">
    <v-btn small class="rounded-lg" @click="sendToOsteonCAM()">
      <v-icon small class="mr-2">mdi-folder-upload</v-icon>
      Osteon CAM
    </v-btn>
    <InstallPopup v-model="triggered" />
  </span>
</template>

<script>
import InstallPopup from "@/shared/InstallPopup";
import startApp from "@/shared/oep_helper";

export default {
  name: "OsteonCAM",
  components: {
    InstallPopup
  },
  props: ["cases"],
  data() {
    return {
      triggered: false
    };
  },
  computed: {
    folderPaths() {
      return this.cases
        .filter(kase => !!kase.folder_path)
        .map(kase => {
          if (kase.new_folder_structure) {
            return `\\\\corp.osteonmedical.com\\cases\\${kase.folder_path}\\`;
          } else {
            return `\\\\corp.osteonmedical.com\\files\\Current Cases\\${kase.folder_path}\\`;
          }
        })
        .join(";");
    }
  },
  methods: {
    sendToOsteonCAM() {
      this.$axios
        .post("/api/v2/key_value/", {
          value: `production-queue:${this.folderPaths}`
        })
        .then(response => {
          startApp(`fetch-instruction:${response.data.key}`);
          this.triggered = true;
        });
    }
  }
};
</script>
