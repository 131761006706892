<template>
  <v-form @submit.prevent="triggerSearch()">
    <v-text-field
      v-model="keyword"
      outlined
      dark
      dense
      clearable
      class="rounded-lg"
      prepend-icon="mdi-magnify"
      label="Case Search"
    />
  </v-form>
</template>

<script>
export default {
  name: "GlobalSearch",
  data() {
    return {
      keyword: null
    };
  },
  methods: {
    triggerSearch() {
      if (this.keyword && this.keyword.length > 2) {
        document.location = `/static/v2/collection.html?search=${encodeURI(
          this.keyword
        )}`;
      }
    }
  }
};
</script>
