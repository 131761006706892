<template>
  <v-row justify="center">
    <v-dialog v-model="displayProgressModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayProgressModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Progress Case</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="progressForm" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="kase.status_id"
                  dense
                  outlined
                  :items="statusItems"
                  :rules="[statusValidation]"
                  label="Status"
                >
                  <template v-slot:item="data">
                    <div class="ma-3">
                      <strong>{{ data.item.text }}</strong
                      ><br />
                      <small v-if="data.item.parent_name">
                        <v-icon small>mdi-account-group</v-icon>
                        {{ data.item.parent_name }}
                      </small>
                    </div>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  class="rounded-lg"
                  v-if="isDelegated"
                  v-model="kase.delegated_to_id"
                  dense
                  outlined
                  clearable
                  :items="employeeItems"
                  label="Delegated To"
                >
                  <template v-slot:item="data">
                    <div class="ma-3">
                      <strong>
                        {{ data.item.text }}
                      </strong>
                      <br />
                      <small>
                        <span class="mr-2" v-if="data.item.branch">
                          <v-icon small>mdi-factory</v-icon>
                          {{ data.item.branch }}
                        </span>
                        <span v-if="data.item.all_departments.length > 0">
                          <v-icon small>mdi-account-group</v-icon>
                          {{ data.item.all_departments.join(", ") }}
                        </span>
                      </small>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="displayProgressModal">
              <v-col>
                <QCChecklist
                  :case-uid="kase.uid"
                  :department="initialDepartment"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  class="rounded-lg"
                  :disabled="submitting"
                  color="success"
                  @click="updateCase"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="dialog"
      @confirmation="setConfirmation"
      :title="title"
      :message="message"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import departments from "@/shared/departments";
import ConfirmDialog from "@/shared/ConfirmDialog";
import QCChecklist from '@/shared/QCChecklist'

export default {
  name: "ProgressModal",
  components: {
    ConfirmDialog,
    QCChecklist
  },
  watch: {
    department(newValue, oldValue) {
      if (oldValue === undefined) {
        this.initialDepartment = newValue;
      }
    },
    caseStatus(newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      const oldStatus = this.statuses.find(status => status.id === oldValue);
      if (
        newValue &&
        oldStatus.parent_name === "IN PRODUCTION" &&
        newValue !== oldValue
      ) {
        if (
          this.kase.machine_project &&
          this.kase.machine_project.status &&
          this.kase.machine_project.status !== "Complete"
        ) {
          this.openConfirmationDialog();
        }
      }
    },
    confirmation(value) {
      if (!value) {
        this.resetValues();
      }
    }
  },
  data() {
    return {
      submitting: false,
      dialog: false,
      message: "",
      title: "",
      confirmation: null,
      initialDepartment: null
    };
  },
  computed: {
    ...mapGetters([
      "progressModal",
      "collection",
      "kase",
      "statuses",
      "employees",
      "me",
      "branches"
    ]),
    selectedStatus() {
      return (
        this.statuses.find(status => status.id === this.kase.status_id) || {}
      );
    },
    isDelegated() {
      return (
        this.selectedStatusInProduction ||
        this.selectedStatusInDesign ||
        this.selectedStatusInFinishing
      );
    },
    selectedStatusInProduction() {
      if (!this.selectedStatus) {
        return false;
      }
      return this.selectedStatus.in_production;
    },
    selectedStatusInDesign() {
      if (!this.selectedStatus) {
        return false;
      }
      return this.selectedStatus.in_design;
    },
    selectedStatusInFinishing() {
      if (!this.selectedStatus) {
        return false;
      }
      return this.selectedStatus.in_finishing;
    },
    statusValidation() {
      if (!this.kase.status_id) {
        return "Must be selected";
      }
      return true;
    },
    metrologyCompleteValidation() {
      if (!!this.dateMetrologyComplete && !this.kase.metrology_user_id) {
        return "Must select measured by employee";
      } else if (!!this.kase.metrology_user_id && !this.dateMetrologyComplete) {
        return "Must select metrology complete";
      }
      return true;
    },
    designCompleteValidation() {
      if (!!this.dateDesignComplete && !this.kase.design_user_id) {
        return "Must select designed by employee";
      } else if (!!this.kase.design_user_id && !this.dateDesignComplete) {
        return "Must select design complete";
      }
      return true;
    },
    machineCompleteValidation() {
      if (!!this.dateMachiningComplete && !this.kase.machine_user_id) {
        return "Must select machined by employee";
      } else if (!!this.kase.machine_user_id && !this.dateMachiningComplete) {
        return "Must select machining complete";
      }
      return true;
    },
    displayProgressModal: {
      get() {
        return this.progressModal;
      },
      set(value) {
        if (!value) {
          this.triggerReload();
          this.hideProgressModal();
        }
      }
    },
    statusItems() {
      return [...this.statuses]
        .sort((a, b) => {
          if (a.parent_name === b.parent_name) {
            return a.name.localeCompare(b.name);
          }
          return (a.parent_name || "Z").localeCompare(b.parent_name || "Z");
        })
        .map(status => {
          return {
            text: status.name,
            value: status.id,
            parent_name: status.parent_name
          };
        });
    },
    allEmployeeItems() {
      return [...this.employees]
        .filter(employee => {
          return employee.name.trim() !== "";
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(employee => {
          return {
            text: employee.name,
            value: employee.id,
            branch: employee.main_branches[0],
            department: employee.department
          };
        });
    },
    employeeItems() {
      let employees = [...this.employees]
        .filter(employee => {
          return employee.name.trim() !== "";
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      if (this.selectedStatusInDesign) {
        employees = this.employees.filter(employee =>
          employee.all_departments.includes("DESIGN")
        );
      } else if (this.selectedStatusInProduction) {
        employees = this.employees.filter(employee =>
          employee.all_departments.includes("MACHINING")
        );
      } else if (this.selectedStatusInFinishing) {
        employees = this.employees.filter(employee =>
          employee.all_departments.includes("POLISHING")
        );
      }
      return employees.map(employee => {
        return {
          text: employee.name,
          value: employee.id,
          branch: employee.main_branches[0],
          department: employee.department,
          all_departments: employee.all_departments
        };
      });
    },
    department() {
      const statusDepartment = this.statuses.find(
        status => status.id === this.kase.status_id
      );
      if (!statusDepartment) {
        return;
      }
      if (statusDepartment.name === "ORDERED") {
        return this.departments?.find(
          department => department.value === "ADMIN"
        );
      }
      if (statusDepartment.in_design) {
        return this.departments?.find(
          department => department.value === "DESIGN"
        );
      }
      if (statusDepartment.in_finishing) {
        return this.departments?.find(
          department => department.value === "POLISHING"
        );
      }
      if (statusDepartment.in_production) {
        return this.departments?.find(
          department => department.value === "MACHINING"
        );
      }
      return "";
    },
    caseStatus() {
      return this.kase.status_id;
    }
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "hideProgressModal",
      "triggerReload"
    ]),
    async updateCase() {
      if (!this.$refs.progressForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "edit_case",
          case: {
            fulfillment_branch_id: this.collection.fulfillment_branch_id,
            forceUpdateStatus: this.confirmation,
            ...this.kase
          }
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.displayProgressModal = false;
          this.initialDepartment = this.department;
          this.submitting = false;
        });
    },
    setConfirmation(value) {
      this.confirmation = value;
    },
    openConfirmationDialog() {
      this.title = "WARNING";
      this.message =
        "This case is currently in CAM Programming or Machining- if you click confirm, the case will be removed from production. Are you sure this is okay?";
      this.dialog = true;
    },
    resetValues() {
      this.confirmation = null;
      this.displayProgressModal = false;
    }
  },
  created() {
    this.departments = departments;
  }
};
</script>
