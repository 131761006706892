<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-row class="mb-2">
        <v-col sm="12" lg="9">
          <v-icon class="mr-2">mdi-cog</v-icon>
          Technical Information
        </v-col>
        <v-col sm="12" lg="3" class="text-right">
          <v-btn class="rounded-lg" v-if="!edit" small @click="edit = true">
            <v-icon small>mdi-lock</v-icon>
          </v-btn>
          <v-btn class="rounded-lg" v-if="edit" small @click="edit = false">
            <v-icon small>mdi-lock-open</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!edit">
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Job Type</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.job_type }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Material Type</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.material_type }} {{ kase.material }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Material Batch Number</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.material_batch_number }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Thickness (mm)</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.material_thickness }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Dentate</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.dentate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Attachment</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <ul v-for="(attachment, index) in kase.attachments" :key="index">
            <li>{{ attachment.quantity }}x {{ attachment.name }}</li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Analog</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <ul v-for="(analog, index) in kase.analogs" :key="index">
            <li>
              {{ analog.quantity }}x {{ analog.name }} -
              {{ analog.description }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Scan Gauge Kit</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          {{ kase.scan_gauge_kit_display }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="edit">
      <v-form ref="technicalInfoForm" lazy-validation>
        <v-autocomplete
          v-model="jobTypeID"
          :rules="[jobTypeValidation]"
          dense
          outlined
          :items="jobTypeItems"
          class="rounded-lg"
          label="Job Type"
        />
        <v-autocomplete
          v-model="materialTypeID"
          dense
          outlined
          :items="materialTypeItems"
          class="rounded-lg"
          label="Material Type"
        />
        <v-autocomplete
          v-model="materialID"
          dense
          outlined
          :items="materialItems"
          class="rounded-lg"
          label="Material"
        />
        <v-text-field
          outlined
          dense
          v-model="kase.material_batch_number"
          class="rounded-lg"
          label="Material Batch Number"
        />
        <v-autocomplete
          v-model="kase.dentate"
          dense
          outlined
          :items="dentateItems"
          class="rounded-lg"
          label="Dentate"
        />
        <v-autocomplete
          v-model="kase.scan_gauge_kit_id"
          class="rounded-lg"
          dense
          outlined
          :items="scanGaugeItems"
          label="Existing Scan Gauge Kit"
        />
        <v-text-field
          v-if="kase.scan_gauge_kit_id === 0"
          v-model="kase.scan_gauge_name"
          class="rounded-lg"
          dense
          outlined
          hint="Enter Scan Gauge ID when none matches the client's existing Scan Gauge Kits"
          label="Scan Gauge ID"
        />
        <v-row>
          <v-col>
            <ComponentTypeCard
              componentType="Attachment"
              :componentItems="attachmentItems"
              :components="kase.attachments"
              :edit="edit"
              @addComponent="addComponent"
              @deleteComponent="deleteComponent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ComponentTypeCard
              componentType="Analog"
              :componentItems="analogItems"
              :components="kase.analogs"
              :edit="edit"
              @addComponent="addAnalog"
              @deleteComponent="deleteAnalog"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn class="rounded-lg" color="success" @click="updateCase()">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ComponentTypeCard from "./TechnicalInfo/ComponentTypeCard";

export default {
  name: "TechnicalInfo",
  components: {
    ComponentTypeCard
  },
  data() {
    return {
      edit: false,
      dentateItems: ["UPPER", "LOWER", "BOTH", "NONE"]
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.startEditing();
      } else {
        this.stopEditing();
      }
    }
  },
  computed: {
    ...mapGetters([
      "collection",
      "kase",
      "materials",
      "materialTypes",
      "jobTypes",
      "attachments",
      "analogs"
    ]),
    jobTypeValidation() {
      if (!this.kase.job_type_id) {
        return "Cannot be blank";
      }
      return true;
    },
    attachmentValidation() {
      if (this.kase.attachment_quantity > 0 && !this.kase.attachment_id) {
        return "Attachment type must be selected";
      }
      return true;
    },
    materialItems() {
      return this.materials.map(material => {
        return {
          text: material.name,
          value: material.id
        };
      });
    },
    materialTypeItems() {
      return this.materialTypes.map(material => {
        return {
          text: material.name,
          value: material.id
        };
      });
    },
    jobTypeItems() {
      return this.jobTypes.map(jobType => {
        return {
          text: jobType.name,
          value: jobType.id
        };
      });
    },
    attachmentItems() {
      return this.attachments.map(attachment => {
        return {
          text: attachment.name,
          value: attachment.id,
          code: attachment.code,
          name: attachment.name,
          description: attachment.description,
          disabled: attachment.is_disabled
        };
      });
    },
    analogItems() {
      return this.analogs.map(analog => {
        return {
          text: analog.name + " - " + analog.description,
          value: analog.id,
          code: analog.code,
          name: analog.name,
          description: analog.description,
          disabled: analog.is_disabled
        };
      });
    },
    scanGaugeItems() {
      return this.kase.client_gauge_kits.map(kit => {
        return {
          text: kit.kit_box_display,
          value: kit.gauge_kit_id
        };
      });
    },
    materialTypeID: {
      get() {
        return this.kase.material_type_id;
      },
      set(value) {
        this.kase.material_type_id = value;
        if (!this.kase.material_id) {
          return;
        }
        const material = this.materials.find(
          material => material.id === this.kase.material_id
        );
        if (!material) {
          return;
        }
        if (material.material_type_id !== value) {
          this.kase.material_id = null;
        }
      }
    },
    materialID: {
      get() {
        return this.kase.material_id;
      },
      set(value) {
        this.kase.material_id = value;
        const material = this.materials.find(material => material.id === value);
        if (!material) {
          return;
        }
        this.materialTypeID = material.material_type_id;
      }
    },
    jobTypeID: {
      get() {
        return this.kase.job_type_id;
      },
      set(value) {
        this.kase.job_type_id = value;
        const jobType = this.jobTypes.find(jobType => jobType.id === value);
        if (!jobType) {
          return;
        }
        this.kase.job_type_id = jobType.id;
      }
    }
  },
  methods: {
    ...mapActions([
      "startEditing",
      "stopEditing",
      "startLoading",
      "stopLoading",
      "triggerReload"
    ]),
    async updateCase() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "edit_case",
          case: {
            fulfillment_branch_id: this.collection.fulfillment_branch_id,
            ...this.kase
          }
        })
        .then(() => {
          this.edit = false;
          this.triggerReload();
          this.stopLoading();
        });
    },
    addComponent(component) {
      this.kase.attachments.push(component);
    },
    deleteComponent(component) {
      this.kase.attachments.splice(
        this.kase.attachments.findIndex(
          attachment => attachment.component_id === component.component_id
        ),
        1
      );
    },
    addAnalog(analog) {
      this.kase.analogs.push(analog);
    },
    deleteAnalog(component) {
      this.kase.analogs.splice(
        this.kase.analogs.findIndex(
          analog => analog.component_id === component.component_id
        ),
        1
      );
    }
  }
};
</script>
