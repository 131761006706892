<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card outlined v-if="kase.picking_slips" class="rounded-lg">
        <v-card-title>
          <v-icon class="mr-2">mdi-dolly</v-icon>
          Picking Slips</v-card-title
        >
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Requested Time</th>
                <th>Requested By</th>
                <th>Picked Time</th>
                <th>Picked By</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(picking_slip, index) in kase.picking_slips"
                :key="index"
                @click="showModal(picking_slip)"
              >
                <td>
                  <v-icon v-if="!picking_slip.picked_at">mdi-timer-sand</v-icon>
                  <v-icon v-if="picking_slip.picked_at">mdi-check</v-icon>
                </td>
                <td>{{ picking_slip.id }}</td>
                <td>
                  {{ picking_slip.requested_at | luxon({ output: "tz_med" }) }}
                </td>
                <td>{{ picking_slip.requested_by }}</td>
                <td>
                  {{ picking_slip.picked_at | luxon({ output: "tz_med" }) }}
                </td>
                <td>{{ picking_slip.picked_by }}</td>
              </tr>
              <tr v-if="kase.picking_slips.length === 0">
                <td colspan="6" class="text-center ma-3">
                  No Picking Slips
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-row class="mt-2">
            <v-col class="text-right">
              <v-btn
                class="rounded-lg"
                :disabled="submitting"
                @click="newPickingSlip()"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>
                New
              </v-btn>
            </v-col>
          </v-row>
          <v-dialog v-model="pickingSlipModal" width="400">
            <v-card class="rounded-lg" v-if="selectedPickingSlip">
              <v-toolbar dark>
                <v-btn icon dark @click="pickingSlipModal = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  Picking Slip Details
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-5">
                <v-card outlined class="rounded-lg">
                  <v-card-title>
                    Picking Slip ID
                  </v-card-title>
                  <v-card-text>
                    <code>{{ selectedPickingSlip.id }}</code>
                  </v-card-text>
                </v-card>
                <v-card outlined class="rounded-lg mt-5">
                  <v-card-title>
                    Manufactured Part
                  </v-card-title>
                  <v-simple-table v-if="componentItems">
                    <thead>
                      <tr>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(part, index) in componentItems" :key="index">
                        <td>
                          <small>{{ part.description }}</small>
                        </td>
                      </tr>
                      <tr v-if="componentItems.length === 0">
                        <td colspan="2" class="text-center ma-3">
                          No Manufactured Part Supplied
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
                <v-card outlined class="rounded-lg mt-5">
                  <v-card-title>Components</v-card-title>
                  <v-simple-table v-if="screwItems">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Quantity</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(part, index) in screwItems" :key="index">
                        <td>{{ part.code }}</td>
                        <td>{{ part.quantity }}</td>
                        <td>{{ part.type.toUpperCase() }}</td>
                      </tr>
                      <tr v-if="screwItems.length === 0">
                        <td colspan="2" class="text-center ma-3">
                          No Components Requested
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
                <v-card outlined class="rounded-lg mt-5">
                  <v-card-title>Lot Numbers</v-card-title>
                  <v-simple-table
                    v-if="
                      selectedPickingSlip && selectedPickingSlip.lot_numbers
                    "
                  >
                    <tbody>
                      <tr v-for="(number, index) in lotNumbers" :key="index">
                        <td>{{ number }}</td>
                      </tr>
                      <tr v-if="lotNumbers.length === 0">
                        <td class="text-center ma-3">
                          No Recorded Lot Numbers
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PickingSlips",
  data() {
    return {
      submitting: false,
      pickingSlipModal: false,
      selectedPickingSlip: null
    };
  },
  computed: {
    ...mapGetters(["kase"]),
    screwItems() {
      if (!this.selectedPickingSlip) {
        return [];
      }
      return this.selectedPickingSlip.pick_items.filter(item => !!item.code);
    },
    componentItems() {
      if (!this.selectedPickingSlip) {
        return [];
      }
      return this.selectedPickingSlip.pick_items.filter(
        item => !!item.description
      );
    },
    lotNumbers() {
      if (Array.isArray(this.selectedPickingSlip.lot_numbers)) {
        return this.selectedPickingSlip.lot_numbers;
      }
      const lotNumbers = [];
      for (const [key, value] of Object.entries(
        this.selectedPickingSlip.lot_numbers
      )) {
        if (value.lotNumbers) {
          lotNumbers.push(`${key}: ${value.lotNumbers.join(", ")}`);
        }
      }
      return lotNumbers;
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    showModal(pickingSlip) {
      this.selectedPickingSlip = pickingSlip;
      this.pickingSlipModal = true;
    },
    async newPickingSlip() {
      if (!confirm("Are you sure you want to request a new picking slip?")) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "picking_slip"
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
        });
    }
  }
};
</script>
