<template>
  <v-card outlined class="rounded-lg mb-5">
    <v-tabs v-model="departmentFilter" class="ml-1 pr-5">
      <v-tab v-for="(department, index) in departments" :key="index">
        {{ department.name }}
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
import departments from "@/shared/departments";

export default {
  name: "DepartmentFilter",
  props: ["value"],
  watch: {
    departmentFilter(value) {
      this.$emit("input", this.departments[value]);
    }
  },
  data() {
    return {
      departmentFilter: 0
    };
  },
  created() {
    this.departments = departments;
  },
  mounted() {
    this.$emit("input", this.departments[this.departmentFilter]);
  }
};
</script>
