<template>
  <v-row justify="center">
    <v-dialog v-model="displayModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Case Component</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form lazy-validation ref="addForm">
            <v-autocomplete
              class="rounded-lg"
              v-model="jobTypeID"
              :rules="[jobTypeValidation]"
              dense
              outlined
              :items="jobTypeItems"
              label="Job Type"
            />
            <v-autocomplete
              v-model="dentate"
              class="rounded-lg"
              :rules="[dentateValidator]"
              dense
              outlined
              :items="dentateItems"
              label="Dentate"
            />
            <v-text-field
              class="rounded-lg"
              v-model="datePromised"
              dense
              outlined
              label="Date Promised"
              type="date"
            />
            <v-card v-if="schedule || loading" outlined class="mb-5">
              <div class="text-center">
                <v-progress-circular
                  v-if="loading"
                  :size="256"
                  color="#147BD1"
                  indeterminate
                >
                  Loading
                </v-progress-circular>
              </div>
              <v-card-text v-if="schedule && !loading">
                <CaseSchedule :schedule="schedule" />
              </v-card-text>
            </v-card>
            <v-autocomplete
              class="rounded-lg"
              v-model="statusID"
              dense
              outlined
              :items="statusItems"
              :rules="[statusValidation]"
              label="Status"
            >
              <template v-slot:item="data">
                <div class="ma-3">
                  <strong>{{ data.item.text }}</strong
                  ><br />
                  <small v-if="data.item.parent_name">
                    <v-icon small>mdi-account-group</v-icon>
                    {{ data.item.parent_name }}
                  </small>
                </div>
              </template>
            </v-autocomplete>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  class="rounded-lg"
                  color="success"
                  :disabled="submitting"
                  @click="save()"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CaseSchedule from "@/shared/CaseSchedule";

export default {
  name: "AddComponentModal",
  props: ["value"],
  components: {
    CaseSchedule
  },
  data() {
    return {
      defaultStatus: null,
      loading: false,
      schedule: null,
      modal: false,
      jobTypeID: null,
      dentate: null,
      datePromised: null,
      statusID: null,
      kitItemID: null,
      submitting: false,
      dentateItems: ["UPPER", "LOWER", "BOTH", "NONE"]
    };
  },
  watch: {
    value() {
      this.loading = false;
      this.schedule = null;
      this.jobTypeID = null;
      this.dentate = null;
      this.datePromised = null;
      this.statusID = this.defaultStatus;
      this.kitItemID = null;
    },
    jobTypeID(value) {
      if (!value) {
        return;
      }
      this.fetchSchedule();
    },
    datePromised() {
      this.fetchSchedule();
    }
  },
  computed: {
    ...mapGetters(["collection", "jobTypes", "statuses"]),
    displayModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    jobTypeValidation() {
      if (!this.jobTypeID) {
        return "Cannot be blank";
      }
      return true;
    },
    jobTypeItems() {
      return this.jobTypes.map(jobType => {
        return {
          text: jobType.name,
          value: jobType.id
        };
      });
    },
    dentateValidator() {
      if (!this.dentate) {
        return "Cannot be blank";
      }
      return true;
    },
    statusValidation() {
      if (!this.statusID) {
        return "Cannot be blank";
      }
      return true;
    },
    statusItems() {
      return [...this.statuses]
        .sort((a, b) => {
          if (a.parent_name === b.parent_name) {
            return a.name.localeCompare(b.name);
          }
          return (a.parent_name || "Z").localeCompare(b.parent_name || "Z");
        })
        .map(status => {
          return {
            text: status.name,
            value: status.id,
            parent_name: status.parent_name
          };
        });
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "triggerReload"]),
    async fetchSchedule() {
      if (!this.jobTypeID) {
        this.schedule = false;
        return;
      }
      this.loading = true;
      this.$axios
        .post("/api/v2/case/schedule/", {
          branch_id: this.collection.fulfillment_branch_id,
          job_type_id: this.jobTypeID,
          date_promised: this.datePromised
        })
        .then(response => {
          this.schedule = response.data;
          this.loading = false;
        });
    },
    async save() {
      if (!this.$refs.addForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/collection/${this.collection.uid}/component/`, {
          job_type_id: this.jobTypeID,
          dentate: this.dentate,
          date_promised: this.datePromised,
          status_id: this.statusID
        })
        .then(() => {
          this.stopLoading();
          this.triggerReload();
          this.submitting = false;
          this.$refs.addForm.reset();
          this.displayModal = false;
        });
    }
  },
  mounted() {
    const defaultStatus = this.statusItems.find(item => {
      return (
        item.text === "Awaiting Status Allocation" &&
        item.parent_name === "IN DESIGN"
      );
    });
    if (defaultStatus) {
      this.defaultStatus = defaultStatus.value;
      this.statusID = this.defaultStatus;
    }
  }
};
</script>
