<template>
  <div>
    <v-card outlined class="rounded-lg">
      <v-card-title>
        <v-icon class="mr-2">mdi-target</v-icon>
        Box Location Audit
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-center ma-3" v-if="audit.length === 0">
              There is no location history recorded for this case
            </div>
            <v-timeline dense v-if="audit.length > 0">
              <v-timeline-item
                fill-dot
                small
                icon="mdi-target"
                v-for="(change, index) in audit"
                :key="index"
              >
                <BinLocation
                  :bin-location="change.bin_location"
                  :bin-location-branch="change.bin_location_branch"
                />
                <i class="ml-5">{{ change.moved_at | luxonRelative }}</i>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BinLocation from "@/shared/BinLocation";

export default {
  name: "LocationAudit",
  components: {
    BinLocation
  },
  computed: {
    ...mapGetters(["collection"]),
    audit() {
      return [
        ...Array.from(
          new Set(this.collection.location_audit.map(item => item.bin_location))
        ).map(location => {
          const item = [...this.collection.location_audit]
            .sort((a, b) => a.moved_at.localeCompare(b.moved_at))
            .filter(audit => audit.bin_location === location);
          return item[0];
        })
      ].reverse();
    }
  }
};
</script>
