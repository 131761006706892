<template>
  <v-row justify="center">
    <v-dialog v-model="displayOutsourceModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayOutsourceModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            New Outsource Job
          </v-toolbar-title>
        </v-toolbar>
        <v-container fluid v-if="adapter">
          <v-btn x-small class="mt-2 rounded-lg" @click="partnerID = null">
            <v-icon x-small>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <Evident
            :partner="partnerID"
            v-if="adapter === 'EVIDENT'"
            @completed="displayOutsourceModal = false"
          />
        </v-container>
        <v-container fluid v-if="!adapter">
          <v-form ref="outsourceForm" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-autocomplete
                  class="rounded-lg"
                  v-model="partnerID"
                  :rules="[partnerIDValidation]"
                  dense
                  outlined
                  :items="partnerItems"
                  label="Partner"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  class="rounded-lg"
                  outlined
                  dense
                  type="datetime-local"
                  label="Due Time"
                  v-model="dueTime"
                  :rules="[dueTimeValidation]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="outsourceJobType"
                  :rules="[outsourceJobTypeValidation]"
                  dense
                  outlined
                  :items="jobTypes"
                  class="rounded-lg"
                  label="Job Type"
                />
              </v-col>
            </v-row>
            <v-row v-if="outsourceJobType === 'physical'">
              <v-col>
                <v-autocomplete
                  v-model="returningBranch"
                  :rules="[returningbranchValidation]"
                  dense
                  outlined
                  :items="branchItems"
                  class="rounded-lg"
                  label="Returning Branch"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  class="rounded-lg"
                  v-model="additionalRecipients"
                  outlined
                  dense
                  label="Additional Recipients"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  class="rounded-lg"
                  v-model="note"
                  :rules="[noteValidation]"
                  outlined
                  dense
                  label="Note"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  class="rounded-lg"
                  :disabled="submitting"
                  color="success"
                  @click="startOutsource()"
                >
                  <v-icon class="mr-2">mdi-send</v-icon>
                  Start
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import Evident from "./Adapters/Evident";

export default {
  name: "OutsourceModal",
  components: {
    Evident
  },
  data() {
    return {
      submitting: false,
      partnerID: null,
      dueTime: null,
      additionalRecipients: null,
      uploadNotRequired: false,
      note: null,
      outsourceJobType: "digital",
      jobTypes: [
        { text: "Digital Job", value: "digital" },
        { text: "Physical Job", value: "physical" }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "partners",
      "kase",
      "branches",
      "collection",
      "outsourceModal"
    ]),
    dueTimeWithZone() {
      if (!this.dueTime) {
        return;
      }
      return DateTime.fromISO(this.dueTime).toISO();
    },
    partnerIDValidation() {
      if (!this.partnerID) {
        return "Must be selected";
      }
      return true;
    },
    outsourceJobTypeValidation() {
      if (!this.outsourceJobType) {
        return "Must be selected";
      }
      return true;
    },
    returningbranchValidation() {
      if (this.outsourceJobType === "physical" && !this.returningBranch) {
        return "Must be selected";
      }
      return true;
    },
    dueTimeValidation() {
      if (!this.dueTime) {
        return "Must be selected";
      }
      if (DateTime.now() > new Date(this.dueTime)) {
        return "Cannot be in the past";
      }
      return true;
    },
    noteValidation() {
      if (!this.note) {
        return "Must be provided";
      }
      return true;
    },
    displayOutsourceModal: {
      get() {
        return this.outsourceModal;
      },
      set(value) {
        if (!value) {
          this.triggerReload();
          this.hideOutsourceModal();
        }
      }
    },
    partnerItems() {
      return this.partners.map(partner => {
        return {
          text: partner.name,
          value: partner.id
        };
      });
    },
    branchItems() {
      return this.branches.map(branch => {
        return {
          text: branch.name,
          value: branch.id
        };
      });
    },
    returningBranch: {
      get() {
        return this.collection.fulfillment_branch_id;
      },
      set(value) {
        if (!value) {
          return;
        }
        this.collection.fulfillment_branch_id = value;
      }
    },
    adapter() {
      if (!this.partnerID) {
        return;
      }
      return this.partners.find(partner => partner.id === this.partnerID)
        .adapter_type;
    }
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "triggerReload",
      "hideOutsourceModal"
    ]),
    async startOutsource() {
      if (!this.kase.in_design) {
        if (
          !confirm(
            "This case is not in design - if you start this outsource job, the case will be moved back to the design system. Are you sure this is okay?"
          )
        ) {
          return;
        }
      }
      if (!this.$refs.outsourceForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "start_outsource",
          partner_id: this.partnerID,
          due_time: this.dueTimeWithZone,
          additional_recipients: this.additionalRecipients,
          job_type: this.outsourceJobType,
          returning_branch_id: this.returningBranch,
          note: this.note
        })
        .then(() => {
          this.$refs.outsourceForm.reset();
          this.triggerReload();
          this.stopLoading();
          this.submitting = false;
          this.displayOutsourceModal = false;
        });
    }
  }
};
</script>

<style>
.v-picker__title {
  border-radius: 0px !important;
}
</style>
