<template>
  <v-dialog v-model="viewFileModal" max-width="1000">
    <v-card v-if="value" class="rounded-lg">
      <v-toolbar dark>
        <v-btn icon dark @click="viewFileModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ value.file_name | sanitizeFilename }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn v-if="filePath" icon @click="downloadFile()">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid v-if="filePath">
        <v-img v-if="fileType === 'image'" :src="`${filePath}`" />
        <div v-if="fileType === 'html'" class="iframe-container">
          <iframe :src="`${filePath}`" />
        </div>
        <ModelViewer v-if="fileType === 'model'" :file-path="filePath" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModelViewer from "@/shared/ModelViewer";

export default {
  name: "FileViewModal",
  props: ["value", "type", "caseUid"],
  components: {
    ModelViewer,
  },
  data() {
    return {
      filePath: null,
    };
  },
  watch: {
    value(file) {
      if (!file) {
        return;
      }
      this.fetchFileURI();
    },
  },
  computed: {
    ...mapGetters(["kase", "collection"]),
    caseID() {
      if (this.kase.case_id) {
        return this.kase.case_id;
      }
      const kase = this.collection.cases.find(
        (kase) => kase.uid === this.caseUid
      );
      if (!kase) {
        return;
      }
      return kase.case_id;
    },
    fileType() {
      let fileExt = this.value.filename || this.value.file_name
      fileExt = fileExt
        .toLowerCase()
        .split(".")
        .pop();
      switch (fileExt) {
        case "html":
          return "html";
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        case "ply":
        case "stl":
          return "model";
        default:
          return "download";
      }
    },
    viewFileModal: {
      get() {
        if (this.value) {
          return true;
        }
        return false;
      },
      set(value) {
        this.$emit("input", value);
        this.filePath = null;
      },
    },
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    async fetchFileURI() {
      if (this.type === "message_attachment") {
        return this.fetchAttachmentURI();
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.caseID}/`, {
          action: "file_link",
          type: this.type,
          file_id: this.value.id,
        })
        .then((response) => {
          this.filePath = response.data;
          if (this.fileType === "download") {
            this.downloadFile();
          }
          this.stopLoading();
        });
    },
    async fetchAttachmentURI() {
      this.startLoading();
      this.$axios
        .get(`/api/v2/messages/attachments/${this.value.uid}/`)
        .then((response) => {
          this.filePath = response.data.url;
          if (this.fileType === "download") {
            this.downloadFile();
          }
          this.stopLoading();
        });
    },
    downloadFile() {
      window.open(this.filePath, "_blank");
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
