<template>
  <v-row class="py-2">
    <v-col sm="12" lg="6">
      <div class="mx-5">
        <small>
          <v-icon small class="mx-1">mdi-eye</v-icon>
          Clients -
          <strong>Approval Request</strong>
          -
          <i>
            {{ approvalCase.job_type }}
            ({{ approvalCase.collection_reference }})
          </i>
        </small>
      </div>
      <v-card elevation="0">
        <v-card-text class="py-0 my-0">
          <v-chip
            :outlined="status !== 'Pending'"
            dark
            color="teal"
            class="pa-2 text-left"
            style="height: auto; min-width: 50%;"
          >
            <span style="white-space: pre-wrap">
              <h3 class="my-3">{{ toTitleCase(approval.approval_type) }}</h3>
              <p>
                Approval Due:
                <br />
                <v-icon class="mr-2">mdi-alarm</v-icon>
                <i>{{
                  approval.approval_due_at | luxon({ output: "tz_med" })
                }}</i>
              </p>
              <p>
                Status:
                <br />
                <v-icon v-if="status === 'Approved'" class="mr-2"
                  >mdi-check</v-icon
                >
                <v-icon v-if="status === 'Pending'" class="mr-2"
                  >mdi-account-clock</v-icon
                >
                <i>{{ status }}</i>
              </p>
              <span v-if="status === 'Approved'">
                <p>
                  Approved At:
                  <br />
                  <v-icon class="mr-2">mdi-clock</v-icon>
                  <i>{{
                    approval.approved_at | luxon({ output: "tz_med" })
                  }}</i>
                </p>
                <p>
                  Approved By:
                  <br />
                  <v-icon class="mr-2">mdi-account</v-icon>
                  <i>{{ approval.approved_by }}</i>
                </p>
              </span>
            </span>
          </v-chip>
        </v-card-text>
        <v-card-actions class="pt-1 mt-0 px-5" v-if="status === 'Pending'">
          <v-btn
            small
            outlined
            color="primary"
            class="rounded-lg"
            @click="openMessaging()"
          >
            <v-icon small class="mr-2">mdi-message</v-icon>
            Message
          </v-btn>
          <v-btn
            small
            outlined
            color="success"
            class="rounded-lg"
            @click="triggerApprove()"
          >
            <v-icon small class="mr-2">mdi-thumb-up</v-icon>
            Approve
          </v-btn>
          <v-btn
            small
            outlined
            color="error"
            class="rounded-lg"
            @click="triggerCancellation()"
          >
            <v-icon small class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="mx-5">
        <small>
          <v-icon small>
            mdi-account
          </v-icon>
          {{ approval.created_by || "Unknown" }}
          -
          {{ approval.created_at | luxon({ output: "tz_med" }) }}
          <span v-if="approval" class="ml-5">
            <a @click="openMessaging()">
              Show Approval Thread
            </a>
          </span>
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Approval",
  props: ["approval", "collection"],
  computed: {
    status() {
      if (this.approval.cancelled_at) {
        return "Cancelled";
      }
      if (this.approval.approved_at) {
        return "Approved";
      }
      return "Pending";
    },
    approvalCase() {
      return this.collection.cases.find(
        kase => kase.uid === this.approval.case_uid
      );
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    openMessaging() {
      this.$emit("filter", {
        approvalFilter: [
          `${this.toTitleCase(this.approval.approval_type)} - ${
            this.approvalCase.job_type
          }`
        ]
      });
    },
    async triggerApprove() {
      if (
        !confirm("Are you sure you want to approve on behalf of the client?")
      ) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/approval/${this.approval.uid}/`, {
          action: "approve"
        })
        .then(() => {
          this.approval.approved_at = new Date().toISOString();
          this.approval.approved_by = "You";
          this.$emit("updated", this.approval);
          this.stopLoading();
        });
    },
    async triggerCancellation() {
      if (!confirm("Are you sure you want to cancel this approval request?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .patch(`/api/v2/approval/${this.approval.uid}/`, {
          action: "cancel"
        })
        .then(() => {
          this.approval.cancelled_at = new Date().toISOString();
          this.approval.cancelled_by = "You";
          this.$emit("updated", this.approval);
          this.stopLoading();
        });
    },
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    }
  }
};
</script>
