<template>
  <v-row justify="center">
    <v-dialog v-model="displayChangeTimesModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayChangeTimesModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Modify Case Times</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form class="mt-2" ref="changeTimesForm" lazy-validation>
            <v-text-field
              class="rounded-lg"
              dense
              outlined
              :rules="[startTimeValidation]"
              v-model="newStartTime"
              label="New Start Time"
              type="datetime-local"
            />
            <v-text-field
              class="rounded-lg"
              clearable
              dense
              outlined
              v-model="newPromiseDate"
              label="New Promise Date (Optional)"
              type="date"
            />
            <v-row>
              <v-col sm="12" class="text-right">
                <v-btn
                  class="rounded-lg"
                  :disabled="submitting"
                  color="success"
                  @click="updateTimes()"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ChangeTimesModal",
  props: ["value"],
  data() {
    return {
      submitting: false,
      newStartTime: null,
      newPromiseDate: null
    };
  },
  watch: {
    displayChangeTimesModal(value) {
      if (!value) {
        return;
      }
      if (this.kase.date_received) {
        this.newStartTime = DateTime.fromISO(this.kase.date_received).toFormat(
          "yyyy-MM-dd'T'HH:mm"
        );
      }
      if (this.kase.date_promised) {
        this.newPromiseDate = DateTime.fromISO(
          this.kase.date_promised
        ).toFormat("yyyy-MM-dd");
      }
    }
  },
  computed: {
    ...mapGetters(["kase"]),
    displayChangeTimesModal: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$refs.changeTimesForm.reset();
          this.$emit("input", value);
        }
      }
    },
    newStartTimeWithZone() {
      if (!this.newStartTime) {
        return;
      }
      return DateTime.fromISO(this.newStartTime).toISO();
    },
    startTimeValidation() {
      if (!this.newStartTime) {
        return "Must be selected";
      }
      return true;
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    async updateTimes() {
      if (!this.$refs.changeTimesForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "update_times",
          new_start_time: this.newStartTimeWithZone,
          new_promise_date: this.newPromiseDate
        })
        .then(() => {
          this.stopLoading();
          this.displayChangeTimesModal = false;
          this.submitting = false;
          this.$emit("changed");
        });
    }
  }
};
</script>
