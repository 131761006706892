<template>
  <li v-if="!hide && value">
    <strong>{{ attribute | snakeToTitle }}:</strong>
    <span v-if="cleanValue">
      {{ cleanValue }}
    </span>
    <span v-if="!cleanValue">
      <ul>
        <Attribute
          v-for="(item, key) in value"
          :key="key"
          :attribute="key"
          :value="item"
        />
      </ul>
    </span>
  </li>
</template>

<script>
import Attribute from "./Attribute";
import hiddenAttributes from "./hidden_attributes";

export default {
  name: "Attribute",
  props: ["attribute", "value"],
  components: {
    Attribute
  },
  computed: {
    hide() {
      if (hiddenAttributes.includes(this.attribute)) {
        return true;
      }
      return false;
    },
    cleanValue() {
      switch (typeof this.value) {
        case "string":
        case "number":
          return this.value;
        case "boolean":
          return this.value ? "Yes" : "No";
        default:
          return false;
      }
    }
  },
  filters: {
    snakeToTitle(str) {
      str = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      return str
        .split("_")
        .map(function(item) {
          return item.charAt(0).toUpperCase() + item.substring(1);
        })
        .join(" ");
    }
  }
};
</script>
