<template>
  <div class="pb-5">
    <v-form @submit.prevent="search()">
      <v-text-field
        v-model="keyword"
        :rules="rules"
        class="rounded-lg"
        clearable
        outlined
        dense
        append-outer-icon="mdi-magnify"
        :error-messages="errors"
        @click:append-outer="search()"
        @click:clear="searchResponse = null"
        :label="`Search ${type} Client`"
      />
    </v-form>
    <v-row v-if="searchResponse">
      <v-col>
        <v-simple-table class="pb-5">
          <thead>
            <tr>
              <th>Client ID</th>
              <th>Country / Region</th>
              <th>Name</th>
              <th>Patients</th>
              <th>Cases</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(result, index) in searchResponse"
              :key="index"
              style="cursor: pointer"
              @click="selectClient(result)"
            >
              <td>{{ result.id }}</td>
              <td>
                <CountryFlags
                  :country="result.country"
                  size="normal"
                  class="mr-1"
                />
              </td>
              <td>
                <strong>{{ result.name }}</strong>
              </td>
              <td>
                <v-chip dark small color="success">
                  <v-icon class="mr-2">mdi-account-multiple</v-icon>
                  {{ result.patient_count }}
                </v-chip>
              </td>
              <td>
                <v-chip dark small color="#147BD1">
                  <v-icon class="mr-2">mdi-inbox</v-icon>
                  {{ result.case_count }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="searchResponse.length === 0">
              <td colspan="5" class="text-center pa-5">
                <v-icon>mdi-emoticon-sad</v-icon>
                We found nothing
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CountryFlags from "@/shared/CountryFlags";

export default {
  name: "ClientSearch",
  props: ["value", "type", "rules", "errors"],
  components: {
    CountryFlags
  },
  data() {
    return {
      loading: false,
      keyword: null,
      searchResponse: null
    };
  },
  methods: {
    async search() {
      this.loading = true;
      this.$axios
        .post("/api/v2/client/search/", { keyword: this.keyword })
        .then(response => {
          this.searchResponse = response.data;
          this.loading = false;
        });
    },
    selectClient(client) {
      this.$emit("input", client);
    }
  }
};
</script>
