var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"font-italic",attrs:{"cols":"auto"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-5"},[_c('small',{staticClass:"mr-2"},[(_vm.type === 'email')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-email")]):_vm._e(),(_vm.type === 'mobile')?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-cellphone-message")]):_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.toTitleCase(_vm.communications.communication_type)))]),(_vm.type === 'email')?_c('span',[_vm._v(" email")]):_vm._e(),(_vm.type === 'mobile')?_c('span',[_vm._v(" notification")]):_vm._e(),_vm._v(" sent - "+_vm._s(_vm._f("luxon")(_vm.communications.created_at,{ output: "tz_med" }))+" "),(
                _vm.caseDescriptionFromUID(
                  _vm.communications.case_uid,
                  _vm.communications.case_collection_uid
                )
              )?_c('span',[_vm._v(" - "),(_vm.caseLink)?_c('a',{on:{"click":function($event){return _vm.openCase(
                    _vm.communications.case_uid,
                    _vm.communications.case_collection_uid
                  )}}},[_vm._v(" "+_vm._s(_vm.caseDescriptionFromUID( _vm.communications.case_uid, _vm.communications.case_collection_uid ))+" ")]):_vm._e(),(!_vm.caseLink)?_c('span',[_vm._v(" "+_vm._s(_vm.caseDescriptionFromUID( _vm.communications.case_uid, _vm.communications.case_collection_uid ))+" ")]):_vm._e()]):_vm._e()],1)]),(_vm.communications)?_c('v-expansion-panel-content',[(_vm.type === 'mobile')?_c('div',_vm._l((_vm.communications.response),function(communication,index){return _c('div',{key:index,staticClass:"mx-1"},[(communication.device_type === 'android')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-android ")]):_vm._e(),(communication.device_type === 'ios')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-apple-ios ")]):_vm._e(),_vm._v(" "+_vm._s(communication.identifcation)+" "),(communication.success)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1)}),0):_vm._e(),(_vm.type === 'email')?_c('div',_vm._l((_vm.communications.response),function(communication,index){return _c('div',{key:index,staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"color":"rgba(0, 0, 0, 0)","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({on:{"mouseover":function($event){return _vm.fetchEmailInfo(communication._id)},"mouseleave":function($event){return _vm.resetValues()}}},'a',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(communication.email)+" ")],1)]}}],null,true)},[(_vm.emailInfo && !_vm.error)?_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.emailInfo.email)+" ")],1),_c('v-simple-table',{staticClass:"px-5 pb-5",attrs:{"dark":"","dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Clicks")]),_c('th',[_vm._v("Opens")])])]),_c('tbody',[_c('tr',[_c('td',[_c('small',[_vm._v(_vm._s(_vm.toTitleCase(_vm.emailInfo.state)))])]),_c('td',[_c('small',[_vm._v(_vm._s(_vm.emailInfo.clicks))])]),_c('td',[_c('small',[_vm._v(_vm._s(_vm.emailInfo.opens))])])])])])],1):_vm._e(),(_vm.error)?_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.error.error)+" ")],1)],1):_vm._e(),(!_vm.emailInfo && !_vm.error)?_c('v-card',{staticClass:"text-center pa-5",attrs:{"dark":""}},[_c('v-progress-circular',{attrs:{"size":"128","indeterminate":"","color":"white"}},[_vm._v(" Loading... ")])],1):_vm._e()],1)],1)}),0):_vm._e()]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }