<template>
  <v-row
    class="py-2"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 2000
    }"
  >
    <v-col
      sm="12"
      :offset-lg="columnOffset"
      :lg="fullWidth ? 12 : 6"
      :align="align"
    >
      <div class="mx-5">
        <small>
          <v-icon small class="mx-1">mdi-eye</v-icon>
          {{ toTitleCase(message.visibility) }} -
          <strong>{{ toTitleCase(message.message_type) }}</strong>
          <span v-if="caseDescriptionFromUID(message.case_uid)">
            -
            <i>
              <a @click="openCase()" v-if="caseLink">
                {{ caseDescriptionFromUID(message.case_uid) }}
              </a>
              <span v-if="!caseLink">
                {{ caseDescriptionFromUID(message.case_uid) }}
              </span>
            </i>
          </span>
        </small>
      </div>
      <v-card elevation="0">
        <v-card-text class="py-0 my-0">
          <v-chip
            :color="bubbleColor"
            :class="klass"
            :dark="dark"
            style="height: auto;"
          >
            <span style="white-space: pre-wrap">
              <strong v-if="viewers.length === 0">{{ message.note }}</strong>
              <span v-if="viewers.length > 0">{{ message.note }}</span>
            </span>
          </v-chip>
        </v-card-text>
        <v-card-actions class="pt-1 mt-0 px-5">
          <v-spacer v-if="align === 'right'" />
          <Attachments
            :message="message"
            v-if="message.attachments"
            class="mr-5"
          />
          <AlertingMessage
            :message="message"
            v-if="message.resolvable"
            @updated="updated"
          />
        </v-card-actions>
      </v-card>
      <div class="mx-5">
        <small>
          <span v-if="message.author_reference">
            <v-icon small>
              mdi-account
            </v-icon>
            {{ message.author_reference }}
            <span v-if="message.recipient_reference">
              to {{ message.recipient_reference }}
            </span>
            -
          </span>
          {{ message.created_at | luxon({ output: "tz_med" }) }}
          <v-tooltip bottom v-if="viewers.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <span class="ml-5" v-bind="attrs" v-on="on">
                <v-icon small>mdi-check-all</v-icon>
                Seen by {{ viewers.length }}
                <span v-if="viewers.length > 1">people</span>
                <span v-if="viewers.length === 1">person</span>
              </span>
            </template>
            <small>{{ viewers.join(", ") }}</small>
          </v-tooltip>
          <span v-if="approval" class="ml-5">
            <a @click="openMessaging()">
              Show Approval Thread
            </a>
          </span>
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Attachments from "./Actions/Attachments";
import AlertingMessage from "./Actions/AlertingMessage";

export default {
  name: "Message",
  props: [
    "message",
    "highlight",
    "collection",
    "caseLink",
    "scopeUsername",
    "readOnly",
    "fullWidth"
  ],
  data() {
    return {
      hover: false,
      messageTypes: [],
      read: false
    };
  },
  components: {
    Attachments,
    AlertingMessage
  },
  computed: {
    approval() {
      if (!this.collection) {
        return;
      }
      if (!this.collection.approvals) {
        return;
      }
      if (!this.message.approval_uid) {
        return;
      }
      return this.collection.approvals.find(
        approval => approval.uid === this.message.approval_uid
      );
    },
    kase() {
      if (!this.collection) {
        return;
      }
      if (!this.collection.cases) {
        return;
      }
      if (!this.message.case_uid) {
        return;
      }
      return this.collection.cases.find(
        kase => kase.uid === this.message.case_uid
      );
    },
    direction() {
      if (this.scopeUsername) {
        if (
          this.message.author_reference.toLowerCase() ===
          this.scopeUsername.toLowerCase()
        ) {
          return "OUTBOUND";
        }
        return "INBOUND";
      }
      return this.message.direction;
    },
    align() {
      if (this.direction === "INBOUND") {
        return "right";
      }
      return "left";
    },
    columnOffset() {
      if (this.direction === "INBOUND") {
        return 6;
      }
      return 0;
    },
    bubbleColor() {
      if (this.message.message_type === "SPECIAL_REQUEST") {
        return "success";
      }
      if (this.message.message_type === "CLIENT_NOTE") {
        return "warning";
      }
      if (this.message.message_type === "ALERTING_MESSAGE") {
        return "error";
      }
      if (this.message.message_type === "CUSTOMER_SERVICE_NOTE") {
        return "lime";
      }
      if (this.message.message_type === "SALES_MESSAGE") {
        return "purple";
      }
      if (
        this.message.message_type === "APPROVAL_MESSAGE" &&
        this.direction === "OUTBOUND"
      ) {
        return "teal";
      }
      if (this.direction === "INBOUND") {
        return "primary";
      }
      return "null";
    },
    dark() {
      if (
        this.message.message_type === "APPROVAL_MESSAGE" ||
        this.message.message_type === "SALES_MESSAGE"
      ) {
        return true;
      }
      return false;
    },
    viewers() {
      if (!this.message.read_receipts) {
        return [];
      }
      return [
        ...new Set(this.message.read_receipts.map(receipt => receipt.read_by))
      ];
    },
    klass() {
      const klass = "pa-2 text-left";
      if (!this.highlight) {
        return klass;
      }
      return `${klass} pulsing`;
    }
  },
  methods: {
    openMessaging() {
      this.$emit("filter", {
        approvalFilter: [
          `${this.toTitleCase(this.approval.approval_type)} - ${
            this.kase.job_type
          }`
        ]
      });
    },
    openCase() {
      if (!this.message.case_uid || !this.message.case_collection_uid) {
        return;
      }
      window.open(
        `/static/v2/collection.html?uid=${this.message.case_collection_uid}&cuid=${this.message.case_uid}`
      );
    },
    caseDescriptionFromUID(uid) {
      if (!this.collection) {
        return;
      }
      const kase = this.collection.cases.find(kase => kase.uid === uid);
      if (!kase) {
        return;
      }
      if (this.caseLink) {
        return `${kase.case_mkey} - ${kase.job_type}`;
      }
      return `${kase.job_type} (${kase.collection_reference})`;
    },
    messageTypeFromCode(code) {
      return this.messageTypes.find(type => type.code === code);
    },
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
    updated(message) {
      this.$emit("updated", message);
    },
    async visibilityChanged(isVisible) {
      if (!this.message.uid) {
        return;
      }
      if (this.readOnly) {
        return;
      }
      if (!isVisible || this.read) {
        return;
      }
      this.$axios
        .patch(`/api/v2/message/${this.message.uid}/`, {
          action: "read"
        })
        .then(() => {
          this.read = true;
        });
    },
    async fetchMessageTypes() {
      this.$axios.get("/api/v2/messages/types/").then(response => {
        this.messageTypes = response.data;
      });
    }
  },
  mounted() {
    this.fetchMessageTypes();
  }
};
</script>

<style>
.pulsing {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
