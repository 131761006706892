<template>
  <div>
    <v-app-bar
      elevation="0"
      outlined
      :color="toolbarColor.color"
      :dark="toolbarColor.dark"
      class="mb-5 rounded-lg"
    >
      <CountryFlags
        v-if="collection.shipping_client"
        :country="collection.shipping_client.country"
        size="big"
        class="mr-2"
      />
      <h1>
        {{ collection.collection_id }}
      </h1>
      <v-chip outlined v-if="collection.vip" class="ml-2">VIP</v-chip>
      <v-spacer />
      <h3 class="d-none d-md-flex">
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ collection.patient_reference }}
      </h3>
      <v-spacer />
      <h3 class="d-non d-md-flex" v-if="collection.shipping_client">
        <v-icon class="mr-2">mdi-hospital-box</v-icon>
        {{ collection.shipping_client.name }}
      </h3>
      <OEPHelper
        v-if="collection.new_folder_structure"
        :collection="collection"
        @installPopup="showInstall()"
        class="ml-2"
      />
    </v-app-bar>
    <InstallPopup v-model="installPopup" />
    <FileLockMonitor />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlags from "@/shared/CountryFlags";
import OEPHelper from "@/shared/OEPHelper";
import InstallPopup from "@/shared/InstallPopup";
import FileLockMonitor from "@/shared/FileLockMonitor";

export default {
  name: "CollectionHeader",
  components: {
    CountryFlags,
    OEPHelper,
    InstallPopup,
    FileLockMonitor
  },
  data() {
    return {
      installPopup: false
    };
  },
  computed: {
    ...mapGetters(["collection"]),
    toolbarColor() {
      if (this.collection.vip) {
        return { color: "blue darken-4", dark: true };
      } else {
        return { color: "", dark: false };
      }
    }
  },
  methods: {
    showInstall() {
      this.installPopup = true;
    }
  }
};
</script>
