<template>
  <v-card outlined class="rounded-lg" v-if="kase">
    <v-card-title>
      <v-icon class="mr-2">mdi-tooth</v-icon>
      Implants &amp; Teeth
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn small class="mr-2 rounded-lg" @click="cloneModal = true">
            <v-icon small class="mr-2">mdi-content-copy</v-icon>
            Clone
          </v-btn>
          <v-btn small class="rounded-lg" v-if="!edit" @click="edit = true">
            <v-icon small>mdi-lock</v-icon>
          </v-btn>
          <v-btn small class="rounded-lg" v-if="edit" @click="edit = false">
            <v-icon small>mdi-lock-open</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-card v-if="noBranch" outlined class="rounded-lg my-5">
        <v-card-text class="text-center my-5">
          <v-icon x-large color="error">mdi-alert</v-icon>
          <br />
          No Fulfillment Branch Selected
        </v-card-text>
      </v-card>
      <div class="text-center my-3" v-if="!noBranch">
        <v-progress-linear
          height="8"
          v-if="loading"
          indeterminate
          color="primary"
          class="rounded-lg"
        />
        <v-progress-linear
          height="8"
          v-if="!loading"
          value="100"
          color="success"
          class="rounded-lg"
        />
      </div>
      <div v-if="!noBranch">
        <v-row v-if="edit && !implantEdit">
          <v-col sm="12" lg="2" class="pb-0">
            <v-switch
              hide-details
              class="mt-1"
              v-model="isPontic"
              label="Pontic"
            />
          </v-col>
          <v-col sm="12" lg="10">
            <v-autocomplete
              hide-details
              v-if="!isPontic"
              v-model="selectedImplantPlatformID"
              dense
              outlined
              clearable
              :items="implantPlatformSelections"
              class="rounded-lg"
              label="Implant Platform"
            />
          </v-col>
        </v-row>
        <v-row v-if="implantPlatform && !implantEdit">
          <v-col sm="12" lg="2">
            <v-switch
              hide-details
              class="mt-1"
              v-model="useDefaultScrew"
              label="Default Screw"
            />
          </v-col>
          <v-col sm="12" lg="4">
            <v-autocomplete
              hide-details
              v-model="selectedScrewID"
              dense
              outlined
              :items="screwSelections"
              :disabled="useDefaultScrew"
              class="rounded-lg"
              label="Screw"
            />
          </v-col>
          <v-col sm="12" lg="6" class="text-right">
            <v-btn-toggle hide-details v-model="implantOptions" dense multiple>
              <v-btn class="rounded-l-lg" value="biaxial">Bi-Axial</v-btn>
              <v-btn class="rounded-r-lg" value="engaging">Engaging</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col sm="12" lg="2" v-if="implantPlatform.to_tibase">
            <v-switch
              hide-details
              class="mt-1"
              v-model="useDefaultTibase"
              label="Default Ti-Base"
            />
          </v-col>
          <v-col sm="12" lg="4" v-if="implantPlatform.to_tibase">
            <v-autocomplete
              hide-details
              v-model="selectedTibaseID"
              dense
              outlined
              :items="tibaseSelections"
              :disabled="useDefaultTibase"
              class="rounded-lg"
              label="Ti-Base"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" lg="3" class="text-center">
            <ToothPicker
              :convention="internationalToothConvention"
              :selected="teeth"
              :customColors="customColors"
              style="max-height: 350px"
              @toothClicked="triggerAddImplant"
            />
            <br />
            <v-btn-toggle v-model="internationalToothConvention" dense>
              <v-btn class="rounded-l-lg" x-small value="fdi">FDI</v-btn>
              <v-btn x-small value="palmer">Pal.</v-btn>
              <v-btn class="rounded-r-lg" x-small value="universal">Uni.</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col sm="12" lg="9">
            <v-card class="overflow-y-auto" max-height="600" elevation="0">
              <v-row
                class="ma-0"
                v-for="(implant, index) in sortedImplants"
                :key="index"
              >
                <ImplantCard
                  :branch="collection.fulfillment_branch"
                  :implant="implant"
                  :isPontic="!implant.platform"
                  :screws="screws"
                  :isEdit="edit"
                  :internationalToothConvention="internationalToothConvention"
                  @editImplant="triggerEditImplant"
                  @deleteImplant="triggerRemoveImplant"
                  @toggleEngaging="toggleEngaging"
                  @toggleBiaxial="toggleBiaxial"
                />
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-dialog v-model="implantEditModal" max-width="800">
        <v-card class="rounded-lg" v-if="implantEdit">
          <v-toolbar dark>
            <v-btn icon dark @click="implantEditModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title
              >Edit Tooth
              {{ getToothNumber(implantEdit.tooth) }} Implant</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="mt-5">
              <v-col sm="12" lg="4" class="pb-0">
                <v-switch
                  hide-details
                  class="mt-1"
                  v-model="isPontic"
                  label="Pontic"
                />
              </v-col>
              <v-col sm="12" lg="8">
                <v-autocomplete
                  hide-details
                  v-if="!isPontic"
                  v-model="selectedImplantPlatformID"
                  dense
                  outlined
                  clearable
                  :items="implantPlatformSelections"
                  class="rounded-lg"
                  label="Implant Platform"
                />
              </v-col>
            </v-row>
            <v-row v-if="implantPlatform">
              <v-col sm="12" lg="6">
                <v-switch
                  hide-details
                  class="mt-1"
                  v-model="useDefaultScrew"
                  label="Default Screw"
                />
              </v-col>
              <v-col sm="12" lg="6">
                <v-autocomplete
                  hide-details
                  v-model="selectedScrewID"
                  dense
                  outlined
                  :items="screwSelections"
                  :disabled="useDefaultScrew"
                  class="rounded-lg"
                  label="Screw"
                />
              </v-col>
              <v-col sm="12" lg="6" v-if="implantPlatform.to_tibase">
                <v-switch
                  hide-details
                  class="mt-1"
                  v-model="useDefaultTibase"
                  label="Default Ti-Base"
                />
              </v-col>
              <v-col sm="12" lg="6" v-if="implantPlatform.to_tibase">
                <v-autocomplete
                  hide-details
                  v-model="selectedTibaseID"
                  dense
                  outlined
                  :items="tibaseSelections"
                  :disabled="useDefaultTibase"
                  class="rounded-lg"
                  label="Ti-Base"
                />
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn-toggle
                  hide-details
                  v-model="implantOptions"
                  dense
                  multiple
                >
                  <v-btn class="rounded-l-lg" value="biaxial">Bi-Axial</v-btn>
                  <v-btn class="rounded-r-lg" value="engaging">Engaging</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn color="success" @click="triggerEditImplantSave()">
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="cloneModal" max-width="800">
        <v-card class="rounded-lg">
          <v-toolbar dark>
            <v-btn icon dark @click="cloneModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Clone Implants</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="collectionCases.length === 0">
              <v-col class="text-center ma-5">
                <br />
                No other components to clone from.
              </v-col>
            </v-row>
            <v-simple-table v-if="collectionCases.length > 0">
              <thead>
                <tr>
                  <th>Component ID</th>
                  <th>Job Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(related_case, index) in collectionCases"
                  :key="index"
                >
                  <td>
                    {{ related_case.case_mkey }}
                  </td>
                  <td>
                    <small>{{ related_case.job_type }}</small>
                  </td>
                  <td class="text-right">
                    <v-btn
                      small
                      color="primary"
                      class="rounded-lg"
                      @click="cloneImplants(related_case.case_id)"
                    >
                      <v-icon small class="mr-2">mdi-content-copy</v-icon>
                      Clone Implants
                    </v-btn>
                  </td>
                </tr>
                <tr
                  v-if="kase.related_cases && kase.related_cases.length === 0"
                >
                  <td colspan="3" class="text-center">
                    No Other Components
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import internationalToothNumbers from "@/shared/international_tooth_numbers";
import ToothPicker from "@/shared/ToothPicker";
import ImplantCard from "@/shared/ImplantCard.vue";

export default {
  name: "Implants",
  components: {
    ToothPicker,
    ImplantCard
  },
  data() {
    return {
      cloneModal: false,
      implantEdit: null,
      edit: false,
      loading: true,
      noBranch: false,
      internationalToothConvention: "fdi",
      implants: [],
      implantPlatforms: [],
      screws: [],
      tibases: [],
      isPontic: true,
      selectedImplantPlatformID: null,
      useDefaultScrew: true,
      selectedScrewID: null,
      useDefaultTibase: true,
      selectedTibaseID: null,
      implantOptions: [],
      fulfillmentBranch: null,
      clientCountry: null
    };
  },
  watch: {
    edit(value) {
      this.resetForm();
      if (value) {
        this.startEditing();
      } else {
        this.stopEditing();
      }
    },
    branch(value) {
      if (!value) {
        return;
      }
      this.fetchData();
    },
    kase(value) {
      if (!value) {
        return;
      }
      this.fetchData();
    },
    isPontic(value) {
      if (!value) {
        return;
      }
      this.selectedImplantPlatformID = null;
    },
    implantPlatform(value) {
      if (!value) {
        this.resetForm();
        return;
      }
      this.setFormToDefaults();
      if (!value.default_components) {
        return;
      }
      if (!value.default_components.straight_screw) {
        this.useDefaultScrew = false;
      }
      if (!value.default_components.tibase) {
        this.useDefaultTibase = false;
      }
    },
    biaxial(value) {
      if (!this.useDefaultScrew) {
        return;
      }
      if (!this.implantPlatform) {
        return;
      }
      if (!this.implantPlatform.default_components) {
        return;
      }
      if (value) {
        if (!this.implantPlatform.default_components.biaxial_screw) {
          this.useDefaultScrew = false;
          this.selectedScrewID = null;
        } else {
          this.selectedScrewID = this.implantPlatform.default_components.biaxial_screw.id;
        }
      } else {
        if (!this.implantPlatform.default_components.straight_screw) {
          this.useDefaultScrew = false;
          this.selectedScrewID = null;
        } else {
          this.selectedScrewID = this.implantPlatform.default_components.straight_screw.id;
        }
      }
    },
    useDefaultScrew(value) {
      if (!value) {
        return;
      }
      if (!this.implantPlatform) {
        return;
      }
      if (!this.implantPlatform.default_components) {
        return;
      }
      if (this.biaxial) {
        if (!this.implantPlatform.default_components.biaxial_screw) {
          this.useDefaultScrew = false;
          this.selectedScrewID = null;
        } else {
          this.selectedScrewID = this.implantPlatform.default_components.biaxial_screw.id;
        }
      } else {
        if (!this.implantPlatform.default_components.straight_screw) {
          this.useDefaultScrew = false;
          this.selectedScrewID = null;
        } else {
          this.selectedScrewID = this.implantPlatform.default_components.straight_screw.id;
        }
      }
    },
    useDefaultTibase(value) {
      if (!value) {
        return;
      }
      if (!this.implantPlatform) {
        return;
      }
      if (!this.implantPlatform.default_components) {
        return;
      }
      if (!this.implantPlatform.default_components.tibase) {
        this.useDefaultTibase = false;
        this.selectedTibaseID = null;
      } else {
        this.selectedTibaseID = this.implantPlatform.default_components.tibase.id;
      }
    }
  },
  computed: {
    ...mapGetters(["collection", "kase"]),
    implantEditModal: {
      get() {
        return !!this.implantEdit;
      },
      set(value) {
        if (value) {
          return;
        }
        this.resetForm();
        this.implantEdit = null;
      }
    },
    implantPlatformSelections() {
      return this.implantPlatforms.map(platform => {
        return {
          text: platform.name,
          value: platform.id,
          disabled: !platform.enabled
        };
      });
    },
    screwSelections() {
      return this.screws.map(screw => {
        return {
          text: screw.name,
          value: screw.id,
          disabled: !screw.enabled
        };
      });
    },
    tibaseSelections() {
      return this.tibases.map(tibase => {
        return {
          text: tibase.name,
          value: tibase.id,
          disabled: !tibase.enabled
        };
      });
    },
    implantPlatform() {
      if (!this.selectedImplantPlatformID) {
        return;
      }
      return this.implantPlatforms.find(
        platform => platform.id === this.selectedImplantPlatformID
      );
    },
    branch() {
      if (!this.collection) {
        return;
      }
      return this.collection.fulfillment_branch;
    },
    biaxial() {
      return this.implantOptions.includes("biaxial");
    },
    collectionCases() {
      if (!this.collection || !this.collection.cases) {
        return [];
      }
      return this.collection.cases.filter(kase => kase.uid !== this.kase.uid);
    },
    sortedImplants() {
      return [...this.implants]
        .sort((a, b) => a.tooth - b.tooth)
        .sort((a, b) => !!b.platform - !!a.platform);
    },
    teeth() {
      return this.implants.map(implant => implant.tooth);
    },
    customColors() {
      const customColors = {};
      this.sortedImplants.forEach(implant => {
        if (!implant.platform_id) {
          customColors[implant.tooth] = { start: "#FFFF00", end: "#FFF000" };
        }
      });
      return customColors;
    },
    formValid() {
      if (this.isPontic) {
        return true;
      }
      if (!this.selectedImplantPlatformID) {
        return false;
      }
      if (!this.useDefaultScrew && !this.selectedScrewID) {
        return false;
      }
      if (
        this.implantPlatform.to_tibase &&
        !this.useDefaultTibase &&
        !this.selectedTibaseID
      ) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions(["startEditing", "stopEditing"]),
    async cloneImplants(caseID) {
      if (
        !confirm(
          "Existing implants on this case will be removed - are you sure?"
        )
      ) {
        return;
      }
      this.loading = true;
      this.cloneModal = false;
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "clone_implants",
          target_case_id: caseID
        })
        .then(() => {
          this.fetchImplants();
        });
    },
    fetchData() {
      this.loading = true;
      if (!this.safeToFetch()) {
        this.loading = false;
        return;
      }
      const requests = [];
      if (
        this.fulfillmentBranch !== this.collection.fulfillment_branch ||
        this.clientCountry !== this.collection.shipping_client.country
      ) {
        this.fulfillmentBranch = this.collection.fulfillment_branch;
        this.clientCountry = this.collection.shipping_client.country;
        requests.push(this.fetchImplantPlatforms());
        requests.push(this.fetchScrews());
        requests.push(this.fetchTibases());
      }
      requests.push(this.fetchImplants());
      Promise.all(requests).then(() => {
        this.loading = false;
      });
    },
    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.internationalToothConvention
      ].padStart(2, "0");
    },
    resetForm() {
      this.isPontic = true;
      this.selectedImplantPlatformID = null;
      this.useDefaultScrew = true;
      this.selectedScrewID = null;
      this.useDefaultTibase = true;
      this.selectedTibaseID = null;
      this.implantOptions = [];
    },
    setFormToDefaults() {
      if (this.implantEdit) {
        if (!this.implantPlatform.default_components) {
          this.useDefaultScrew = false;
          this.useDefaultTibase = false;
          this.selectedScrewID = null;
          this.selectedTibaseID = null;
          return;
        }
        if (
          this.implantEdit.using_defaults &&
          this.implantEdit.using_defaults.screw
        ) {
          if (this.implantEdit.biaxial) {
            if (this.implantPlatform.default_components.biaxial_screw) {
              this.useDefaultScrew = true;
              this.selectedScrewID = this.implantPlatform.default_components.biaxial_screw.id;
            } else {
              this.useDefaultScrew = false;
              this.selectedScrewID = null;
            }
          } else {
            if (this.implantPlatform.default_components.straight_screw) {
              this.useDefaultScrew = true;
              this.selectedScrewID = this.implantPlatform.default_components.straight_screw.id;
            } else {
              this.useDefaultScrew = false;
              this.selectedScrewID = null;
            }
          }
        }
        if (
          this.implantEdit.using_defaults &&
          this.implantEdit.using_defaults.tibase
        ) {
          if (this.implantPlatform.default_components.tibase) {
            this.useDefaultTibase = true;
            this.selectedTibaseID = this.implantPlatforms.default_components.tibase.id;
          } else {
            this.useDefaultTibase = false;
            this.selectedTibaseID = null;
          }
        }
        return;
      }
      if (!this.implantPlatform) {
        return;
      }
      if (!this.implantPlatform.default_components) {
        return;
      }
      if (this.implantPlatform.default_components.straight_screw) {
        this.useDefaultScrew = true;
        this.selectedScrewID = this.implantPlatform.default_components.straight_screw.id;
      } else {
        this.useDefaultScrew = false;
        this.selectedScrewID = null;
      }
      if (this.implantPlatform.default_components.tibase) {
        this.useDefaultTibase = true;
        this.selectedTibaseID = this.implantPlatform.default_components.tibase.id;
      } else {
        this.useDefaultTibase = false;
        this.selectedTibaseID = null;
      }
    },
    triggerAddImplant(tooth) {
      if (!this.edit) {
        return;
      }
      if (!this.formValid) {
        return;
      }
      this.$axios
        .post(`/api/v2/case/${this.kase.uid}/implants/`, {
          tooth: tooth,
          platform_id: this.isPontic ? null : this.selectedImplantPlatformID,
          screw_id: this.selectedScrewID,
          ti_base_id: this.selectedTibaseID,
          bi_axial: this.implantOptions.includes("biaxial"),
          engaging: this.implantOptions.includes("engaging")
        })
        .then(() => {
          this.fetchImplants();
        });
    },
    triggerEditImplant(implant) {
      this.implantEdit = implant;
      this.isPontic = !implant.platform;
      if (this.isPontic) {
        return;
      }
      this.selectedImplantPlatformID = implant.platform_id;
      this.useDefaultScrew = implant.using_defaults.screw;
      if (implant.screw) {
        this.selectedScrewID = implant.screw.id;
      }
      this.useDefaultTibase = implant.using_defaults.tibase;
      if (implant.tibase) {
        this.selectedTibaseID = implant.tibase.id;
      }
      if (implant.biaxial) {
        this.implantOptions.push("biaxial");
      }
      if (implant.engaging) {
        this.implantOptions.push("engaging");
      }
    },
    triggerEditImplantSave() {
      if (!this.implantEdit) {
        return;
      }
      if (!this.formValid) {
        return;
      }
      this.$axios
        .put(`/api/v2/case/${this.kase.uid}/implants/${this.implantEdit.id}/`, {
          tooth: this.implantEdit.tooth,
          platform_id: this.isPontic ? null : this.selectedImplantPlatformID,
          screw_id: this.selectedScrewID,
          ti_base_id: this.selectedTibaseID,
          bi_axial: this.implantOptions.includes("biaxial"),
          engaging: this.implantOptions.includes("engaging")
        })
        .then(() => {
          this.fetchImplants();
          this.implantEditModal = false;
        });
    },
    triggerRemoveImplant(implant) {
      if (!this.edit) {
        return;
      }
      this.$axios
        .delete(`/api/v2/case/${this.kase.uid}/implants/${implant.id}/`)
        .then(() => {
          this.fetchImplants();
        });
    },
    toggleEngaging(implant) {
      if (!this.edit) {
        return;
      }
      this.$axios
        .patch(`/api/v2/case/${this.kase.uid}/implants/${implant.id}/`, {
          engaging: true
        })
        .then(() => {
          this.fetchImplants();
        });
    },
    toggleBiaxial(implant) {
      if (!this.edit) {
        return;
      }
      this.$axios
        .patch(`/api/v2/case/${this.kase.uid}/implants/${implant.id}/`, {
          bi_axial: true
        })
        .then(() => {
          this.fetchImplants();
        });
    },
    safeToFetch() {
      if (
        !this.collection ||
        !this.collection.uid ||
        !this.collection.fulfillment_branch ||
        !this.collection.shipping_client ||
        !this.collection.shipping_client.country ||
        !this.kase ||
        !this.kase.uid
      ) {
        if (!this.collection.fulfillment_branch) {
          this.noBranch = true;
        } else {
          this.noBranch = false;
        }
        return false;
      }
      return true;
    },
    async fetchImplantPlatforms() {
      return new Promise(resolve => {
        this.$axios
          .get(
            `/api/v2/implant_platforms/${this.fulfillmentBranch}/${this.clientCountry}/`
          )
          .then(response => {
            this.implantPlatforms = response.data;
            resolve();
          });
      });
    },
    async fetchScrews() {
      return new Promise(resolve => {
        this.$axios
          .get(
            `/api/v2/components/screws/${this.fulfillmentBranch}/${this.clientCountry}/`
          )
          .then(response => {
            this.screws = response.data;
            resolve();
          });
      });
    },
    async fetchTibases() {
      return new Promise(resolve => {
        this.$axios
          .get(
            `/api/v2/components/tibases/${this.fulfillmentBranch}/${this.clientCountry}/`
          )
          .then(response => {
            this.tibases = response.data;
            resolve();
          });
      });
    },
    async fetchImplants() {
      return new Promise(resolve => {
        this.$axios
          .get(`/api/v2/case/${this.kase.uid}/implants/`)
          .then(response => {
            this.implants = response.data;
            resolve();
          });
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
