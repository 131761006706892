import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentTab: "overview",
    portalKey: null,
    branding: "nexus",
    internationalToothConvention: "fdi",
    collection: null,
    messages: [],
    reloadTrigger: false,
    chatHighlightUID: null
  },
  getters: {
    currentTab: state => {
      return state.currentTab;
    },
    portalKey: state => {
      return state.portalKey;
    },
    branding: state => {
      return state.branding;
    },
    internationalToothConvention: state => {
      return state.internationalToothConvention;
    },
    reloadTrigger: state => {
      return state.reloadTrigger;
    },
    collection: state => {
      return state.collection;
    },
    messages: state => {
      return state.messages;
    },
    chatHighlightUID: state => {
      return state.chatHighlightUID;
    },
    dentates: (_, getters) => {
      const dentates = [];
      if (getters.casesByDentate.upper.length > 0) {
        dentates.push("UPPER");
      }
      if (getters.casesByDentate.lower.length > 0) {
        dentates.push("LOWER");
      }
      if (getters.casesByDentate.both.length > 0) {
        dentates.push("BOTH");
      }
      if (getters.casesByDentate.none.length > 0) {
        dentates.push("NONE");
      }
      return dentates;
    },
    casesByDentate: state => {
      const sortedCases = {
        upper: [],
        lower: [],
        both: [],
        none: []
      };
      if (!state.collection) {
        return sortedCases;
      }
      if (!state.collection.cases) {
        return sortedCases;
      }
      if (state.collection.cases.length === 0) {
        return sortedCases;
      }
      state.collection.cases.forEach(kase => {
        if (kase.dentate) {
          if (kase.dentate === "BOTH") {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "NONE") {
            sortedCases.none.push(kase);
            return;
          }
          if (
            kase.implants.filter(implant => implant.tooth > 30).length > 0 &&
            kase.implants.filter(implant => implant.tooth < 30).length > 0
          ) {
            sortedCases.both.push(kase);
            return;
          }
          if (kase.dentate === "UPPER") {
            if (
              kase.implants.filter(implant => implant.tooth > 30).length > 0
            ) {
              sortedCases.both.push(kase);
              return;
            }
            sortedCases.upper.push(kase);
            return;
          }
          if (kase.implants.filter(implant => implant.tooth < 30).length > 0) {
            sortedCases.both.push(kase);
            return;
          }
          sortedCases.lower.push(kase);
          return;
        }
        if (kase.implants.length === 0) {
          sortedCases.none.push(kase);
          return;
        }
        if (
          kase.implants.filter(implant => implant.tooth > 30).length > 0 &&
          kase.implants.filter(implant => implant.tooth < 30).length > 0
        ) {
          sortedCases.both.push(kase);
          return;
        }
        if (kase.implants.filter(implant => implant.tooth > 30).length > 0) {
          sortedCases.lower.push(kase);
          return;
        }
        sortedCases.upper.push(kase);
      });
      return sortedCases;
    },
    implantsByDentate: (_, getters) => {
      const sortedImplants = {
        upper: [],
        lower: [],
        both: [],
        none: []
      };
      getters.casesByDentate.upper.forEach(kase => {
        kase.implants
          .filter(implant => !!implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (tooth < 30 && !sortedImplants.upper.includes(tooth)) {
              sortedImplants.upper.push(tooth);
            }
          });
      });
      getters.casesByDentate.lower.forEach(kase => {
        kase.implants
          .filter(implant => !!implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (tooth > 30 && !sortedImplants.lower.includes(tooth)) {
              sortedImplants.lower.push(tooth);
            }
          });
      });
      getters.casesByDentate.both.forEach(kase => {
        kase.implants
          .filter(implant => !!implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (!sortedImplants.both.includes(tooth)) {
              sortedImplants.both.push(tooth);
            }
          });
      });
      getters.casesByDentate.none.forEach(kase => {
        kase.implants
          .filter(implant => !!implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (!sortedImplants.none.includes(tooth)) {
              sortedImplants.none.push(tooth);
            }
          });
      });
      return sortedImplants;
    },
    ponticsByDentate: (_, getters) => {
      const sortedPontics = {
        upper: [],
        lower: [],
        both: [],
        none: []
      };
      getters.casesByDentate.upper.forEach(kase => {
        kase.implants
          .filter(implant => !implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (tooth < 30 && !sortedPontics.upper.includes(tooth)) {
              sortedPontics.upper.push(tooth);
            }
          });
      });
      getters.casesByDentate.lower.forEach(kase => {
        kase.implants
          .filter(implant => !implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (tooth > 30 && !sortedPontics.lower.includes(tooth)) {
              sortedPontics.lower.push(tooth);
            }
          });
      });
      getters.casesByDentate.both.forEach(kase => {
        kase.implants
          .filter(implant => !implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (!sortedPontics.both.includes(tooth)) {
              sortedPontics.both.push(tooth);
            }
          });
      });
      getters.casesByDentate.none.forEach(kase => {
        kase.implants
          .filter(implant => !implant.platform)
          .map(implant => implant.tooth)
          .forEach(tooth => {
            if (!sortedPontics.none.includes(tooth)) {
              sortedPontics.none.push(tooth);
            }
          });
      });
      return sortedPontics;
    },
    openApprovals: state => {
      return state.collection.approvals.filter(approval => {
        return !approval.approved_at && !approval.cancelled_at;
      });
    }
  },
  mutations: {
    SET_CURRENT_TAB(state, payload) {
      if (payload !== "chat") {
        state.chatHighlightUID = null;
      }
      state.currentTab = payload;
    },
    SET_PORTAL_KEY(state, payload) {
      state.portalKey = payload;
    },
    SET_BRANDING(state, payload) {
      state.branding = payload;
    },
    SET_INTERNATIONAL_TOOTH_CONVENTION(state, payload) {
      state.internationalToothConvention = payload;
    },
    SET_RELOAD_TRIGGER(state, payload) {
      state.reloadTrigger = payload;
    },
    SET_CHAT_HIGHLIGHT_UID(state, payload) {
      state.chatHighlightUID = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_MESSAGE_SEEN(state, uid) {
      const message = state.collection.messages.find(
        message => message.uid === uid
      );
      if (!message) {
        return;
      }
      message.message_seen = true;
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    }
  },
  actions: {
    setCurrentTab({ commit }, tab) {
      commit("SET_CURRENT_TAB", tab);
    },
    setPortalKey({ commit }, key) {
      commit("SET_PORTAL_KEY", key);
    },
    setBranding({ commit }, brand) {
      commit("SET_BRANDING", brand);
    },
    setInternationalToothConvention({ commit }, convention) {
      commit("SET_INTERNATIONAL_TOOTH_CONVENTION", convention);
    },
    triggerReload({ commit }) {
      commit("SET_RELOAD_TRIGGER", true);
    },
    reloadComplete({ commit }) {
      commit("SET_RELOAD_TRIGGER", false);
    },
    setChatHighlightUID({ commit }, uid) {
      commit("SET_CHAT_HIGHLIGHT_UID", uid);
    },
    setCollection({ commit }, collection) {
      commit("SET_COLLECTION", collection);
    },
    setMessageSeen({ commit }, messageUID) {
      commit("SET_MESSAGE_SEEN", messageUID);
    },
    setMessages({ commit }, messages) {
      commit("SET_MESSAGES", messages);
    }
  },
  modules: {}
});
