<template>
  <v-dialog v-model="printOptionsModal" max-width="500">
    <v-card class="rounded-lg">
      <v-toolbar dark>
        <v-btn icon dark @click="printOptionsModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Print Options</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-select
          class="mt-4 rounded-lg"
          v-model="documentType"
          outlined
          dense
          label="Document Type"
          :items="documentTypes"
        />
        <v-select
          class="rounded-lg"
          v-if="documentType !== 'qc'"
          v-model="printPrinter"
          outlined
          dense
          label="Printer"
          :items="printers"
        />
        <v-select
          class="rounded-lg"
          v-if="documentType !== 'qc'"
          v-model="printQuantity"
          outlined
          dense
          label="Quantity"
          :items="[1, 2, 3, 4, 5, 6]"
        />
        <v-checkbox
          v-if="documentType !== 'qc' && documentType !== 'udi'"
          v-model="printRelated"
          label="Print Related"
        />
        <v-row class="mb-2">
          <v-col class="text-right">
            <v-btn
              class="rounded-lg"
              :disabled="!printPrinter"
              v-if="documentType !== 'qc'"
              @click="triggerPrint()"
            >
              <v-icon class="mr-2">mdi-printer</v-icon>
              Print
            </v-btn>
            <v-btn
              v-if="false && documentType === 'qc'"
              class="mr-2 primary"
              @click="printQC()"
            >
              <v-icon class="mr-2">mdi-printer</v-icon>
              Print New
            </v-btn>
            <v-btn
              v-if="documentType === 'qc'"
              class="secondary rounded-lg"
              @click="printQCLegacy()"
            >
              <v-icon class="mr-2">mdi-printer</v-icon>
              Print
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import options from "@/plugins/axios_options";

export default {
  name: "CasePrintModal",
  props: ["value", "kase"],
  data() {
    return {
      printRelated: false,
      printQuantity: 1,
      printPrinter: null,
      documentType: null,
      documentTypes: [
        { text: "Receipt", value: "receipt" },
        { text: "Label", value: "label" },
        { text: "Warranty", value: "warranty" },
        { text: "Packing Slip", value: "packing_slip" },
        { text: "QC Checklist", value: "qc" },
        { text: "UDI", value: "udi" }
      ]
    };
  },
  computed: {
    ...mapGetters(["me"]),
    printOptionsModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    printers() {
      if (!this.documentType) {
        return [];
      }
      let printerType = null;
      if (
        this.documentType === "receipt" ||
        this.documentType === "packing_slip"
      ) {
        printerType = "receipt";
      } else {
        printerType = "label";
      }
      return [...this.me.printers]
        .filter(printer => printer.print_type === printerType)
        .sort((a, b) => a.printer_name.localeCompare(b.printer_name))
        .map(printer => {
          return { text: printer.printer_name, value: printer.id };
        });
    }
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    async triggerPrint() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_mkey}/print/`, {
          document_type: this.documentType,
          quantity: this.printQuantity,
          printer_id: this.printPrinter,
          bundle: this.printRelated ? "all" : "one"
        })
        .then(() => {
          this.printOptionsModal = false;
          this.stopLoading();
        });
    },
    printQC() {
      const printer = this.me.printers.find(
        printer => printer.print_type === "document"
      );
      if (!printer || !printer.id) {
        return;
      }
      this.printPrinter = printer.id;
      this.triggerPrint();
    },
    printQCLegacy() {
      window.open(
        `${options.oepURL}/case/${this.kase.uid}/qc-checklist/`,
        "_blank"
      );
    }
  }
};
</script>
