<template>
  <v-navigation-drawer
    app
    permanent
    :mini-variant="currentView === 'noCollection'"
    :expand-on-hover="currentView !== 'noCollection'"
    style="z-index: 9999;"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="require('@/assets/osteon-logo.png')" />
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-list
      v-if="currentView !== 'noCollection' && currentView !== 'active'"
      nav
      dense
    >
      <v-list-item
        link
        :input-value="currentView === 'components'"
        @click="setCurrentView('components')"
      >
        <v-list-item-icon>
          <v-icon>mdi-hexagon-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Case Components</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'messages'"
        @click="setCurrentView('messages')"
      >
        <v-list-item-icon>
          <v-icon>mdi-message</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Notes &amp; Messages</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'approvals'"
        @click="setCurrentView('approvals')"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Approvals</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'orderForms'"
        @click="setCurrentView('orderForms')"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Order Forms</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'checklists'"
        @click="setCurrentView('checklists')"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-check-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Checklists</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'invoices'"
        @click="setCurrentView('invoices')"
      >
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Billing</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        :input-value="currentView === 'dispatches'"
        @click="setCurrentView('dispatches')"
      >
        <v-list-item-icon>
          <v-icon>mdi-truck-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dispatches</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavigationDrawer",
  watch: {
    currentView(value) {
      if (value === "noCase") {
        window.document.title = "Case Manager";
      }
    }
  },
  computed: {
    ...mapGetters(["currentView"])
  },
  methods: {
    ...mapActions(["setCurrentView"])
  }
};
</script>
