<template>
  <v-footer app rounded="0" class="pb-0" inset>
    <v-row>
      <v-col cols="12" v-if="loading" class="text-center">
        Sending...
        <v-progress-linear indeterminate class="my-3" color="primary" />
      </v-col>
      <v-col cols="12" v-if="!loading">
        <v-textarea
          hide-details
          class="mb-2"
          v-model="message"
          placeholder="Message"
          background-color="white"
          light
          solo
          auto-grow
          rows="1"
        >
          <template v-slot:append>
            <v-file-input
              v-model="attachments"
              hide-input
              multiple
              class="message-attachment pa-0 ma-0 pt-0"
            />
            <v-btn small icon @click="send()">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-textarea>
        <v-chip
          small
          v-for="(attachment, index) in attachments"
          :key="index"
          close
          class="py-1 my-1 mr-2"
          @click:close="removeAttachment(attachment)"
        >
          <v-icon>mdi-paperclip</v-icon>
          {{ attachment.name }}
        </v-chip>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Compose",
  data() {
    return {
      loading: false,
      message: "",
      attachments: []
    };
  },
  computed: {
    ...mapGetters(["portalKey"])
  },
  methods: {
    ...mapActions(["triggerReload"]),
    removeAttachment(attachment) {
      const index = this.attachments.indexOf(attachment);
      if (index < 0) {
        return;
      }
      this.attachments.splice(index, 1);
    },
    async send() {
      if (!this.message) {
        return;
      }
      if (this.message.length === 0) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("note", this.message);
      this.attachments.forEach(attachment => {
        formData.append("attachments", attachment, attachment.name);
      });
      this.$axios
        .post(`/api/v2/customer/${this.portalKey}/message/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.triggerReload();
          this.message = "";
          this.attachments = [];
          this.loading = false;
          this.$emit("sent", true);
        });
    }
  }
};
</script>

<style>
.message-attachment {
  display: inline !important;
}
.message-attachment > .v-input__append-outer,
.v-input__prepend-outer {
  display: inline !important;
}
</style>
