<template>
  <v-card outlined>
    <v-card-text>
      <v-card outlined class="mb-5">
        <v-card-title>Basic Information</v-card-title>
        <v-card-text>
          <div
            v-for="(key, index) in Object.keys(basicForm(form.json_data))"
            :key="index"
          >
            <v-row v-if="basicForm(form.json_data)[key]" class="mb-5">
              <v-col cols="12" lg="6">
                <strong>{{ key | snakeToTitle }}</strong>
              </v-col>
              <v-col cols="12" lg="6">
                {{ basicForm(form.json_data)[key] }}
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <div
        class="mb-5"
        v-for="key in Object.keys(nestedVerticalTableForms(form.json_data))"
        :key="key"
      >
        <v-card outlined v-if="nestedVerticalTableForms(form.json_data)[key]">
          <v-card-title>{{ key | snakeToTitle }}</v-card-title>
          <v-card-text>
            <div
              v-for="subKey in Object.keys(
                nestedVerticalTableForms(form.json_data)[key]
              )"
              :key="subKey"
            >
              <v-row
                v-if="nestedVerticalTableForms(form.json_data)[key][subKey]"
                class="mb-5"
              >
                <v-col cols="12" lg="6">
                  <strong>{{ subKey | snakeToTitle }}</strong>
                </v-col>
                <v-col cols="12" lg="6">
                  {{ nestedVerticalTableForms(form.json_data)[key][subKey] }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <v-card outlined v-if="form.json_data['implants']" class="mb-5">
        <v-card-title>Implants</v-card-title>
        <v-card-text>
          <v-card
            outlined
            v-for="(implant, index) in form.json_data['implants']"
            :key="index"
            class="mb-3"
          >
            <v-card-text>
              <v-row align="center">
                <v-col cols="2" class="text-center">
                  <v-icon>mdi-tooth</v-icon>
                  {{ getToothNumber(implant.tooth) }}
                </v-col>
                <v-col lg="6" cols="10">
                  {{ implantPlatformName(implant) }}
                </v-col>
                <v-col lg="4" cols="12" class="text-right">
                  <v-chip small class="mr-2" v-if="implant.scan_body_type">
                    Scan Body: {{ implant.scan_body_type }}
                  </v-chip>
                  <v-chip small :color="implant.biAxial ? 'success' : ''">
                    <v-icon small v-if="implant.biAxial" class="mr-2"
                      >mdi-check</v-icon
                    >
                    <v-icon small v-if="!implant.biAxial" class="mr-2"
                      >mdi-close</v-icon
                    >
                    Bi-Axial
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import internationalToothNumbers from "@/shared/international_tooth_numbers";

export default {
  name: "Form",
  props: ["form"],
  data() {
    return {
      basicKeys: [
        "solution",
        "attachments",
        "oep_api_secret",
        "client",
        "agree",
        "parent_case_id",
        "tag"
      ],
      nestedVerticalTableKeys: [
        "lower_options",
        "upper_options",
        "preop_options"
      ]
    };
  },
  computed: {
    ...mapGetters(["internationalToothConvention", "collection"])
  },
  methods: {
    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.internationalToothConvention
      ].padStart(2, "0");
    },
    caseFromUID(uid) {
      return this.collection.cases.find(kase => kase.uid === uid);
    },
    basicForm(form) {
      return Object.keys(form).reduce((object, key) => {
        if (
          !this.basicKeys.includes(key) &&
          !this.nestedVerticalTableKeys.includes(key) &&
          key !== "implants"
        ) {
          object[key] = form[key];
        }
        return object;
      }, {});
    },
    nestedVerticalTableForms(form) {
      return Object.keys(form).reduce((object, key) => {
        if (this.nestedVerticalTableKeys.includes(key)) {
          object[key] = form[key];
        }
        return object;
      }, {});
    },
    implantPlatformName(implant) {
      if (implant.name && implant.name.length > 0) {
        return implant.name;
      }
      if (implant.platform && implant.platform.__str__) {
        return implant.platform.__str__;
      }
      return "Unknown";
    }
  },
  filters: {
    snakeToTitle(str) {
      str = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      return str
        .split("_")
        .map(function(item) {
          return item.charAt(0).toUpperCase() + item.substring(1);
        })
        .join(" ");
    }
  }
};
</script>
