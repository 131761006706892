<template>
  <v-row justify="center">
    <v-dialog v-model="displayRedoModal" max-width="800">
      <v-card class="rounded-lg">
        <v-toolbar dark>
          <v-btn icon dark @click="displayRedoModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Redo Case</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="redoForm" lazy-validation>
            <v-row>
              <v-col sm="12">
                <v-checkbox v-model="redo" label="Set Case as Redo" />
              </v-col>
            </v-row>
            <v-row v-if="redo">
              <v-col sm="12" lg="6">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="kase.redo"
                  label="Type"
                  :items="redoTypeItems"
                  dense
                  outlined
                />
              </v-col>
              <v-col sm="12" lg="6">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="kase.redo_reason"
                  label="Reason"
                  :items="redoReasonItems"
                  dense
                  outlined
                />
              </v-col>
              <v-col sm="12" lg="6">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="kase.redo_charge"
                  label="Charge"
                  :items="redoChargeItems"
                  dense
                  outlined
                  @change="updateDiscountAmount()"
                />
              </v-col>
              <v-col sm="12" lg="6">
                <v-text-field
                  class="rounded-lg"
                  v-if="kase.redo_charge === 'discount'"
                  v-model="kase.redo_discount_amount"
                  label="Discount Amount"
                  type="number"
                  suffix="%"
                  dense
                  outlined
                />
              </v-col>
              <v-col sm="12" lg="6">
                <v-checkbox
                  v-model="kase.redo_new_screws_required"
                  label="New Screws Required"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  :disabled="submitting"
                  color="success"
                  class="rounded-lg"
                  @click="updateCase"
                >
                  <v-icon class="mr-2">mdi-content-save</v-icon>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RedoModal",
  data() {
    return {
      submitting: false,
      redo: false
    };
  },
  watch: {
    kase() {
      if (this.kase.redo) {
        this.redo = true;
      }
    },
    redo(value) {
      if (value) {
        return;
      }
      this.$refs.redoForm.reset();
    }
  },
  computed: {
    ...mapGetters(["redoModal", "collection", "kase"]),
    displayRedoModal: {
      get() {
        return this.redoModal;
      },
      set(value) {
        if (!value) {
          this.triggerReload();
          this.hideRedoModal();
        }
      }
    },
    redoTypeItems() {
      return [
        { text: "Internal", value: "internal" },
        { text: "External", value: "external" }
      ];
    },
    redoReasonItems() {
      return [
        { text: "Check In", value: "che" },
        { text: "Client", value: "cli" },
        { text: "Design", value: "des" },
        { text: "Metrology", value: "met" },
        { text: "Polishing", value: "pol" },
        { text: "Production", value: "pro" },
        { text: "Warranty", value: "war" }
      ];
    },
    redoChargeItems() {
      return [
        { text: "Full", value: "full" },
        { text: "Discount", value: "discount" },
        { text: "Free", value: "free" }
      ];
    }
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "hideRedoModal",
      "triggerReload"
    ]),
    updateDiscountAmount() {
      if (this.kase.redo_charge !== "discount") {
        this.kase.redo_discount_amount = null;
      }
    },
    async updateCase() {
      if (!this.$refs.redoForm.validate()) {
        return;
      }
      this.submitting = true;
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "edit_case",
          case: {
            fulfillment_branch_id: this.collection.fulfillment_branch_id,
            ...this.kase
          }
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
          this.displayRedoModal = false;
          this.submitting = false;
        });
    }
  }
};
</script>
