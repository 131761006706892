<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      Status
    </v-card-title>
    <v-card-text>
      <v-row v-if="!loading">
        <v-col class="pb-0">
          <v-autocomplete
            v-model="changeStatus"
            class="rounded-lg"
            :prepend-inner-icon="saved ? 'mdi-check' : ''"
            x-large
            outlined
            :items="statusSelect"
            label="Change status..."
          >
            <template v-slot:item="data">
              <div class="ma-3">
                <strong>{{ data.item.text }}</strong
                ><br />
                <small>{{ data.item.description }}</small>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col class="text-center">
          <v-btn color="success" class="rounded-lg" @click="dispatchReady()">
            <v-icon class="mr-2">mdi-truck</v-icon>
            Ready for Dispatch
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog persistent v-model="confirmDialog" max-width="500">
          <v-card class="rounded-lg">
            <v-card-title class="text-h5 justify-center warning">
              <v-icon dark class="mr-2">mdi-alert</v-icon>
              <h4 class="white--text">Are you sure?</h4>
              <v-icon dark class="ml-2">mdi-alert</v-icon>
            </v-card-title>
            <v-card-text>
              <p class="mt-5">
                This component will be marked as
                <v-chip outlined small>Dispatch Ready</v-chip>
              </p>
              <div v-if="groupedCases.length > 0">
                The following components will also be marked as Dispatch Ready:
                <ul>
                  <li v-for="(kase, index) in groupedCases" :key="index">
                    {{ kase.case_mkey }} - <strong>{{ kase.job_type }}</strong>
                  </li>
                </ul>
              </div>
              <p v-if="groupedCases.length > 0">
                Please ensure all components are present before proceeding.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                @click="confirmDialog = false"
                class="mr-2 rounded-lg"
              >
                <v-icon class="mr-2">mdi-close</v-icon>
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="triggerDispatchReady"
                class="rounded-lg"
              >
                <v-icon class="mr-2">mdi-check</v-icon>
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          size="128"
          class="ma-5"
          color="primary"
        >
          Setting Status
        </v-progress-circular>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StatusSelector",
  props: ["kase"],
  data() {
    return {
      changeStatus: null,
      saved: false,
      loading: false,
      confirmDialog: false,
      groupedCases: []
    };
  },
  watch: {
    changeStatus(value) {
      if (!value) {
        return;
      }
      this.triggerSetStatus();
    },
    caseUID(value) {
      if (!value) {
        return;
      }
      this.saved = false;
      this.changeStatus = null;
    },
    confirmDialog(value) {
      if (value) {
        return;
      }
      this.groupedCases = [];
    }
  },
  computed: {
    ...mapGetters(["statuses", "caseUID", "collection"]),
    statusSelect() {
      return this.statuses
        .filter(status => {
          return status.in_finishing || status.dispatch_ready;
        })
        .map(status => {
          return {
            text: status.name,
            value: status.id,
            description: status.description
          };
        });
    }
  },
  methods: {
    async triggerSetStatus() {
      this.loading = true;
      this.$axios
        .post(`/api/v2/finishing/${this.kase.case_id}/`, {
          action: "change_status",
          status_id: this.changeStatus,
          grouped: this.groupedCases.length > 0
        })
        .then(() => {
          const status = this.statuses.find(
            status => status.id === this.changeStatus
          );
          this.kase.status = status.name;
          this.kase.status_id = status.id;
          this.loading = false;
          this.saved = true;
        });
    },
    dispatchReady() {
      if (this.kase.group_number) {
        this.groupedCases = this.collection.cases.filter(kase => {
          return (
            kase.status !== "DISPATCHED" &&
            kase.status !== "CANCELLED" &&
            kase.uid !== this.kase.uid &&
            kase.group_number === this.kase.group_number
          );
        });
      }
      this.confirmDialog = true;
    },
    async triggerDispatchReady() {
      const dispatchStatus = this.statuses.find(
        status => status.dispatch_ready
      );
      this.changeStatus = dispatchStatus.id;
      await this.triggerSetStatus();
      this.confirmDialog = false;
    }
  }
};
</script>
