var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center ma-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"256","color":"primary"}},[_vm._v(" Loading Active Cases ")])],1):_vm._e(),(!_vm.loading)?_c('div',[_c('v-app-bar',{staticClass:"mb-5 rounded-lg",attrs:{"elevation":"0","outlined":""}},[_c('h3',[_c('v-avatar',[_c('v-icon',[_vm._v("mdi-playlist-play")])],1),_vm._v(" All Active Cases ")],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"sm":"6","lg":"3"}},[_c('v-select',{staticClass:"mt-5 mr-2 rounded-lg",attrs:{"clearable":"","dense":"","outlined":"","items":_vm.countryItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ml-1",attrs:{"align":"center"}},[_c('CountryFlags',{staticClass:"mr-1 mb-1",attrs:{"country":item.value,"size":"normal"}}),_c('span',[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('CountryFlags',{staticClass:"mr-1 my-auto",attrs:{"country":item.value,"size":"normal"}}),_vm._v(" "+_vm._s(item.text)+" ")],1)],1)],1)],1)]}}],null,false,1076920336),model:{value:(_vm.countryFilter),callback:function ($$v) {_vm.countryFilter=$$v},expression:"countryFilter"}})],1)],1)],1),_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.filteredCollections},on:{"click:row":_vm.openCollection},scopedSlots:_vm._u([{key:"item.icon",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-avatar',{attrs:{"left":"","size":"32","color":"#147BD1"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-hexagon-multiple")])],1)],1)],1)]},proxy:true},{key:"item.collection_id",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('strong',[_vm._v(_vm._s(item.collection_id))])])],1)]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('CountryFlags',{attrs:{"country":item.country,"size":"normal"}})],1)],1)]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(item.client_name)+" ")])],1)]}},{key:"item.patient_reference",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(item.patient_reference)+" ")])],1)]}},{key:"item.first_case_start_time",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-chip',{attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(_vm._f("luxon")(item.first_case_start_time,{ output: "tz_date_med" })))]),(
                  item.first_case_start_time !== item.last_case_start_time
                )?_c('span',[_vm._v(" - "),_c('v-chip',{attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(_vm._f("luxon")(item.last_case_start_time,{ output: "tz_date_med" })))])],1):_vm._e()],1)],1)]}},{key:"item.status",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v("Active")])],1)],1)]},proxy:true},{key:"item.case_count",fn:function(ref){
                var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-chip',{attrs:{"small":"","outlined":"","label":""}},[_vm._v(" "+_vm._s(item.case_count)+" ")])],1)],1)]}}],null,false,2698374184)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }