<template>
  <v-row>
    <v-col sm="12" lg="7">
      <CaseAudit :audit="caseAudit" />
    </v-col>
    <v-col sm="12" lg="5">
      <LocationAudit class="mb-5" />
      <DelegationAudit :audit="delegationAudit" class="mb-5" />
      <ImplantAudit :audit="implantAudit" class="mb-5" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { diff } from "deep-object-diff";
import hiddenAttributes from "./Audit/hidden_attributes";
import CaseAudit from "./Audit/CaseAudit";
import DelegationAudit from "./Audit/DelegationAudit";
import LocationAudit from "./Audit/LocationAudit";
import ImplantAudit from "./Audit/ImplantAudit";

export default {
  name: "Audit",
  components: {
    CaseAudit,
    DelegationAudit,
    LocationAudit,
    ImplantAudit
  },
  computed: {
    ...mapGetters(["kase", "audit"]),
    caseAudit() {
      let audit = this.audit.find(item => item.case_uid === this.kase.uid);
      if (!audit) {
        return [];
      }
      audit = audit.case_audit.sort((a, b) =>
        b.modified_at.localeCompare(a.modified_at)
      );
      let filteredAudit = audit.map(change => {
        return {
          ...change,
          changed_values: Object.keys(change.changed_values)
            .filter(key => {
              if (hiddenAttributes.includes(key)) {
                return false;
              }
              return true;
            })
            .reduce((obj, key) => {
              obj[key] = change.changed_values[key];
              return obj;
            }, {})
        };
      });
      filteredAudit = filteredAudit.map((change, index) => {
        let changedAttributes = [];
        if (index !== audit.length - 1) {
          changedAttributes = Object.keys(
            diff(change.changed_values, filteredAudit[index + 1].changed_values)
          );
        }
        return {
          ...change,
          changed_attributes: changedAttributes
        };
      });
      return filteredAudit.filter(
        change => change.changed_attributes.length > 0
      );
    },
    implantAudit() {
      const audit = this.audit.find(item => item.case_uid === this.kase.uid);
      if (!audit) {
        return [];
      }
      return audit.implant_audit.sort((a, b) =>
        b.modified_at.localeCompare(a.modified_at)
      );
    },
    delegationAudit() {
      const audit = this.audit.find(item => item.case_uid === this.kase.uid);
      if (!audit) {
        return [];
      }
      return audit.delegation_audit.sort((a, b) =>
        b.modified_at.localeCompare(a.modified_at)
      );
    }
  }
};
</script>
