<template>
  <div>
    <v-form @submit.prevent="collectionSearch()">
      <v-row>
        <v-col v-if="!searchResponse" cols="12" align="center">
          <v-img
            v-if="eggClick <= 3"
            @click="dasEgg()"
            :src="require('@/assets/osteon-logo.png')"
            max-width="128"
          />
          <v-img
            v-if="eggClick > 3"
            :src="require('@/assets/egg.gif')"
            max-width="128"
          />
          <span v-if="eggClick <= 3">
            <h1>Case Search</h1>
            What are you looking for?
          </span>
          <span v-if="eggClick > 3">
            <h1>Get back to work!</h1>
          </span>
        </v-col>
        <v-col :cols="searchResponse ? 8 : 6" class="mx-auto">
          <v-text-field
            class="rounded-lg"
            autofocus
            v-model="keyword"
            outlined
            clearable
            label="Search for Cases"
          />
        </v-col>
        <v-col
          :cols="searchResponse ? 4 : 12"
          :align="searchResponse ? 'left' : 'center'"
        >
          <v-btn
            v-if="!loading"
            dark
            color="#147BD1"
            x-large
            class="mr-2 rounded-lg"
            @click="collectionSearch()"
          >
            <v-icon class="mr-2">mdi-magnify</v-icon>
            Search
          </v-btn>
          <v-btn
            v-if="!loading"
            dark
            color="green"
            @click="caseCheckin()"
            x-large
            class="mr-2 rounded-lg"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Check-in
          </v-btn>
          <v-btn
            v-if="!loading"
            dark
            color="warning"
            class="rounded-lg"
            @click="allActive()"
            x-large
          >
            <v-icon class="mr-2">mdi-playlist-play</v-icon>
            All Active
          </v-btn>
          <v-progress-circular
            size="50"
            indeterminate
            v-if="loading"
            color="#147BD1"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="!loading && searchResponse">
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th>Case ID</th>
              <th>Country / Region</th>
              <th>Client</th>
              <th>Patient Reference</th>
              <th>Date Range</th>
              <th>Status</th>
              <th>Components</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(result, index) in searchResponse"
              :key="index"
              style="cursor: pointer"
              @click="openCollection(result.uid)"
            >
              <td>
                <v-avatar left size="32" color="#147BD1">
                  <v-icon color="white">mdi-hexagon-multiple</v-icon>
                </v-avatar>
              </td>
              <td>
                <strong>{{ result.collection_id }}</strong>
              </td>
              <td>
                <CountryFlags
                  :country="result.country"
                  size="normal"
                  class="mr-1"
                />
              </td>
              <td>{{ result.client_name }}</td>
              <td>{{ result.patient_reference }}</td>
              <td>
                <v-chip outlined small>{{
                  result.first_case_start_time
                    | luxon({ output: "tz_date_med" })
                }}</v-chip>
                <span
                  v-if="
                    result.first_case_start_time !== result.last_case_start_time
                  "
                >
                  -
                  <v-chip outlined small>{{
                    result.last_case_start_time
                      | luxon({ output: "tz_date_med" })
                  }}</v-chip>
                </span>
              </td>
              <td>
                <v-chip small color="success" v-if="result.active"
                  >Active</v-chip
                >
                <v-chip small v-if="!result.active">Archived</v-chip>
              </td>
              <td>
                <v-chip small outlined label>
                  {{ result.case_count }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="searchResponse.length === 0">
              <td colspan="8" class="text-center pa-5">
                <v-icon>mdi-emoticon-sad</v-icon>
                We found nothing
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CountryFlags from "@/shared/CountryFlags";

export default {
  name: "Search",
  components: {
    CountryFlags
  },
  data() {
    return {
      loading: false,
      eggClick: 0
    };
  },
  computed: {
    ...mapGetters(["searchKeyword", "searchResponse"]),
    keyword: {
      get() {
        return this.searchKeyword;
      },
      set(value) {
        this.setSearchKeyword(value);
      }
    }
  },
  methods: {
    ...mapActions([
      "setCollectionUID",
      "triggerReload",
      "setCurrentView",
      "setSearchKeyword",
      "setSearchResponse",
      "startBlockLoading"
    ]),
    openCollection(collectionUID) {
      this.startBlockLoading();
      this.setCollectionUID(collectionUID);
      this.triggerReload();
      this.setCurrentView("components");
    },
    async collectionSearch() {
      if (!this.keyword) {
        return;
      }
      if (this.keyword.length < 2) {
        return;
      }
      this.loading = true;
      this.$axios
        .post("/api/v2/collections/", { keyword: this.keyword })
        .then(response => {
          this.setSearchResponse(response.data);
          if (this.searchResponse.length === 1) {
            this.openCollection(this.searchResponse[0].uid);
          }
          this.loading = false;
        });
    },
    caseCheckin() {
      document.location = "/static/v2/checkin.html";
    },
    allActive() {
      this.setCurrentView("active");
    },
    dasEgg() {
      this.eggClick++;
      setTimeout(() => {
        this.eggClick = 0;
      }, 2000);
    }
  },
  mounted() {
    if (this.searchKeyword && !this.searchResponse) {
      this.collectionSearch();
    }
  }
};
</script>
