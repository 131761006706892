<template>
  <v-row>
    <v-col sm="6" v-if="kase.order_files">
      <OrderFiles />
    </v-col>
    <v-col sm="6" v-if="kase.internal_files">
      <InternalFiles />
    </v-col>
    <v-col sm="6" v-if="kase.approval_files">
      <ApprovalFiles />
    </v-col>
    <v-col sm="6" v-if="kase.outsource_sent_files">
      <OutsourceSharedFiles />
    </v-col>
    <v-col sm="6" v-if="kase.outsource_received_files">
      <OutsourceReceivedFiles />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import OrderFiles from "./Files/OrderFiles";
import InternalFiles from "./Files/InternalFiles";
import ApprovalFiles from "./Files/ApprovalFiles";
import OutsourceSharedFiles from "./Files/OutsourceSharedFiles";
import OutsourceReceivedFiles from "./Files/OutsourceReceivedFiles";

export default {
  name: "Files",
  components: {
    OrderFiles,
    InternalFiles,
    ApprovalFiles,
    OutsourceSharedFiles,
    OutsourceReceivedFiles
  },
  computed: {
    ...mapGetters(["kase"])
  }
};
</script>
