<template>
  <v-row>
    <v-col cols="auto" class="font-italic">
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="pl-5">
            <small class="mr-2">
              <v-icon v-if="type === 'email'" small class="mr-1"
                >mdi-email</v-icon
              >
              <v-icon v-if="type === 'mobile'" small class="mr-1"
                >mdi-cellphone-message</v-icon
              >
              <strong>{{
                toTitleCase(communications.communication_type)
              }}</strong>
              <span v-if="type === 'email'"> email</span>
              <span v-if="type === 'mobile'"> notification</span>
              sent -
              {{ communications.created_at | luxon({ output: "tz_med" }) }}
              <span
                v-if="
                  caseDescriptionFromUID(
                    communications.case_uid,
                    communications.case_collection_uid
                  )
                "
              >
                -
                <a
                  @click="
                    openCase(
                      communications.case_uid,
                      communications.case_collection_uid
                    )
                  "
                  v-if="caseLink"
                >
                  {{
                    caseDescriptionFromUID(
                      communications.case_uid,
                      communications.case_collection_uid
                    )
                  }}
                </a>
                <span v-if="!caseLink">
                  {{
                    caseDescriptionFromUID(
                      communications.case_uid,
                      communications.case_collection_uid
                    )
                  }}
                </span>
              </span>
            </small>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="communications">
            <div v-if="type === 'mobile'">
              <div
                v-for="(communication, index) in communications.response"
                :key="index"
                class="mx-1"
              >
                <v-icon small v-if="communication.device_type === 'android'">
                  mdi-android
                </v-icon>
                <v-icon small v-if="communication.device_type === 'ios'">
                  mdi-apple-ios
                </v-icon>
                {{ communication.identifcation }}
                <v-icon small v-if="communication.success">mdi-check</v-icon>
              </div>
            </div>
            <div v-if="type === 'email'">
              <div
                v-for="(communication, index) in communications.response"
                :key="index"
                class="mx-1"
              >
                <v-tooltip color="rgba(0, 0, 0, 0)" top>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @mouseover="fetchEmailInfo(communication._id)"
                      @mouseleave="resetValues()"
                    >
                      <v-icon small>
                        mdi-account
                      </v-icon>
                      {{ communication.email }}
                    </a>
                  </template>
                  <v-card dark v-if="emailInfo && !error">
                    <v-card-title>
                      <v-icon class="mr-2">mdi-email</v-icon>
                      {{ emailInfo.email }}
                    </v-card-title>
                    <v-simple-table dark dense class="px-5 pb-5">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Clicks</th>
                          <th>Opens</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <small>{{ toTitleCase(emailInfo.state) }}</small>
                          </td>
                          <td>
                            <small>{{ emailInfo.clicks }}</small>
                          </td>
                          <td>
                            <small>{{ emailInfo.opens }}</small>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                  <v-card dark v-if="error">
                    <v-card-title>
                      <v-icon class="mr-2">mdi-alert</v-icon>
                      {{ error.error }}
                    </v-card-title>
                  </v-card>
                  <v-card
                    dark
                    v-if="!emailInfo && !error"
                    class="text-center pa-5"
                  >
                    <v-progress-circular size="128" indeterminate color="white">
                      Loading...
                    </v-progress-circular>
                  </v-card>
                </v-tooltip>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Communication",
  props: ["communications", "caseLink", "collection", "collections"],
  data() {
    return {
      emailInfo: null,
      error: null
    };
  },
  computed: {
    type() {
      if (this.communications.communication_provider === "FCM") {
        return "mobile";
      }
      return "email";
    }
  },
  methods: {
    toTitleCase(string) {
      return string
        .replaceAll("_", " ")
        .replace(
          /[a-zA-Z]+/g,
          text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
        );
    },
    async fetchEmailInfo(emaiID) {
      this.$axios.get(`/api/v2/communications/${emaiID}`).then(response => {
        if (response.data && response.data.error) {
          this.error = response.data;
          return;
        }
        this.emailInfo = response.data;
      });
    },
    caseDescriptionFromUID(caseUID, collectionUID) {
      if (!caseUID || !collectionUID) {
        return;
      }
      let collection = null;
      if (this.collections) {
        collection = this.collections.find(
          collection => collection.uid === collectionUID
        );
      } else {
        collection = this.collection;
      }
      if (!collection) {
        return;
      }
      const kase = collection.cases.find(kase => kase.uid === caseUID);
      if (!kase) {
        return;
      }
      if (this.caseLink) {
        return `${kase.case_mkey} - ${kase.job_type}`;
      }
      return `${kase.job_type} (${kase.collection_reference})`;
    },
    openCase(caseUID, collectionUID) {
      if (!caseUID || !collectionUID) {
        return;
      }
      window.open(
        `/static/v2/collection.html?uid=${collectionUID}&cuid=${caseUID}`
      );
    },
    resetValues() {
      this.emailInfo = null;
      this.error = null;
    }
  }
};
</script>
