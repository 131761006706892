<template>
  <v-card outlined class="rounded-lg">
    <v-card-title>
      <v-icon class="mr-2">mdi-vector-link</v-icon>
      Grouping
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          This
          <v-chip
            class="rounded-lg"
            dark
            :color="groupColor(kase.group_number)"
            label
          >
            {{ kase.job_type }}
          </v-chip>
          is grouped with:
        </v-col>
        <v-col>
          <ul v-for="(relatedCase, index) in groupedCases" :key="index">
            <li class="mb-2">
              <v-chip
                class="rounded-lg"
                dark
                :color="groupColor(kase.group_number)"
                label
                @click="setCaseUID(relatedCase.uid)"
              >
                {{ relatedCase.case_mkey }} -
                {{ relatedCase.job_type }}
              </v-chip>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import groupColor from "@/shared/group_color";

export default {
  name: "Grouping",
  computed: {
    ...mapGetters(["collection", "kase"]),
    groupedCases() {
      if (!this.kase) {
        return;
      }
      if (!this.collection.cases) {
        return;
      }
      return this.collection.cases.filter(related => {
        return (
          related.group_number === this.kase.group_number &&
          related.uid !== this.kase.uid
        );
      });
    }
  },
  methods: {
    ...mapActions(["setCaseUID"]),
    openComponent(caseUID) {
      window.scrollTo(0, 0);
      this.setCaseUID(caseUID);
    },
    groupColor(number) {
      return groupColor(number);
    }
  }
};
</script>
