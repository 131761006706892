<template>
  <v-row>
    <v-col sm="12" md="4">
      <Immediacy class="mb-5" />
      <Progress class="mb-5" />
    </v-col>
    <v-col sm="12" md="8">
      <Schedule class="mb-5" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Immediacy from "./Timeline/Immediacy";
import Progress from "./Timeline/Progress";
import Schedule from "./Timeline/Schedule";

export default {
  name: "Timeline",
  components: {
    Immediacy,
    Progress,
    Schedule
  },
  computed: {
    ...mapGetters(["kase"])
  }
};
</script>
