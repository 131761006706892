<template>
  <div>
    <v-toolbar flat>
      <v-btn
        color="success"
        class="mr-2 rounded-lg"
        v-if="kase.active && kase.virtual_product"
        @click="showCompleteModal()"
      >
        <v-icon class="mr-2">mdi-check</v-icon> Mark as Done
      </v-btn>
      <v-btn
        :color="kase.virtual_product ? 'primary' : 'success'"
        class="mr-2 rounded-lg"
        v-if="kase.active"
        @click="showProgressModal()"
      >
        <v-icon class="mr-2">mdi-fast-forward</v-icon> Progress
      </v-btn>
      <v-btn
        dark
        color="red darken-4"
        class="mr-2 rounded-lg"
        @click="showRedoModal()"
      >
        <v-icon class="mr-2">mdi-refresh</v-icon>
        Redo
      </v-btn>
      <v-btn
        dark
        color="success"
        class="mr-2 rounded-lg"
        v-if="!kase.date_received"
        @click="triggerReceived()"
      >
        <v-icon class="mr-2">mdi-email</v-icon>
        Received
      </v-btn>
      <v-spacer />
      <v-btn class="mr-2 rounded-lg" @click="cameraCapture = true">
        <v-icon class="mr-2">mdi-camera</v-icon> Photo
      </v-btn>
      <v-btn class="mr-2 rounded-lg" @click="printOptionsModal = true">
        <v-icon class="mr-2">mdi-printer</v-icon> Print
      </v-btn>
    </v-toolbar>
    <v-row justify="center">
      <CasePrintModal v-model="printOptionsModal" :kase="kase" />
      <v-dialog v-model="cameraCapture" max-width="600">
        <CameraCapture :case-id="kase.case_id" name="case_image" />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CameraCapture from "@/shared/CameraCapture";
import CasePrintModal from "@/shared/CasePrintModal";

export default {
  name: "Toolbar",
  data() {
    return {
      printOptionsModal: false,
      cameraCapture: false
    };
  },
  components: {
    CameraCapture,
    CasePrintModal
  },
  watch: {
    cameraCapture(value) {
      if (!value) {
        this.triggerReload();
      }
    }
  },
  computed: {
    ...mapGetters(["kase"])
  },
  methods: {
    ...mapActions([
      "startLoading",
      "stopLoading",
      "triggerReload",
      "showProgressModal",
      "showCompleteModal",
      "showRedoModal"
    ]),
    async triggerReceived() {
      if (!confirm("Are you sure you want to send the case received email?")) {
        return;
      }
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "received"
        })
        .then(() => {
          this.triggerReload();
          this.stopLoading();
        });
    }
  }
};
</script>
