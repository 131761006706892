<template>
  <v-card outlined v-if="kase.generic_components" class="rounded-lg">
    <v-card-title>
      <v-row class="mb-2">
        <v-col sm="12" lg="9">
          <v-icon class="mr-2">mdi-archive-cog</v-icon>
          Components
        </v-col>
        <v-col sm="12" lg="3" class="text-right">
          <v-btn class="rounded-lg" v-if="!edit" small @click="edit = true">
            <v-icon small>mdi-lock</v-icon>
          </v-btn>
          <v-btn class="rounded-lg" v-if="edit" small @click="edit = false">
            <v-icon small>mdi-lock-open</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!edit">
      <v-row v-if="kase.generic_components.length === 0">
        <v-col sm="12" class="text-center pb-5">
          None Added
        </v-col>
      </v-row>
      <v-row v-if="kase.generic_components.length > 0">
        <v-col sm="12" lg="4" class="pb-0">
          <strong>Component</strong>
        </v-col>
        <v-col sm="12" lg="8" class="pb-0">
          <ul
            v-for="(generic_component, index) in kase.generic_components"
            :key="index"
          >
            <li>
              {{ generic_component.quantity }}x {{ generic_component.name }} -
              {{ generic_component.description }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="edit">
      <v-form ref="technicalInfoForm" lazy-validation>
        <v-row>
          <v-col>
            <ComponentTypeCard
              componentType="Component"
              :componentItems="genericComponentItems"
              :components="kase.generic_components"
              :edit="edit"
              @addComponent="addGenericComponent"
              @deleteComponent="deleteGenericComponent"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn class="rounded-lg" color="success" @click="updateCase()">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ComponentTypeCard from "./TechnicalInfo/ComponentTypeCard";

export default {
  name: "GenericComponents",
  components: {
    ComponentTypeCard
  },
  data() {
    return {
      edit: false
    };
  },
  watch: {
    edit(value) {
      if (value) {
        this.startEditing();
      } else {
        this.stopEditing();
      }
    }
  },
  computed: {
    ...mapGetters(["kase", "genericComponents"]),
    genericComponentItems() {
      return this.genericComponents.map(genericComponent => {
        return {
          text: genericComponent.description,
          value: genericComponent.id,
          code: genericComponent.code,
          name: genericComponent.name,
          description: genericComponent.description,
          disabled: genericComponent.is_disabled
        };
      });
    }
  },
  methods: {
    ...mapActions([
      "startEditing",
      "stopEditing",
      "startLoading",
      "stopLoading",
      "triggerReload"
    ]),
    async updateCase() {
      this.startLoading();
      this.$axios
        .post(`/api/v2/case/${this.kase.case_id}/`, {
          action: "edit_component",
          case: this.kase
        })
        .then(() => {
          this.edit = false;
          this.triggerReload();
          this.stopLoading();
        });
    },
    addGenericComponent(component) {
      this.kase.generic_components.push(component);
    },
    deleteGenericComponent(component) {
      this.kase.generic_components.splice(
        this.kase.generic_components.findIndex(
          genericComponent =>
            genericComponent.component_id === component.component_id
        ),
        1
      );
    }
  }
};
</script>
