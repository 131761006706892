import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#C7C9C7"
      },
      light: {
        primary: "#147BD1",
        secondary: "#147BD1",
        accent: "#82B1FF"
      }
    }
  }
});
