<template>
  <v-row>
    <v-col sm="12" md="4">
      <StatusSelector :kase="kase" v-if="showStatusSelector" class="mb-5" />
      <BasicInfo class="mb-5" />
      <TechnicalInfo class="mb-5" />
      <GenericComponent class="mb-5" />
      <span v-if="kase.machine_project && kase.machine_project.id">
        <Production class="mb-5" />
      </span>
    </v-col>
    <v-col sm="12" md="8">
      <Grouping v-if="hasGroup" class="mb-5" />
      <Implants class="mb-5" />
      <PickingSlips />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import StatusSelector from "./Overview/StatusSelector";
import BasicInfo from "./Overview/BasicInfo";
import TechnicalInfo from "./Overview/TechnicalInfo";
import Production from "./Overview/Production";
import Grouping from "./Overview/Grouping";
import Implants from "./Overview/Implants";
import PickingSlips from "./Overview/PickingSlips";
import GenericComponent from "./Overview/GenericComponents";

export default {
  name: "Overview",
  components: {
    StatusSelector,
    BasicInfo,
    TechnicalInfo,
    Production,
    Grouping,
    Implants,
    PickingSlips,
    GenericComponent
  },
  computed: {
    ...mapGetters(["collection", "kase", "department"]),
    hasGroup() {
      if (!this.kase.group_number) {
        return false;
      }
      if (
        this.collection.cases &&
        this.collection.cases.filter(kase => {
          return (
            kase.group_number === this.kase.group_number &&
            kase.uid !== this.kase.uid
          );
        }).length > 0
      ) {
        return true;
      }
      return false;
    },
    showStatusSelector() {
      if (
        this.department === "finishing" &&
        this.kase.in_lab &&
        !(this.kase.dispatched || this.kase.cancelled)
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>
